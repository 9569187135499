import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import * as moment from "moment";

function InspectionStatisticsStatus({ status, values, navigate, filtervalue }) {
  const [xaxiscat, setXaxiscat] = useState([]);
  const [series, setSeries] = useState([
    {
      name: "Total Tags",
      type: "line",
      data: [],
    },
    {
      name: "Red Tags",
      type: "column",
      data: [],
    },
    {
      name: "Green Tags",
      type: "column",
      data: [],
    },
    {
      name: "Yellow Tags",
      type: "column",
      data: [],
    },
  ]);

  let options = {
    chart: {
      type: "line",
      stacked: false,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
        },
        export: {
          svg: {
            filename: "Inspection Statistics - Inspection Status",
          },
          png: {
            filename: "Inspection Statistics - Inspection Status",
          },
          csv: {
            filename: "Inspection Statistics - Inspection Status",
          },
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        maxColumnWidth: "50%"
      },
    },
    responsive: [
      {
        breakpoint: 1440,
        options: {
          chart: {
            height: 310,
            width: "100%",
          },
          dataLabels: {
            style: {
              fontSize: "12px",
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
        },
      },
      {
        breakpoint: 990,
        options: {
          chart: {
            width: "100%",
            height: 310,
          },
          dataLabels: {
            style: {
              fontSize: "12px",
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "400",
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          legend: {
            horizontalAlign: "left",
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
            height: 320,
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
      offsetY: -25,
      style: {
        fontSize: "14px",
        fontFamily: "Halvetica, sans-serif",
        fontWeight: "normal",
        colors: ["#4F4F4F"],
      },
    },
    stroke: {
      width: [3, 0, 0, 0],
    },
    xaxis: {
      categories: xaxiscat,
      labels: {
        style: {
          colors: "#4F4F4F",
          fontSize: "14px",
          fontFamily: "Halvetica, sans-serif",
          fontWeight: "normal",
        },
      },
      tickPlacement: 'between'
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#4F4F4F",
            fontSize: "14px",
            fontFamily: "Halvetica, sans-serif",
            fontWeight: "normal",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
    legend: {
      show: false,
    },
    markers: {
      size: 5,
    },
    colors: ["#FEB019", "#FF0000", "#E1E103", "#00B050"],
  };
  useEffect(() => {
    setSeries([
      {
        name: "Total Tags",
        type: "line",
        data: status?.map((item, i) => {
          return item?.total ? item?.total : 0;
        }),
      },
      {
        name: "Red Tags",
        type: "column",
        data: status?.map((item, i) => {
          return item?.red ? item?.red : 0;
        }),
      },

      {
        name: "Yellow Tags",
        type: "column",
        data: status?.map((item, i) => {
          return item?.yellow ? item?.yellow : 0;
        }),
      },
      {
        name: "Green Tags",
        type: "column",
        data: status?.map((item, i) => {
          return item?.green ? item?.green : 0;
        }),
      },
    ]);
    setXaxiscat(
      status?.map((item, i) => {
        if (filtervalue.type === "monthly") {
          return item?.date ? moment(item?.date).format("MMM-YYYY") : [];
        }
        if (filtervalue.type === "yearly") {
          return item?.date ? moment(item?.date).format("YYYY") : [];
        } else {
          return item?.date ? moment(item?.date).format("DD-MM-YYYY") : [];
        }
      })
    );
  }, [status]);

  return (
    <div>
      <>
        <Chart options={options} series={series} width="100%" height="420px" />
        <div className="columnLegends">
          <div className="columnLegends__item disabled">
            <span
              className="columnLegends__color"
              style={{ backgroundColor: "#feb019" }}
            ></span>
            <span className="columnLegends__label">Total Tags</span>
          </div>
          <div
            className="columnLegends__item"
            onClick={() =>
              navigate("/asset-register", {
                state: {
                  valid: "true",
                  inspectionStatus: "Red",
                  startDate: values.startDate,
                  endDate: values.endDate,
                },
              })
            }
          >
            <span
              className="columnLegends__color"
              style={{ backgroundColor: "#ff0000" }}
            ></span>
            <span className="columnLegends__label">Red Tags</span>
          </div>
          <div
            className="columnLegends__item"
            onClick={() =>
              navigate("/asset-register", {
                state: {
                  valid: "true",
                  inspectionStatus: "Yellow",
                  startDate: values.startDate,
                  endDate: values.endDate,
                },
              })
            }
          >
            <span
              className="columnLegends__color"
              style={{ backgroundColor: "#e1e103" }}
            ></span>
            <span className="columnLegends__label">Yellow Tags</span>
          </div>
          <div
            className="columnLegends__item"
            onClick={() =>
              navigate("/asset-register", {
                state: {
                  valid: "true",
                  inspectionStatus: "Green",
                  startDate: values.startDate,
                  endDate: values.endDate,
                },
              })
            }
          >
            <span
              className="columnLegends__color"
              style={{ backgroundColor: "#00b050" }}
            ></span>
            <span className="columnLegends__label">Green Tags</span>
          </div>
        </div>
      </>
    </div>
  );
}

export default InspectionStatisticsStatus;
