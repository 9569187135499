import React from "react";
import { Button } from "../../components";
import { images } from "../../constants";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";

const SuccessMsg = () => {
  return (
    <div className="error__wrapper">
      <div className="error__content">
        <h3 className="error__title">Hurray..</h3>
        <span className="error__subtitle">
          You were successfully updated your password
        </span>
        <img src={images.Success} alt="error-img" className="error__img" />
        <p className="error__text">
          you can go back to login page, and use your new password to login.
        </p>
        <Link to="/login">
          <Button
            variant="click-primary"
            size="click-xl"
            className="error__btn"
          >
            <FiArrowLeft />
            <span>Back to Login</span>
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default SuccessMsg;
