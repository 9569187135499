import React from 'react';

const AddTable = ({ label, placeholder, onClick, className, value }) => {
  return (
    <>
        <div className='form-group'>
            <label className='form-label'>{label}</label>
            <button 
                className={`add-table ${className}`}
                label={label}
                placeholder={placeholder}
                onClick={onClick}
                value={value}
            >
                <span className='add-label'>{placeholder}</span>
            </button>
        </div>
    </>
  )
}

export default AddTable;