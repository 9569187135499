import React, { useState } from 'react';

const FormInput = ({type, value, placeholder, onChange, label, name, id, autoComplete, className, focusref, readOnly, required, disabled, errormsg,min}) => {

    return (
        <div className='form-group'>
            <label className='form-label' htmlFor={name}>
                {label}
                {required && (
                    <span className='form-required'>*</span>
                )}
            </label>
            <input 
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                name={name}
                id={id}
                min={min}
                ref={focusref}
                autoComplete={autoComplete}
                className={`form-input ${className} ${readOnly && 'readonly'} ${disabled && 'disabled'}`}
                readOnly={readOnly}
                disabled={disabled}
                required={required}  
                errormsg={errormsg}
            />
            {required&&
                <span className='form-error'>{errormsg}</span>
            }
        </div>
    );
}

export default FormInput