import React, { useState, useEffect } from "react";
import {
  Form,
  FormInput,
  FormSelect,
  TextArea,
  ReadOnlyInput,
  Spinner,
} from "../../../components";
import { toast } from "react-toastify";
import equipmentService from "../../../services/equipmentService";
import TokenService from "../../../services/tokenService";
import * as moment from "moment-timezone";
import { Row, Col } from "react-bootstrap";
import { images } from "../../../constants";
import Table from "react-bootstrap/Table";
import { useParams } from "react-router-dom";
import { MdOutlineClose, MdCheck } from "react-icons/md";
import { useSelector } from "react-redux";
import assetService from "../../../services/assetService";
import userService from "../../../services/userService";

const CorrectiveActions = ({
  isSubmit,
  setIsSubmit,
  locationId,
  setLocationId,
  assetId,
  setCorrectiveActionData,
  correctiveActionData,
  isClear,
  setIsClear,
  next,
  setNext,
  ...props
}) => {
  const [currentStatus, setCurrentStatus] = useState("");
  const [currentCondition, setCurrentCondition] = useState("");
  const [defectCategory, setDefectCategory] = useState("");
  const [repairedBy, setRepairedBy] = useState("");
  const [assetDetails, setAssetDetails] = useState({});
  const [repaireDate, setRepaireDate] = useState("");
  const [userName, setUsername] = useState("");
  const [signaturePath, setSignaturePath] = useState("");

  const [values, setValues] = useState({});
  const lookup = useSelector((state) => state.lookupData.data);
  const subLookup = useSelector((state) => state.subLookupData);
  useEffect(() => {
    if (isSubmit) {
      handleSubmit();
      setIsSubmit(false);
      setNext(true);
    }
  }, [isSubmit]);

  useEffect(() => {
    if (isClear) {
      handleClear();
      setIsClear(false);
    }
  }, [isClear]);

  const handleClear = () => {
    setValues("");
  };

  useEffect(() => {
    if (assetId !== undefined) {
      try {
        assetService.getAssetById(String(assetId)).then((assetData) => {
          setAssetDetails(assetData);
          setValues(assetData);
          if (assetData.repairedDate.includes("+")) {
            setRepaireDate(
              moment.utc(assetData.repairedDate).format("DD/MM/YYYY hh:mm A")
            );
          } else {
            setRepaireDate(
              moment(assetData.repairedDate).format("DD/MM/YYYY hh:mm A")
            );
          }
          setValues(assetData);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, []);
  useEffect(() => {
    Getuser();
  }, []);
  const Getuser = async () => {
    let data = await TokenService.getUser();
    setUsername(data.userName);
  };
  useEffect(async () => {
    let data = await userService.getAllUsers();
    data?.users?.map((item) => {
      if (item.username?.includes(values?.repairedBy)) {
        setSignaturePath(item?.signature);
      }
    });
  }, [values]);
  const handleComment = (e) => {
    setValues({ ...values, comments: e.target.value });
  };

  const handleExistingFaults = (e) => {
    setValues({ ...values, existingFaults: e.target.value });
  };

  const handleRepairedDate = (e) => {
    setValues({ ...values, repairedDate: e.target.value });
  };

  const handleIsolations = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    setValues({ ...values, correctiveisolation: value });
  };

  const handleOtherRequirements = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    setValues({ ...values, correctiveOtherRequirements: value });
  };
  const handleSignature = (e) => {
    setValues({ ...values, Signature: e.target.value });
  };
  const handleRepairedby = (e) => {
    setValues({ ...values, repairedBy: e.target.value });
  };

  const handleCurrentStatus = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    setValues({ ...values, currentStatus: value });
  };

  const handleCurrentCondition = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    setValues({ ...values, correctiveOverallCondition: value });
  };

  const handleRepairedBy = (e) => {
    setRepairedBy({ repairedBy });
  };

  const handleDefectCategory = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    setValues({ ...values, correctiveDefectCategory: value });
  };

  const dummyData = [
    {
      value: "Red",
      text: "Red ",
    },
    {
      value: "Yellow",
      text: "Yellow",
    },
    {
      value: "Green",
      text: "Green",
    },
  ];

  let DefectCategoryDatas = [
    {
      value: "1",
      text: "1 ",
    },
    {
      value: "2",
      text: "2",
    },
    {
      value: "3",
      text: "3",
    },
    {
      value: "4",
      text: "4 ",
    },
    {
      value: "5",
      text: "5",
    },
    {
      value: "Not Applicable",
      text: "Not Applicable",
    },
  ];
  let CurrentConditionData = [];
  let isolationsData = [];
  let otherRequirementsData = [];

  if (lookup) {
    // lookup?.DefectCategory?.map((ele) => {
    //   DefectCategoryData.push({
    //     key: ele._id,
    //     text: ele.value,
    //     value: ele.value,
    //   });
    // });

    lookup?.OverallCondition?.map((ele) => {
      CurrentConditionData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });

    lookup?.OtherRequirements?.map((ele) => {
      otherRequirementsData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });

    lookup?.Isolations?.map((ele) => {
      isolationsData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });
  }
  const Image = ({ srcvale }) => {
    const [loading, setLoading] = useState(true);

    return (
      <>
        {loading ? <Spinner /> : ""}
        <img
          src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${srcvale}`}
          className={"defect-img"}
          alt="defective photos"
          onClick={() => {
            window.open(`${process.env.REACT_APP_BACKEND_BASE_URL}/${srcvale}`);
          }}
          onLoad={() => setLoading(false)}
        />
      </>
    );
  };
  const handleSubmit = async () => {
    let newData = {
      existingFaults: values?.existingFaults ? values?.existingFaults : "",
      currentStatus: values?.currentStatus ? values?.currentStatus : "",
      correctiveDefectCategory: values?.correctiveDefectCategory
        ? values.correctiveDefectCategory
        : "",
      repairedBy: values?.repairedBy ? values?.repairedBy : "",
      repairedDate: values?.repairedDate ? values?.repairedDate : "",
      correctiveOverallCondition: values?.correctiveOverallCondition
        ? values?.correctiveOverallCondition
        : "",
      correctiveisolation: values?.correctiveisolation
        ? values?.correctiveisolation
        : "",
      correctiveOtherRequirements: values?.correctiveOtherRequirements
        ? values?.correctiveOtherRequirements
        : "",
      checkList: values?.checkList ? values?.checkList : "",
      additionalComments: values?.additionalComments
        ? values?.additionalComments
        : "",
      // defectIsolation: values?.defectIsolation,
      // defectOtherRequirements: values?.defectOtherRequirements,
      // inspectedBy: values?.inspectedBy,
      // inspectedDate: values?.inspectedDate,
    };
    try {
      await equipmentService.addEquipmentTagById(assetId, newData).then(
        (response) => {
          toast.success(response.data.msg);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {assetId ? (
        <>
          <div className="asset-table corrective-table table-align-left spacing-in">
            <Table striped>
              <thead>
                <tr>
                  <th style={{ width: "100px" }}>Defect Code</th>
                  <th>Findings</th>
                  <th>Remedial Actions</th>
                  <th>Done</th>
                  <th style={{ width: "15%" }}>Repaired By</th>
                  <th style={{ width: "15%", textAlign: "left" }}>
                    Repaired Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {assetDetails?.checkList?.map((item, index) =>
                  item?.findingsAndActions?.map((list, i) => {
                    if (list.isSelected != undefined) {
                      if (list?.isSelected === true) {
                        return (
                          <tr key={i}>
                            <td>{list?.defectCode}</td>
                            <td>{list?.finding}</td>
                            <td>
                              {" "}
                              <input
                                className="wo-input"
                                type="text"
                                value={list?.remedialAction}
                                onChange={(ev) => {
                                  values.checkList[index].findingsAndActions[
                                    i
                                  ].remedialAction = ev.target.value;
                                  list.remedialAction = ev.target.value;
                                  setValues({ ...values });
                                  setAssetDetails({ ...assetDetails });
                                }}
                              />
                            </td>
                            <td>
                              <span className="repairs-done corrective">
                                <p>{list?.defectCode}</p>
                                {list?.isDone === true ? (
                                  <span
                                    className="success"
                                    onClick={() => {
                                      values.checkList[
                                        index
                                      ].findingsAndActions[i].isDone = false;
                                      list.isDone = false;
                                      values.checkList[
                                        index
                                      ].findingsAndActions[i].repairedBy = "";
                                      list.repairedBy = "";
                                      values.checkList[
                                        index
                                      ].findingsAndActions[i].repairedAt = "";
                                      list.repairedAt = "";
                                      setValues({ ...values });
                                      setAssetDetails({ ...assetDetails });
                                    }}
                                  >
                                    <MdCheck />
                                  </span>
                                ) : (
                                  <span
                                    className="failed"
                                    onClick={() => {
                                      values.checkList[
                                        index
                                      ].findingsAndActions[i].isDone = true;
                                      list.isDone = true;
                                      values.checkList[
                                        index
                                      ].findingsAndActions[i].repairedBy =
                                        userName;
                                      list.repairedBy = userName;
                                      values.checkList[
                                        index
                                      ].findingsAndActions[i].repairedAt =
                                        moment(new Date()).format(
                                          "DD/MM/YYYY hh:mm A"
                                        );
                                      list.repairedAt = moment(
                                        new Date()
                                      ).format("DD/MM/YYYY hh:mm A");
                                      setValues({ ...values });
                                      setAssetDetails({ ...assetDetails });
                                    }}
                                  >
                                    <MdOutlineClose />
                                  </span>
                                )}
                              </span>
                            </td>
                            <td>{list?.repairedBy}</td>
                            <td style={{ textAlign: "left" }}>
                              {list?.repairedAt?.length > 19
                                ? moment(list?.repairedAt).format(
                                  "DD/MM/YYYY hh:mm A"
                                )
                                : list?.repairedAt}
                            </td>
                          </tr>
                        );
                      }
                    }
                  })
                )}
              </tbody>
            </Table>
          </div>
          <TextArea
            label="Additional Comments"
            className="defect-comment"
            placeholder="Type Here..."
            value={values.additionalComments ? values.additionalComments : ""}
            onChange={(ev) => {
              values.additionalComments = ev.target.value;
              setValues({ ...values });
              setAssetDetails({ ...assetDetails });
            }}
          />
          {(assetDetails.correctivePhoto1 ||
            assetDetails.correctivePhoto2 ||
            assetDetails.correctivePhoto3) && <hr className="tab-divider" />}
          {(assetDetails.correctivePhoto1 ||
            assetDetails.correctivePhoto2 ||
            assetDetails.correctivePhoto3) && (
              <h3 className="tab-inner-title">Current Photos</h3>
            )}
          <div className="defective-photos">
            <Row>
              <Col xxl={4} lg={4} md={4}>
                {assetDetails?.correctivePhoto1 ? (
                  <Image srcvale={assetDetails.correctivePhoto1} />
                ) : (
                  ""
                )}
              </Col>
              <Col xxl={4} lg={4} md={4}>
                {assetDetails?.correctivePhoto2 ? (
                  <Image srcvale={assetDetails.correctivePhoto2} />
                ) : (
                  ""
                )}
              </Col>
              <Col xxl={4} lg={4} md={4}>
                {assetDetails?.correctivePhoto3 ? (
                  <Image srcvale={assetDetails.correctivePhoto3} />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
          <hr className="tab-divider" />
        </>
      ) : (
        ""
      )}

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xxl={12} lg={12}>
            <span className="form-group-label">Corrective Actions Summary</span>
          </Col>
        </Row>
        <Row>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Existing Faults"
              placeholder="Enter here"
              // onChange={handleExistingFaults}
              value={values?.existingFaults ? values.existingFaults : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              label="Current Status"
              placeholder="Select "
              data={dummyData}
              onChange={handleCurrentStatus}
              value={values.currentStatus ? values.currentStatus : ""}
              required
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              label="Defect Category"
              placeholder="Select "
              data={DefectCategoryDatas}
              onChange={handleDefectCategory}
              value={
                values.correctiveDefectCategory
                  ? values.correctiveDefectCategory
                  : defectCategory
              }
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              label="Current Condition"
              placeholder="Select "
              data={CurrentConditionData}
              onChange={handleCurrentCondition}
              value={
                values.correctiveOverallCondition
                  ? values.correctiveOverallCondition
                  : ""
              }
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              label="Isolations"
              name="isolations"
              placeholder="Select "
              data={isolationsData}
              value={
                values.correctiveisolation ? values.correctiveisolation : ""
              }
              onChange={handleIsolations}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              type="text"
              label="Other Requirements"
              placeholder="Select "
              data={otherRequirementsData}
              value={
                values.correctiveOtherRequirements
                  ? values.correctiveOtherRequirements
                  : ""
              }
              onChange={handleOtherRequirements}
            />
          </Col>
        </Row>
        <Col xxl={12} lg={12}>
          <span className="form-group-label">Repair sign off</span>
        </Col>
        <Row>
          <Col xxl={3} lg={4}>
            {/* <FormInput
              label="Signature"
              placeholder="Enter here"
              onChange={handleSignature}
              value={values.Signature ? values.Signature : ""}
            /> */}
            <label htmlFor="signature" className="form-label">Signature</label>
            <div className="sig-border-new-wrap">
              {signaturePath ? (
                <img
                  src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${signaturePath}`}
                />
              ) : (
                ""
              )}
            </div>
          </Col>

          <Col xxl={3} lg={4}>
            <FormInput
              label="Repaired By"
              placeholder="Enter here"
              onChange={handleRepairedby}
              value={values.repairedBy ? values.repairedBy : ""}
            />
          </Col>

          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Repaired Date"
              onChange={handleRepairedDate}
              value={values.repairedDate ? repaireDate : ""}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CorrectiveActions;
