import React from 'react';
import { Button } from '../../components';
import { MdOutlineClose } from 'react-icons/md';

const Modal = ({ title, text, children, closeModal }) => {

    return (
        <>
            <div 
                className='aimmodal'
                closeModal={closeModal}
                title={title}
                text={text}
            >
                <div className='aimmodal__overlay' onClick={closeModal}></div>
                <div className='aimmodal__wrapper'>
                    <div className='aimmodal__header'>
                        <Button 
                            variant='click-none' 
                            size='click-resize'
                            className='aimmodal__close'
                            onClick={closeModal}
                        >
                            <MdOutlineClose size={25} />
                        </Button>
                    </div>
                    <div className='aimmodal__content'>
                        <h3 className='aimmodal__title'>{title}</h3>
                        <p className='aimmodal__text'>{text}</p>
                        <div className='aimmodal__btns'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Modal;