import React, { useState,useEffect } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import assetService from "../../services/assetService";
import { LocationDetails, EquipmentTag, InspectionChecklist, DefectAnalysis, CorrectiveActions, InspectionReport } from './StepperComponents';
import { Button, PageFooter, Modal } from '../../components';
import { useHistory ,useLocation } from 'react-router-dom';

function getSteps() {
  return ["Location Details", "Equipment Tag", "Inspection Checklist", "Defect Analysis", "Corrective Actions", "Inspection Test Report"];
}

const HorizontalLinearStepper = ({assetId,stopswipe, ...props} ) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [isSubmit, setIsSubmit] = useState(false);
  const [locationId, setLocationId] = useState('');
  const [locationData, setLocationData] = useState({});
  const [equipmentData, setEquipmentData] = useState({});
  const [checklistData, setChecklistData] = useState({});
  const [defectData, setDefectData] = useState({});
  const [correctiveActionData, setCorrectiveActionData] = useState({});
  const [isClear, setIsClear] = useState(false);
  const [next, setNext] = useState(false);
  const [show, setShow] = useState(false);
  const [defectPhoto1, setDefectPhoto1] = useState("");
  const [defectPhoto2, setDefectPhoto2] = useState("");
  const [defectPhoto3, setDefectPhoto3] = useState("");
  const [CorrectivePhoto1, setCorrectivePhoto1] = useState("");
  const [CorrectivePhoto2, setCorrectivePhoto2] = useState("");
  const [CorrectivePhoto3, setCorrectivePhoto3] = useState("");
  const [Comments, setComments] = useState("");


  const location = useLocation()

  const handleNext = () => {
    //need to confirm
    // if(next) {
      
    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // }
    // else {
    //   setShow(true);
    // }
    if(location.pathname == '/asset-register/add'){
      setIsSubmit(true)
      setNext(true)
    } else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleSave = () => {
    setIsSubmit(true)
    setNext(true)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setIsClear(true);
    //setActiveStep(0);
  };

  useEffect(() => {
    if (assetId !== undefined) {
      try {
        assetService.getAssetById(String(assetId)).then((assetData) => {
          setComments(assetData?.additionalComments);      
          const toDataURL = (url) =>
          fetch(url)
            .then((response) => response.blob())
            .then(
              (blob) =>
                new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onloadend = () => resolve(reader.result);
                  reader.onerror = reject;
                  reader.readAsDataURL(blob);
                })
            );
    
        toDataURL(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/${assetData?.defectivePhoto1}`
        ).then((dataUrl) => {
          setDefectPhoto1(dataUrl.split(",")[1]);
        });
        toDataURL(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/${assetData?.defectivePhoto2}`
        ).then((dataUrl) => {
          setDefectPhoto2(dataUrl.split(",")[1]);
        });
        toDataURL(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/${assetData?.defectivePhoto3}`
        ).then((dataUrl) => {
          setDefectPhoto3(dataUrl.split(",")[1]);
        });
        toDataURL(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/${assetData?.correctivePhoto1}`
        ).then((dataUrl) => {
          setCorrectivePhoto1(dataUrl.split(",")[1]);
        });
        toDataURL(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/${assetData?.correctivePhoto2}`
        ).then((dataUrl) => {
          setCorrectivePhoto2(dataUrl.split(",")[1]);
        });
        toDataURL(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/${assetData?.correctivePhoto3}`
        ).then((dataUrl) => {
          setCorrectivePhoto3(dataUrl.split(",")[1]);
        });
         
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  function getStepContent(step) {
    switch (step) {
      case 0: return <LocationDetails  activeStep={activeStep} isSubmit={isSubmit} setActiveStep={setActiveStep} setIsSubmit={setIsSubmit} locationId={locationId} setLocationId={setLocationId} assetId={assetId} locationData={locationData} setLocationData={setLocationData} value={locationData} isClear={isClear} setIsClear={setIsClear} next={next} setNext={setNext}/>;
      case 1: return <EquipmentTag isSubmit={isSubmit} setIsSubmit={setIsSubmit} locationId={locationId} setLocationId={setLocationId} assetId={assetId} locationData={locationData} equipmentData={equipmentData} setEquipmentData={setEquipmentData} value={equipmentData} isClear={isClear} setIsClear={setIsClear} next={next} setNext={setNext}/>;
      case 2: return <InspectionChecklist isSubmit={isSubmit} setIsSubmit={setIsSubmit} locationId={locationId} setLocationId={setLocationId} assetId={assetId} checklistData={checklistData} setChecklistData={setChecklistData} value={checklistData} isClear={isClear} setIsClear={setIsClear} next={next} setNext={setNext} />;
      case 3: return <DefectAnalysis  isSubmit={isSubmit} setIsSubmit={setIsSubmit} locationId={locationId} setLocationId={setLocationId} assetId={assetId} defectData={defectData} setDefectData={setDefectData} value={defectData} isClear={isClear} setIsClear={setIsClear} next={next} setNext={setNext}/>;
      case 4: return <CorrectiveActions isSubmit={isSubmit} setIsSubmit={setIsSubmit} locationId={locationId} setLocationId={setLocationId} assetId={assetId} correctiveActionData={correctiveActionData} setCorrectiveActionData={setCorrectiveActionData} value={correctiveActionData} isClear={isClear} setIsClear={setIsClear} next={next} setNext={setNext} />;
      case 5: return <InspectionReport 
                          assetId={assetId}   
                          dPhoto1={defectPhoto1} 
                          dPhoto2={defectPhoto2} 
                          dPhoto3={defectPhoto3} 
                          CPhoto1={CorrectivePhoto1} 
                          CPhoto2={CorrectivePhoto2} 
                          CPhoto3={CorrectivePhoto3}
                          Comments = {Comments} 

                          />;
      default: return "Unknown step";
    }
  }
 
  return (
    <div>
      <Stepper activeStep={activeStep} className='tab-header'>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
       (stopswipe===true)?   <Step key={label} {...stepProps} >
              <StepLabel {...labelProps} className='tab-item'>{label}</StepLabel>
            </Step>:<Step key={label} {...stepProps} onClick={()=>{setActiveStep(index)}}>
              <StepLabel {...labelProps} className='tab-item'>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div></div>
        ) : (
          <div className="tab-wrapper">
            <div className="tab-content">
              {getStepContent(activeStep)}
            </div>
            <PageFooter>
              <Button variant='click-primary' size='click-lg' type='submit' onClick={handleSave}>Save</Button>
              <Button variant='click-gray' size='click-lg' type='button' onClick={handleReset}>Clear</Button>
              <Button 
                variant={`${activeStep === 5 ? "click-accent" : "click-gray"}`} 
                size='click-lg' 
                type='button'
                onClick={handleBack}
                className={`stepper-back ${activeStep === 0 ? "disable" : ""} ${activeStep === 5 ? "active" : ""}`}
              >
                Back
              </Button>
              <Button 
                variant='click-accent' 
                size='click-lg' 
                type='button'
                onClick={handleNext}
                className={`stepper-next ${activeStep === 5 ? "disable" : ""}`}
              >
                Next
              </Button>
            </PageFooter>
          </div>
        )}
      </div>
      {show&&
        <Modal 
          title='Save to proceed'
        >
          <Button variant='click-gray' size='click-lg' onClick={()=>setShow(false)}>Close</Button>
        </Modal>
      }
    </div>
  );
}

export default HorizontalLinearStepper;