import React, { Component, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import * as moment from "moment";

function InspectionsOverdue({
  inspectionOverdue,
  values,
  navigate,
  filtervalue,
}) {
  const [xaxiscat, setXaxiscat] = useState([]);
  const [series, setSeries] = useState([
    {
      name: "Cumulative",
      type: "line",
      data: [],
    },
    {
      name: "Inspection Overdue",
      type: "column",
      data: [],
    },
  ]);
  let options = {
    chart: {
      type: "line",
      stacked: false,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
        },
        export: {
          svg: {
            filename: "Activities Overdue - Inspections Overdue",
          },
          png: {
            filename: "Activities Overdue - Inspections Overdue",
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 1440,
        options: {
          chart: {
            height: 310,
            width: "100%",
          },
          dataLabels: {
            style: {
              fontSize: "12px",
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
        },
      },
      {
        breakpoint: 990,
        options: {
          chart: {
            width: "100%",
            height: 310,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          legend: {
            horizontalAlign: "left",
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
            height: 320,
          },
          dataLabels: {
            style: {
              fontSize: "12px",
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "400",
            },
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        columnWidth: "40%",
        dataLabels: {
          position: "center",
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#373D3F"],
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -5,
      style: {
        fontSize: "14px",
        fontFamily: "Halvetica, sans-serif",
        fontWeight: "400",
        colors: ["#373D3F", "#373D3F"],
      },
      background: {
        enabled: false,
      },
    },
    stroke: {
      width: [3, 0],
    },
    xaxis: {
      categories: xaxiscat,

      labels: {
        style: {
          colors: "#4F4F4F",
          fontSize: "14px",
          fontFamily: "Halvetica, sans-serif",
          fontWeight: "400",
        },
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#4F4F4F",
            fontSize: "14px",
            fontFamily: "Halvetica, sans-serif",
            fontWeight: "400",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
    legend: {
      show: false,
    },
    markers: {
      size: 5,
    },
    fill: {
      opacity: 1,
    },
    colors: ["#FEB019", "#2F9FAD"],
  };
  let total = 0;
  const getTotal = (item) => {
    return (total = total + item);
  };
  useEffect(() => {
    setSeries([
      {
        name: "Cumulative",
        type: "line",
        data: inspectionOverdue?.map((item, i) => {
          return getTotal(item.count);
        }),
      },
      {
        name: "Inspection Overdue",
        type: "column",
        data: inspectionOverdue?.map((item, i) => {
          return item.count ? item.count : 0;
        }),
      },
    ]);
    setXaxiscat(
      inspectionOverdue?.map((item, i) => {
          if (filtervalue.type === "monthly") {
            return item?.date ? moment(item?.date).format("MMM-YYYY") : [];
          }
          if (filtervalue.type === "yearly") {
            return item?.date ? moment(item?.date).format("YYYY") : [];
          } else {
            return item?.date ? moment(item?.date).format("DD-MM-YYYY") : [];
          }
      })
    );
  }, [inspectionOverdue]);

  return (
    <>
      <Chart options={options} series={series} width="100%" height="380" />
      <div className="columnLegends">
        <div className="columnLegends__item disabled">
          <span
            className="columnLegends__color"
            style={{ backgroundColor: "#feb019" }}
          ></span>
          <span className="columnLegends__label">Cumulative</span>
        </div>
        <div
          className="columnLegends__item"
          onClick={() =>
            navigate("/asset-register", {
              state: {
                valid: "true",
                activitiesOverdue: "inspectionOverdue",
                startDate: values.startDate,
                endDate: values.endDate,
                inspectionDate: "true",
              },
            })
          }
        >
          <span
            className="columnLegends__color"
            style={{ backgroundColor: "#2f9fad" }}
          ></span>
          <span className="columnLegends__label">Inspection Overdue</span>
        </div>
      </div>
    </>
  );
}

export default InspectionsOverdue;
