import React from "react";
import { Button, Photospinner } from "../../components";
import { BsFillArrowUpCircleFill } from "react-icons/bs";

const TablePagination = (props) => {


  return (
    <>
      <div className="pagination-wrapper">
        <span className="pagination-count">
          Showing {props?.showingResult?.length} of {props?.totalResult} Results
        </span>
        {!props.Loadmore ? (
          <span>
            {props?.showingResult?.length < props?.totalResult ? (
              <Button
                variant="click-primary "
                size="click-md"
                onClick={props.fetchMoreData}
                style={{ minWidth: "100px" }}
              >
                Load More
              </Button>
            ) : (
              ""
            )}
          </span>
        ) : (
          <span className="pagination-count">
            {" "}
            <Button style={{ minWidth: "100px" }}>
              <Photospinner />
            </Button>
          </span>
        )}
        <span className="pagination-count" style={{ cursor: "pointer" }}>
          {props.isLoading ? (
            <h4 onClick={props.handleClick}>
              Scroll to top <BsFillArrowUpCircleFill />
            </h4>
          ) : (
            ""
          )}
        </span>
      </div>
    </>
  );
};

export default TablePagination;
