import React, { useState, useEffect, useRef } from "react";
import { Accordion, AccordionChild, ReadOnlyInput } from "../../components";
import inspectionService from "../../services/inspectionService";
import assetService from "../../services/assetService";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { CgChevronDownO } from 'react-icons/cg';

const InspectionChecklist = (id) => {
  const { _id } = useParams();

  let assetId;

  if (_id != undefined) {
    assetId = _id;
  } else {
    assetId = id;
  }

  const [inspectionChecklist, setInspectionChecklist] = useState([]);
  const [inspectionDetails, setInspectionDetails] = useState([]);
  const [totalA, setDefectA] = useState([]);
  const [totalB, setDefectB] = useState([]);
  const [totalC, setDefectC] = useState([]);
  const [defectCountA, setDefectCountA] = useState([]);
  const [defectCountB, setDefectCountB] = useState([]);
  const [defectCountC, setDefectCountC] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const [values, setValues] = useState({});

  const checklistFormRef = useRef(null);

  useEffect(async () => {
    let filter = {};

    if (assetId !== undefined) {
      let details;

      try {
        await assetService.getAssetById(String(assetId)).then((assetData) => {
          setValues(assetData)
          setCheckList(assetData?.checkList);
          console.log(assetData?.checkList[0].inspectionType);
          filter = {
            inspectionType: assetData?.inspectionType,
            equipmentType: assetData?.equipmentEquipmentType,
            checklistName: assetData?.inspectionChecklistType,
            inspectionGrade: assetData?.inspectionGrade,
          };

          setDefectCountA(
            assetData?.checkList?.filter((f) =>
              f.defectCode.toLowerCase().startsWith("a")
            )
          );
          setDefectCountB(
            assetData?.checkList?.filter((f) =>
              f.defectCode.toLowerCase().startsWith("b")
            )
          );
          setDefectCountC(
            assetData?.checkList?.filter((f) =>
              f.defectCode.toLowerCase().startsWith("c")
            )
          );
        });
      } catch (error) {
        console.log(error);
      }
    }

    try {
      inspectionService.getAllInspectionList(filter).then((response) => {
        console.log(filter);
        setInspectionChecklist(response);
        let details = response?.checkListDetails.filter(
          (ele, ind) =>
            ind ===
            response?.checkListDetails.findIndex(
              (elem) => elem.defectCode === ele.defectCode
            )
        );
        setInspectionDetails(details);
        setDefectA(
          details.filter((item) => {
            return item.defectCategory == "A";
          })
        );
        setDefectB(
          details.filter((item) => {
            return item.defectCategory == "B";
          })
        );
        setDefectC(
          details.filter((item) => {
            return item.defectCategory == "C";
          })
        );
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <Row className="align-items-end mb-4">
        <Col xxl={4} lg={4}>
          <ReadOnlyInput
            label="Inspection Type"
            readonly
            value={values?.inspectionType}
          />
        </Col>
        <Col xxl={4} lg={4}>
          <ReadOnlyInput
            label="Inspection Checklist"
            readonly
            value={values?.inspectionChecklistType}
          />
        </Col>
        <Col xxl={4} lg={4}>
          <ReadOnlyInput
            label="Inspection Grade"
            readonly
            value={values?.inspectionGrade}
          />
        </Col>
      </Row>
      {assetId ? (
        <div formRef={checklistFormRef}>
          {inspectionChecklist &&
            inspectionChecklist?.checkLists?.map((item, i) => (
              <div>
                {item?.defectCategory == "A" ? (
                  <div className='accordion'>
                    <div className='accordion__header'>
                      <div className='header-left'>
                        <span>Defect Code</span>
                        <span>{"A. " + item?.checklistName}</span>
                      </div>
                      <div className='header-right'>
                        <span>{defectCountA?.length + "/" + totalA?.length}</span>
                        <CgChevronDownO size={30} />
                      </div>
                    </div>
                    <div className='accordion__content'>
                      {inspectionDetails.map((details, i) => (
                        <div>
                          {details?.defectCategory == "A" ? (
                            <AccordionChild
                              defectCode={details?.defectCode}
                              title={details?.checkListGroup}
                              checkListData={checkList}
                            ></AccordionChild>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : item?.defectCategory == "B" ? (
                  <div className='accordion'>
                    <div className='accordion__header'>
                      <div className='header-left'>
                        <span>Defect Code</span>
                        <span>{"B. " + item?.checklistName}</span>
                      </div>
                      <div className='header-right'>
                        <span>{defectCountB?.length + "/" + totalB?.length}</span>
                        <CgChevronDownO size={30} />
                      </div>
                    </div>
                    <div className='accordion__content'>
                      {inspectionDetails.map((details, i) => (
                        <div>
                          {details?.defectCategory == "B" ? (
                            <AccordionChild
                              defectCode={details?.defectCode}
                              title={details?.checkListGroup}
                              checkListData={checkList}
                            ></AccordionChild>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : item?.defectCategory == "C" ? (
                  <div className='accordion'>
                    <div className='accordion__header'>
                      <div className='header-left'>
                        <span>Defect Code</span>
                        <span>{"C. " + item?.checklistName}</span>
                      </div>
                      <div className='header-right'>
                        <span>{defectCountC?.length + "/" + totalC?.length}</span>
                        <CgChevronDownO size={30} />
                      </div>
                    </div>
                    <div className='accordion__content'>
                      {inspectionDetails.map((details, i) => (
                        <div>
                          {details?.defectCategory == "C" ? (
                            <AccordionChild
                              defectCode={details?.defectCode}
                              title={details?.checkListGroup}
                              checkListData={checkList}
                            ></AccordionChild>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
        </div>
      ) : (
        <div className="no-data">No data found</div>
      )}
    </>
  );
};

export default InspectionChecklist;
