
import React, { useState, useEffect, useContext } from "react";
import { Oval } from "react-loader-spinner";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { toast } from "react-toastify";
import { MyContext } from "../../routes/Routes";
import plannerService from "../../services/plannerService";
import { useNavigate } from "react-router-dom";

const ProgressBarUpload = ({ count, setProgress, setLoaders, setLoading, setCheck2 }) => {
  const { myState, setMyState } = useContext(MyContext);
  const [ChangeMsg, setChangeMsg] = useState("");
  const [ChangeMsg2, setChangeMsg2] = useState("");
  const [ChangeMsg3, setChangeMsg3] = useState(false);
  const [isToggle, setIsToggle] = useState(true);
  const navigate = useNavigate();

  const toggleLoader = () => setIsToggle(!isToggle);

  const closeLoader = () => {
    setMyState(false);
  };
  useEffect(() => {
    const intervalId = setInterval(fetchLoader, 15000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const fetchLoader = async () => {
    let data = await plannerService.getLoader();
    setChangeMsg(data[0]?.LoaderDetails[0]?.LoaderCount);

    console.log(data[0]?.LoaderDetails[0]?.LoaderCount.split("/")[0]);


    if (
      ((data[0]?.LoaderDetails[0]?.LoaderCount.split("/")[0] -
        data[0]?.LoaderDetails[0]?.LoaderCount.split("/")[1])<=1000)
    ) {
      console.log("sucessfully error loged");
      downloadError()
      let LoaderModel = {
        LoaderModel: {
          LoaderCount: 0,

        }
      };
      let datass = await plannerService.updateLoader(data[0]?.LoaderDetails[0]._id, LoaderModel);
      console.log(datass, "datassssloaded");
      setLoading(false)
      // toast.success("Assets added successfully");
      navigate("/asset-register/media-upload");
    }

  if (
      (data[0]?.LoaderDetails[0]?.LoaderCount.split("/")[0] ===
        data[0]?.LoaderDetails[0]?.LoaderCount.split("/")[1])
    ) {
      setCheck2(true)
      let LoaderModel = {
        LoaderModel: {
          LoaderCount: 0,

        }
      };
      let datass = await plannerService.updateLoader(data[0]?.LoaderDetails[0]._id, LoaderModel);
      console.log(datass, "datassssloaded");
      setLoading(false)
      // toast.success("Assets added successfully");
      navigate("/asset-register/media-upload");
    }
    console.log(data, "data");
  };
  const downloadError = () => {

    let name = "Error.txt";
    let uri = `${process.env.REACT_APP_BACKEND_BASE_URL}/media/workorders/${name}`;
    const accessToken = localStorage.getItem("accessToken");
    const options = {
      headers: {
        Authorization: `Bearer ${JSON.parse(accessToken).value}`,
      },
    };
    fetch(uri, options)
      .then((res) => res.blob())
      .then((blob) => {
        const fileURL = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");

        downloadLink.href = fileURL;
        downloadLink.download = name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
  };
  return (
    <div className="downloading">
      <div className={`downloading__progress ${isToggle && "show"}`}>
        <Oval
          height={30}
          width={30}
          color="#EA2A2A"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#EA2A2A"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />

        {ChangeMsg && ChangeMsg}
      </div>
      <button className="downloading__toggle" onClick={toggleLoader}>
        {isToggle ? <BiChevronRight size={24} /> : <BiChevronLeft size={24} />}
      </button>
    </div>
  );
};

export default ProgressBarUpload;
