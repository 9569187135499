import React from 'react';

const MixedInput = ({ fileId, textId, name, textName, label, accept, onFileChange, onTextChange, placeholder, textValue }) => {

    return (
        <div className='form-group mixed-upload'>
            <label className='mixed-label' htmlFor={textName}>{label}</label>
            <div className='mixed-wrap'>
                <input 
                    type='text'
                    className='mixed-input'
                    id={textId}
                    name={textName}
                    label={label}
                    accept={accept}
                    onChange={onTextChange}
                    value={textValue}
                />
                <label className='mixed-file' htmlFor={name}>
                    <input 
                        type='file'
                        id={fileId}
                        name={name}
                        onChange={onFileChange}
                        placeholder={placeholder}
                    />
                    Browse
                </label>
            </div>
        </div>
    );
}

export default MixedInput;