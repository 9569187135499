import React, { useState, useRef, useEffect } from "react";
import { Form, ReadOnlyInput, FormSelect, Button } from "../../components";
import { Row, Col } from "react-bootstrap";
import equipmentService from "../../services/equipmentService";
import { useParams } from "react-router-dom";
import assetService from "../../services/assetService";

import {
  gasGroupData,
  equipmentCategoryData,
  tClassData,
  atexCategoryData,
  ipRatingData,
  eplData,
  protectionStandardData,
} from "../../data/AssetRegister/EquipmentTag";

const EquipmentTag = ({ isSubmit, setIsSubmit, id, ...props }) => {
  const { _id } = useParams();
  let assetId;

  if (_id != undefined) {
    assetId = _id;
  } else {
    assetId = id;
  }

  const [values, setValues] = useState({
    rfidReference: "",
    inspectionReference: "",
    equipmentTag: "",
    circuitId: "",
    description: "",
    tambient: "",
    manufacturer: "",
    certificationBody: "",
    typeModel: "",
    protectionType: "",
    certificationNumber: "",
    serialNumber: "",
    specialConditions: "",
    gpsCoordinates: "",
  });

  const [gasGroup, setGasGroup] = useState("");
  const [equipmentCategory, setEquipmentCategory] = useState("");
  const [tclass, setTClass] = useState("");
  const [atexCategory, setAtexCategory] = useState("");
  const [ipRating, setIpRating] = useState("");
  const [epl, setEpl] = useState("");
  const [protectionStandard, setProtectionStandard] = useState("");
  const [gpscord, setgpscord] = useState("");
  const [equpcord, setEqucord] = useState("");

  const equipmentFormRef = useRef(null);

  useEffect(() => {
    if (isSubmit) {
      handleSubmit();
      setIsSubmit(false);
    }
  }, [isSubmit]);

  useEffect(() => {
    if (assetId !== undefined) {
      try {
        assetService.getAssetById(String(assetId)).then((assetData) => {
          setValues(assetData);
          let data =
            assetData?.locationLatitude + "," + assetData?.locationLongitude;
          let dataeq =
            assetData?.eqpmtLatitude + "," + assetData?.eqpmtLongitude;
          setgpscord(data);
          setEqucord(dataeq);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const handleEquipmentCategory = (e) => {
    setEquipmentCategory({ equipmentCategory });
  };

  const handleGasGroup = (e) => {
    setGasGroup({ gasGroup });
  };

  const handleTclass = (e) => {
    setTClass({ tclass });
  };

  const handleAtexCategory = (e) => {
    setAtexCategory({ atexCategory });
  };

  const handleipRating = (e) => {
    setIpRating({ ipRating });
  };

  const handleEpl = (e) => {
    setEpl({ epl });
  };

  const handleProtectionStandard = (e) => {
    setProtectionStandard({ protectionStandard });
  };

  const handleRfid = (e) => {
    setValues({ ...values, rfidReference: e.target.value });
  };

  const handleInspection = (e) => {
    setValues({ ...values, inspectionReference: e.target.value });
  };

  const handleEquipmentTag = (e) => {
    setValues({ ...values, equipmentTag: e.target.value });
  };

  const handlecircuitId = (e) => {
    setValues({ ...values, circuitId: e.target.value });
  };

  const handleDescription = (e) => {
    setValues({ ...values, description: e.target.value });
  };

  const handleTambient = (e) => {
    setValues({ ...values, tambient: e.target.value });
  };

  const handleManufacturer = (e) => {
    setValues({ ...values, manufacturer: e.target.value });
  };

  const handleCertification = (e) => {
    setValues({ ...values, certificationBody: e.target.value });
  };

  const handleTypemodel = (e) => {
    setValues({ ...values, typeModel: e.target.value });
  };

  const handleProtectionType = (e) => {
    setValues({ ...values, protectionType: e.target.value });
  };

  const handleCertificationNum = (e) => {
    setValues({ ...values, certificationNumber: e.target.value });
  };

  const handleSerialNum = (e) => {
    setValues({ ...values, serialNumber: e.target.value });
  };

  const handleSpecialConditions = (e) => {
    setValues({ ...values, specialConditions: e.target.value });
  };

  const handleGps = (e) => {
    setValues({ ...values, gpsCoordinates: e.target.value });
  };

  const handleSubmit = async () => {
    let equipmentData = {};
    [...equipmentFormRef.current.elements].map((ele) => {
      equipmentData[ele.name] = ele.value;
    });

    try {
      await equipmentService.addEquipmentTag(equipmentData).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit} formRef={equipmentFormRef}>
        <Row>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="RFID Reference"
              value={values.rfidRef ? values.rfidRef : ""}
              readOnly
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Inspection Reference"
              value={
                values.inspectionReferenceNumber
                  ? values.inspectionReferenceNumber
                  : ""
              }
              readOnly
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="GPS Coordinates"
              value={equpcord ? equpcord : ""}
              readOnly
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Equipment Category"
              name="eqpmtCatg"
              placeholder="Electrical"
              data={equipmentCategoryData}
              readOnly
              value={values.eqpmtCatg ? values.eqpmtCatg : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Equipment Tag"
              readOnly
              value={values.eqpmtTag ? values.eqpmtTag : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Circuit ID"
              readOnly
              value={values.circuitId ? values.circuitId : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Description"
              readOnly
              value={values.description ? values.description : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Manufacturer"
              readOnly
              value={values.manufacturer ? values.manufacturer : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Type/Model"
              readOnly
              value={values.type ? values.type : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Serial Number"
              readOnly
              value={values.serialNumber ? values.serialNumber : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="ATEX Category"
              name="atexCatg"
              data={atexCategoryData}
              readOnly="true"
              value={values.atexCatg ? values.atexCatg : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="EPL"
              name="epl"
              placeholder="EPL"
              data={eplData}
              readOnly="true"
              value={values.epl ? values.epl : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Protection Standard"
              readOnly
              value={values.protectionStd ? values.protectionStd : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Protection Type"
              value={values.protectionType ? values.protectionType : ""}
              readOnly
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Gas Group"
              name="gasGroup"
              placeholder="IIC"
              data={gasGroupData}
              readOnly
              value={values.equipmentGasGroup ? values.equipmentGasGroup : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="T-Class"
              name="tClass"
              placeholder="T5"
              data={tClassData}
              readOnly
              value={values.equipmentTClass ? values.equipmentTClass : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="IP Rating"
              name="ipRating"
              placeholder="65"
              data={ipRatingData}
              readOnly
              value={values.equipmentIpRating ? values.equipmentIpRating : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="T-Ambient (°C)"
              readOnly
              value={values.tAmbient ? values.tAmbient : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Certification Body"
              value={values.certfnBody ? values.certfnBody : ""}
              readOnly
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Certification Number"
              value={values.certfnNo ? values.certfnNo : ""}
              readOnly
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Oracle ID"
              value={values.oracleId ? values.oracleId : ""}
              readOnly
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12}>
            <ReadOnlyInput
              label="Special Conditions"
              value={values.specialCond ? values.specialCond : ""}
              readOnly
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EquipmentTag;
