import React, { useState, useEffect, useRef } from "react";
import { FiChevronDown } from "react-icons/fi";

const MonthlyData = ({ onChange }) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);

  const filterOptions = ["Monthly", "Yearly"];

  const prevScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && isActive) {
        setIsActive(false);
      }
      prevScrollY.current = currentScrollY;
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isActive]);

  return (
    <>
      <div className="chart-filter">
        <button
          className={`chart-filter__toggle ${isActive && "active"}`}
          onClick={(e) => {
            setIsActive(!isActive);
          }}
        >
          <span className="toggle-label">{filterOptions[selectedOption]}</span>
          <FiChevronDown size={20} />
        </button>
        <div
          className={`chart-filter__dropdown ${isActive && "open"}`}
          style={{ display: isActive ? "block" : "none" }}
          tabIndex={0}
        >
          {filterOptions.map((option, index) => (
            <button
              className={`chart-filter__item ${
                selectedOption === index ? "active" : ""
              }`}
              id={option}
              role="option"
              aria-selected={selectedOption === index}
              tabIndex={3}
              onClick={() => {
                setSelectedOption(index);
                setIsActive(false);
                onChange(filterOptions[index]);
              }}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default MonthlyData;
