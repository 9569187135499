import React, { Component } from "react";
import Chart from "react-apexcharts";
import { Navigate } from 'react-router-dom';

class InspectionStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      options: {
        colors: ["#FF0000", "#E1E103", "#00B050"],
        legend: {
          show: false,
        },
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              
              if(config.dataPointIndex===0) { 
                this.myFunction(config.dataPointIndex)
              }
              else if(config.dataPointIndex===1) {
                this.myFunction(config.dataPointIndex)

              }
              else if(config.dataPointIndex===2) {
                this.myFunction(config.dataPointIndex)
              }
            }
          },
          dropShadow: {
            enabled: false,
            enabledOnSeries: undefined,
            top: 0,
            left: 0,
            blur: 10,
            color: "#000",
            opacity: 0.1,
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: {
                enabled: true,
              },
            },
            export: {
              svg: {
                filename: 'Inspection Overview Status',
              },
              png: {
                filename: 'Inspection Overview Status',
              }
            },
            autoSelected: 'zoom'
          },
        },
        labels: ["Red Tags", "Yellow Tags", "Green Tags"],
        responsive: [
          {
            breakpoint: 1600,
            options: {
              chart: {
                height: 302,
                width: 302,
              },
              dataLabels: {
                style: {
                  fontSize: "12px",
                },
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                width: '100%',
                height: 267,
              },
              dataLabels: {
                style: {
                  fontSize: "12px",
                },
              },
            },
          },
          {
            breakpoint: 990,
            options: {
              chart: {
                width: "100%",
                height: 310,
              },
              dataLabels: {
                style: {
                  fontSize: "12px",
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "400",
                },
              },
            },
          },
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
                height: 300,
              },
              dataLabels: {
                style: {
                  fontSize: "12px",
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "400",
                },
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: true,
            offsetX: 0,
            offsetY: 0,
            customScale: 1,
            dataLabels: {
              offset: -35,
              minAngleToShowLabel: 10,
            },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "400",
          },
        },
      },

      labels: ["Red Tags", "Yellow Tags", "Green Tags"],
    };
  }
  myFunction = (data) =>{
    this.setState({ redirect: true });
  }

  render() {
    const { redirect } = this.state;
    if(redirect) {
      return <Navigate to="/asset-register"/>
    }
    return (
      <>
        <div className="chart-flex">
          <div className="flex-column">
            <Chart
              options={this.state.options}
              series={[
                this.props.red ? this.props.red : 0,
                this.props.yellow ? this.props.yellow : 0,
                this.props.green ? this.props.green : 0,
              ]}
              type="pie"
              height="358px"
            />
          </div>
          <div className="flex-column">
            <div className="legends">
              <div className="legends__item disabled">
                <span className="legends__color total"></span>
                <div>
                  <p className="legends__title">Total Tags</p>
                  {this.props.total ? (
                    <p className="legends__count">{this.props.total}</p>
                  ) : (
                    <p className="legends__count">0</p>
                  )}
                </div>
              </div>
              <div
                className="legends__item"
                onClick={() =>
                  this.props.navigate("/asset-register", {
                    state: {
                      valid: "true",
                      inspectionStatus: "Red",
                      startDate: this.props.values.startDate,
                      endDate: this.props.values.endDate,
                    },
                  })
                }
              >
                <span className="legends__color red"></span>
                <div>
                  <p className="legends__title">Red Tags</p>
                  {this.props.red ? (
                    <p className="legends__count">{this.props.red}</p>
                  ) : (
                    <p className="legends__count">0</p>
                  )}
                </div>
              </div>
              <div
                className="legends__item"
                onClick={() =>
                  this.props.navigate("/asset-register", {
                    state: {
                      valid: "true",
                      inspectionStatus: "Yellow",
                      startDate: this.props.values.startDate,
                      endDate: this.props.values.endDate,
                    },
                  })
                }
              >
                <span className="legends__color yellow"></span>
                <div>
                  <p className="legends__title">Yellow Tags</p>
                  {this.props.yellow ? (
                    <p className="legends__count">{this.props.yellow}</p>
                  ) : (
                    <p className="legends__count">0</p>
                  )}
                </div>
              </div>
              <div
                className="legends__item"
                onClick={() =>
                  this.props.navigate("/asset-register", {
                    state: {
                      valid: "true",
                      inspectionStatus: "Green",
                      startDate: this.props.values.startDate,
                      endDate: this.props.values.endDate,
                    },
                  })
                }
              >
                <span className="legends__color green"></span>
                <div>
                  <p className="legends__title">Green Tags</p>
                  {this.props.green ? (
                    <p className="legends__count">{this.props.green}</p>
                  ) : (
                    <p className="legends__count">0</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default InspectionStatus;
