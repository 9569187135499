import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { TextArea, Spinner } from "../../../components";
import { images } from "../../../constants";
import validator from "validator";
import assetService from "../../../services/assetService";
import * as moment from "moment-timezone";
import { AiOutlineFileText } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import equipmentService from "../../../services/equipmentService";
import { toast } from "react-toastify";
import Table from "react-bootstrap/Table";
import fileService from "../../../services/fileService";
import userService from "../../../services/userService";
import {
  Form,
  FormInput,
  FormSelect,
  MixedInput,
  ReadOnlyInput,
} from "../../../components";
import { useSelector } from "react-redux";

const DefectAnalysis = ({
  isSubmit,
  setIsSubmit,
  locationId,
  setLocationId,
  assetId,
  setDefectData,
  defectData,
  isClear,
  setIsClear,
  next,
  setNext,
  ...props
}) => {
  const [inspectedBy, setInspectedBy] = useState("");
  const [inspectionStatus, setInspectionStatus] = useState("");
  const [defectCategory, setDefectCategory] = useState("");
  const [signaturePath, setSignaturePath] = useState("");
  const [overall, setOverall] = useState("");
  const [materials, setMaterials] = useState("");
  const [isolations, setIsolations] = useState("");
  const [safeToOperate, setSafeToOperate] = useState("");
  const [repairPriority, setRepairPriority] = useState("");
  const [other, setOther] = useState("");
  const [materialsDatas, setMaterialsData] = useState([]);
  const [qty, setQty] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [requiredMsg, setRequired] = useState("");
  const [filename, setFilename] = useState("");
  const [file, setFile] = useState("");
  const [showAreaFile, setShowAreaFile] = useState(false);
  const [valid, setValid] = useState(false);
  // const [values, setValues] = useState({
  //   comments: "",
  //   spec1: "",
  //   spec2: "",
  //   spec3: "",
  //   faultyItems: "",
  //   overallCondition: "",
  //   inpectedDate: "",
  //   remarks: "",
  //   units:""
  // });

  const [values, setValues] = useState({});
  const [assetDetails, setAssetDetails] = useState({});

  const defectAnalysisRef = useRef(null);

  const lookup = useSelector((state) => state.lookupData.data);
  const subLookup = useSelector((state) => state.subLookupData);

  useEffect(() => {
    if (assetId !== undefined) {
      try {
        assetService.getAssetById(String(assetId)).then((assetData) => {
          setAssetDetails(assetData);
          setValues(assetData);
          setMaterialsData(assetData?.materials);
          setQty(assetData.materials[0].qty);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, []);


  const Image = ({ srcvale }) => {
    const [loading, setLoading] = useState(true);

    return (
      <>
        {loading ? <Spinner /> : ""}
        <img
          src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${srcvale}`}
          className={"defect-img"}
          alt="defective photos"
          onClick={() => {
            window.open(`${process.env.REACT_APP_BACKEND_BASE_URL}/${srcvale}`);
          }}
          onLoad={() => setLoading(false)}
        />
      </>
    );
  };
  useEffect(async () => {
    let data = await userService.getAllUsers();
    data?.users?.map((item) => {
      if (item.username?.includes(values?.inspectedBy)) {
        setSignaturePath(item?.signature);
      }
    });
  }, [values]);
  useEffect(() => {
    if (isSubmit) {
      handleSubmit();
      setIsSubmit(false);
      setNext(true);
    }
  }, [isSubmit]);

  useEffect(() => {
    if (isClear) {
      handleClear();
      setIsClear(false);
    }
  }, [isClear]);

  const handleClear = () => {
    setValues("");
    setValid(true);
    setDefectData("");
  };

  const handleComment = (e) => {
    setMaterials({ materials });
  };

  const handleMaterials = (e) => {
    setValues({ ...values, materialRequirements: e.target.value });
  };
  const handleSpecification = (e) => {
    setValues({ ...values, specification: e.target.value });
  };
  const handleUnits = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    setValues({ ...values, units: value });
  };
  const handleQuantity = (e) => {
    setValues({ ...values, quantity: e.target.value });
  };

  const handlefaultyItems = (e) => {
    setValues({ ...values, faultyItems: e.target.value });
  };

  const handleIsolations = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    setValues({ ...values, defectIsolation: value });
  };

  const handleInspectionStatus = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    setValues({ ...values, inspectionStatus: value });
  };

  const handleDefectCategory = (event, data) => {
    // setDefectCategory({defectCategory});
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    setValues({ ...values, defectDefectCategory: value });
  };

  const handleOther = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    setValues({ ...values, defectOtherRequirements: value });
  };

  const handleOverall = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);

    setValues({ ...values, defectOverallCondition: value });
  };

  const handleSafetoOperate = (e) => {
    setSafeToOperate({ safeToOperate });
  };
  const handleDatasheet = (e) => {
    setValues({ ...values, dataSheetNo: e.target.value });
  };
  const handleRepairPriority = (e) => {
    setRepairPriority({ repairPriority });
  };

  const handleInspectedDate = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    setValues({ ...values, inspectedDate: value });
  };

  const handleRemarks = (e) => {
    setValues({ ...values, remarks: e.target.value });
  };

  const handleInspectedBy = (e) => {
    setValues({ ...values, inspectedBy: e.target.value });
  };
  const handleSignature = (e) => {
    setValues({ ...values, Signature: e.target.value });
  };

  const dummyData = [
    {
      value: "dataone",
      text: "Data 1",
    },
    {
      value: "datatwo",
      text: "Data 2",
    },
    {
      value: "datathree",
      text: "Data 3",
    },
  ];
  let unitsData = [];
  let overallConditionData = [];
  let inspectionStatusData = [];
  let isolationsData = [];
  let otherRequirementsData = [];
  let DefectCategoryDatas = [
    {
      value: "1",
      text: "1 ",
    },
    {
      value: "2",
      text: "2",
    },
    {
      value: "3",
      text: "3",
    },
    {
      value: "4",
      text: "4 ",
    },
    {
      value: "5",
      text: "5",
    },
    {
      value: "Not Applicable",
      text: "Not Applicable",
    },
  ];
  let MaterialsData = [];

  if (lookup) {
    lookup?.Units?.map((ele) => {
      unitsData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });

    lookup?.OverallCondition?.map((ele) => {
      overallConditionData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });
    lookup?.InspectionStatus?.map((ele) => {
      inspectionStatusData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });
    lookup?.Isolations?.map((ele) => {
      isolationsData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });
    lookup?.OtherRequirements?.map((ele) => {
      otherRequirementsData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });

    // lookup?.DefectCategory?.map((ele) => {
    //   DefectCategoryData.push({
    //     key: ele._id,
    //     text: ele.value,
    //     value: ele.value,
    //   });
    // });

    lookup?.Materials?.map((ele) => {
      MaterialsData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });
  }

  const handleDatsheet = async (e) => {
    let selFile = e.target.files[0];
    setFile(selFile);
    setFilename(e.target.files[0].name);
    const formData = new FormData();
    formData.append("file", selFile);
    if (selFile !== undefined) {
      setShowAreaFile(true);
    }
    try {
      await fileService.fileUpload(formData, "dataSheet").then(
        (response) => {
          if (response?.data?.status) {
            setValues({
              ...values,
              dataSheet: response?.data?.data?.uploadStatus?.file,
              dataSheetNo: response?.data?.data?.uploadStatus?.originalName,
            });
          } else {
            toast.error("This format is not supported");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    setDefectData(values);

    let defectData = {};
    [...defectAnalysisRef.current.elements].map((ele) => {
      defectData[ele.name] = ele.value;
    });

    let newData = {
      faultyItems: values?.faultyItems ? values?.faultyItems : "",
      inspectionStatus: values?.inspectionStatus
        ? values?.inspectionStatus
        : "",
      defectDefectCategory: values?.defectDefectCategory
        ? values?.defectDefectCategory
        : "",
      defectOverallCondition: values?.defectOverallCondition
        ? values?.defectOverallCondition
        : "",
      remarks: values?.remarks ? values?.remarks : "",
      defectIsolation: values?.defectIsolation ? values?.defectIsolation : "",
      defectOtherRequirements: values?.defectOtherRequirements
        ? values?.defectOtherRequirements
        : "",
      inspectedBy: values?.inspectedBy ? values?.inspectedBy : "",
      inspectedDate: values?.inspectedDate ? values?.inspectedDate : "",
      checkList: values?.checkList ? values?.checkList : "",
      materials: values?.materials ? values?.materials : "",
      dataSheetOrgName: values?.dataSheetOrgName
        ? values?.dataSheetOrgName
        : "",
      dataSheet: values?.dataSheet ? values?.dataSheet : "",
      dataSheetNo: values?.dataSheetNo ? values?.dataSheetNo : "",
      additionalComments: values?.additionalComments
        ? values?.additionalComments
        : "",
    };
    try {
      await equipmentService.addEquipmentTagById(assetId, newData).then(
        (response) => {
          toast.success(response.data.msg);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      setShowModal(true);
      setStatusMsg("Failed");
    }
  };

  return (
    <>
      {assetId ? (
        <>
          <div className="asset-table spacing-in table-align-left">
            <Table striped>
              <thead>
                <tr>
                  <th style={{ width: 150, maxWidth: 120 }}>Defect Code</th>
                  <th>Findings</th>
                  <th>Remedial Actions</th>
                  <th>Remarks</th>
                </tr>
              </thead>

              <tbody>
                {assetDetails &&
                  assetDetails?.checkList?.map((item, index) =>
                    item?.findingsAndActions?.map((list, i) => {
                      if (list.isSelected != undefined) {
                        if (list?.isSelected === true) {
                          return (
                            <tr key={i}>
                              <td>{list?.defectCode}</td>
                              <td>{list?.finding}</td>
                              {/* <td>{list?.remedialAction}</td> */}
                              <td>
                                <input
                                  className="wo-input"
                                  type="text"
                                  value={list?.remedialAction}
                                  onChange={(ev) => {
                                    values.checkList[index].findingsAndActions[
                                      i
                                    ].remedialAction = ev.target.value;
                                    list.remedialAction = ev.target.value;
                                    setValues({ ...values });
                                    setAssetDetails({ ...assetDetails });
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  className="wo-input"
                                  type="text"
                                  placeholder="Remarks here..."
                                  value={list?.remark}
                                  onChange={(ev) => {
                                    values.checkList[index].findingsAndActions[
                                      i
                                    ].remark = ev.target.value;
                                    list.remark = ev.target.value;
                                    setValues({ ...values });
                                    setAssetDetails({ ...assetDetails });
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        }
                      }
                    })
                  )}
              </tbody>
            </Table>
          </div>
          <TextArea
            label="Additional Comments"
            className="defect-comment"
            placeholder="Type Here..."
            value={values.additionalComments ? values.additionalComments : ""}
            onChange={(ev) => {
              values.additionalComments = ev.target.value;
              setValues({ ...values });
              setAssetDetails({ ...assetDetails });
            }}
          />
          {(assetDetails.defectivePhoto1 ||
            assetDetails.defectivePhoto2 ||
            assetDetails.defectivePhoto3) && <hr className="tab-divider" />}
          {(assetDetails.defectivePhoto1 ||
            assetDetails.defectivePhoto2 ||
            assetDetails.defectivePhoto3) && (
              <h3 className="tab-inner-title">Defective Photos</h3>
            )}
          <div className="defective-photos">
            <Row>
              <Col xxl={4} lg={4} md={4}>
                {assetDetails?.defectivePhoto1 ? (
                  <Image srcvale={assetDetails.defectivePhoto1} />
                ) : (
                  ""
                )}
              </Col>
              <Col xxl={4} lg={4} md={4}>
                {assetDetails?.defectivePhoto2 ? (
                  <Image srcvale={assetDetails.defectivePhoto2} />
                ) : (
                  ""
                )}
              </Col>
              <Col xxl={4} lg={4} md={4}>
                {assetDetails?.defectivePhoto3 ? (
                  <Image srcvale={assetDetails.defectivePhoto3} />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
        </>
      ) : (
        ""
      )}

      <hr className="tab-divider" />
      <Form onSubmit={handleSubmit} formRef={defectAnalysisRef}>
        <Row>
          <Col xxl={12} lg={12}>
            <span className="form-group-label">Defect Analysis Summary</span>
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              type="text"
              label="Faulty Items"
              placeholder="Auto generated"
              name="faultyItems"
              value={values.faultyItems ? values.faultyItems : ""}
            // onChange={handlefaultyItems}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              label="Inspection Status"
              name="inspectionStatus"
              placeholder="Select"
              value={values.inspectionStatus ? values.inspectionStatus : ""}
              data={inspectionStatusData}
              onChange={handleInspectionStatus}
              errormsg={requiredMsg ? requiredMsg : ""}
              required
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              label="Defect Category"
              name="defectCategory"
              placeholder="Select "
              data={DefectCategoryDatas}
              value={
                values.defectDefectCategory ? values.defectDefectCategory : ""
              }
              onChange={handleDefectCategory}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              label="Overall Condition"
              name="overallCondition"
              placeholder="Select "
              data={overallConditionData}
              value={
                values.defectOverallCondition
                  ? values.defectOverallCondition
                  : ""
              }
              onChange={handleOverall}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              label="Isolations"
              name="isolations"
              placeholder="Select "
              data={isolationsData}
              value={values.defectIsolation ? values.defectIsolation : ""}
              onChange={handleIsolations}
              errormsg={requiredMsg ? requiredMsg : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              type="text"
              label="Other Requirements"
              placeholder="Select "
              data={otherRequirementsData}
              value={
                values.defectOtherRequirements
                  ? values.defectOtherRequirements
                  : ""
              }
              onChange={handleOther}
            />
          </Col>
        </Row>
        {assetDetails?.materials!=""&&  <Row>
          <Col xxl={12} lg={12}>
            <span className="form-group-label">Material Requirements</span>
          </Col>
        </Row>}
        {assetDetails?.materials!="" &&  <Row>
          <Col xxl={3} lg={3}>
            {" "}
            <span className="form-label">Part Number</span>
          </Col>
          <Col xxl={3} lg={3}>
            {" "}
            <span className="form-label">Description</span>
          </Col>
          <Col xxl={3} lg={3}>
            {" "}
            <span className="form-label">Units</span>
          </Col>
          <Col xxl={3} lg={3}>
            {" "}
            <span className="form-label">Quantity</span>
          </Col>
        </Row>}

        {assetDetails?.materials?.map((materials, i) => {
          return (
            <Row>
              <Col xxl={3} lg={3}>
                <FormInput
                  type="text"
                  //  label="Material Requirements"
                  placeholder="Enter Part Number"
                  name="materialRequirements"
                  value={valid === true ? "" : materials?._id}
                  onChange={(ev) => {
                    values.materials._id = ev.target.value;
                    materials._id = ev.target.value;
                    setValues({ ...values });
                    setAssetDetails({ ...assetDetails });
                  }}
                  //  required
                  errormsg={requiredMsg ? requiredMsg : ""}
                />
              </Col>
              <Col xxl={3} lg={3}>
                <FormInput
                  type="text"
                  //  label="Description"
                  name="description"
                  placeholder="Enter Description"
                  value={valid === true ? "" : materials?.specification}
                  onChange={(ev) => {
                    values.materials.specification = ev.target.value;
                    materials.specification = ev.target.value;
                    setValues({ ...values });
                    setAssetDetails({ ...assetDetails });
                  }}
                  //  required
                  errormsg={requiredMsg ? requiredMsg : ""}
                />
              </Col>
              <Col xxl={3} lg={3}>
                <FormSelect
                  //  label="Units"
                  name="units"
                  placeholder="Select "
                  data={unitsData}
                  onChange={(event, data) => {
                    values.materials.unit = data.value;
                    materials.unit = data.value;
                    setValues({ ...values });
                    setAssetDetails({ ...assetDetails });
                  }}
                  value={valid === true ? "" : materials?.unit}
                  //  required
                  errormsg={requiredMsg ? requiredMsg : ""}
                />
              </Col>
              <Col xxl={3} lg={3}>
                <FormInput
                  type="number"
                  min="0"
                  //  label="Quantity"
                  name="quantity"
                  placeholder="Enter Quantity"
                  value={valid === true ? "" : materials?.qty}
                  onChange={(ev) => {
                    values.materials.qty = ev.target.value;
                    materials.qty = ev.target.value;
                    setValues({ ...values });
                    setAssetDetails({ ...assetDetails });
                  }}
                  //  required
                  errormsg={requiredMsg ? requiredMsg : ""}
                />
              </Col>
            </Row>
          );
        })}
        <br />
        <Row>
          <Col xxl={3} lg={4}>
            <MixedInput
              label="Data Sheet"
              placeholder="Choose File"
              labelStyle="form-label"
              textValue={values.dataSheetNo ? values.dataSheetNo : ""}
              onFileChange={handleDatsheet}
              onTextChange={handleDatasheet}
            />
            {values.dataSheetNo && (
              <span>
                {" "}
                <span
                  className="document-name"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_BACKEND_BASE_URL}/${values.dataSheet}`
                    );
                  }}
                >
                  {values.dataSheetNo && <AiOutlineFileText size={20} />}
                  {values.dataSheetNo}
                </span>
                <span style={{ marginLeft: "10px" }} className="document-name">
                  {values.eqpmtLytDrawNo && (
                    <RiDeleteBinLine
                      onClick={() => {
                        values.dataSheetOrgName = "";
                        values.dataSheet = "";
                        values.dataSheetNo = "";
                        setValues({ ...values });
                        setAssetDetails({ ...assetDetails });
                      }}
                      size={20}
                    />
                  )}
                </span>
              </span>
            )}
          </Col>
          <Col xxl={9} lg={8}>
            <FormInput
              type="text"
              label="Remarks"
              name="remarks"
              placeholder="Enter here"
              value={values.remarks ? values.remarks : ""}
              onChange={handleRemarks}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col xxl={12} lg={12}>
            <span className="form-group-label">Inspection sign off</span>
          </Col>
        </Row>
        <Row>
          <Col xxl={3} lg={4}>
            {/* <FormInput
              type="text"
              label="Signature"
              name="Signature"
              placeholder="Enter here"
              value={values.Signature ? values.Signature : ""}
              onChange={handleSignature}
            /> */}
            <label htmlFor="signature" className="form-label">Signature</label>
            <div className="sig-border-new-wrap">
              {signaturePath ? (
                <img className="sig-border-new"
                  src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${signaturePath}`}
                />
              ) : (
                ""
              )}
            </div>
          </Col>

          <Col xxl={3} lg={4}>
            <FormInput
              type="text"
              label="Inspected By"
              name="inspectedBy"
              placeholder="Enter here"
              value={values.inspectedBy ? values.inspectedBy : ""}
              onChange={handleInspectedBy}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Inspected Date"
              value={
                values.inspectedDate && values.inspectedDate.includes("+")
                  ? moment
                    .utc(values.inspectedDate)
                    .format("DD/MM/YYYY hh:mm A")
                  : values.inspectedDate
                    ? moment(values.inspectedDate).format("DD/MM/YYYY hh:mm A")
                    : ""
              }
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default DefectAnalysis;
