import React, { useState, useEffect, useContext } from "react";
import { Oval } from "react-loader-spinner";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { MyContext } from "../../routes/Routes";
import assetService from "../../services/assetService";

const MediauploadLoader = ({ LoaderTotal, count }) => {
  const { myState, setMyState } = useContext(MyContext);
  const [ChangeMsg, setChangeMsg] = useState();

  const [isToggle, setIsToggle] = useState(true);

  const toggleLoader = () => setIsToggle(!isToggle);

  return (
    <div className="downloading">
      <div className={`downloading__progress ${isToggle && "show"}`}>
        <Oval
          height={30}
          width={30}
          color="#EA2A2A"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#EA2A2A"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        {LoaderTotal
          ? `Media files ${count}/${LoaderTotal} completed`
          : "loading..."}
      </div>
      <button className="downloading__toggle" onClick={toggleLoader}>
        {isToggle ? <BiChevronRight size={24} /> : <BiChevronLeft size={24} />}
      </button>
    </div>
  );
};

export default MediauploadLoader;
