import React, { useState } from 'react';
import { DateInput } from 'semantic-ui-calendar-react';

const STYLES = [
  "normal",
  "chart-range"
];

const DatePicker = ({ label, value, variable, nameFrom, nameTo, idFrom, idTo, handleFrom, handleTo, fromDate, toDate }) => {

    const setRangeStyle = STYLES.includes(variable) ? variable : STYLES[0];

    return (
        <>
          <div className={`form-group date-group ${setRangeStyle}`}>
            <div className='date-range'>
              <label className='form-label' htmlFor={nameFrom}>{label}</label>
              <DateInput
                id={idFrom}
                name={nameFrom}
                placeholder="dd-mm-yyyy"
                value={value}
                iconPosition="right"
                popupPosition="bottom left"
                onChange={handleFrom}
                dateFormat='DD-MMM-YYYY'
                closable
                closeOnMouseLeave={false}
              />
            </div>
          </div>
        </>
    );
}

export default DatePicker;