import React, { Component, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function CoverageCurrent({ currentStage,values }) {
  const navigate = useNavigate();
  const [xaxiscat, setXaxiscat] = useState([]);
  const [series, setSeries] = useState([
    {
      name: "Total Tags",
      data: [],
      type: "line",
    },
    {
      name: "Green Tags",
      data: [],
      type: "bar",
    },
    {
      name: "Yellow Tags",
      data: [],
      type: "bar",
    },
    {
      name: "Red Tags",
      data: [],
      type: "bar",
    },
  ]);
  let options = {
    chart: {
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
        },
        export: {
          svg: {
            filename: "Defect Coverage - Current Stage",
          },
          png: {
            filename: "Defect Coverage - Current Stage",
          },
        },
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 1440,
        options: {
          chart: {
            height: 400,
            width: '100%',
          },
          dataLabels: {
            enabled: false,
          
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "10px",
              },
            },
          },
        },
      },
      {
        breakpoint: 990,
        options: {
          chart: {
            width: '100%',
            height: 310,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "10px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "10px",
              },
            },
          },
          legend: {
            horizontalAlign: "left",
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
            height: 320,
          },
          dataLabels: {
            enabled: false,
          
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        dataLabels:{
          position: "bottom"
        }
      },
    },
    dataLabels: {
      enabled: false,
    
    },
    stroke: {
      width: [3, 0, 0, 0],
    },
    xaxis: {
      categories: xaxiscat,
      labels: {
        style: {
          colors: "#4F4F4F",
          fontSize: "12px",
          fontFamily: "Halvetica, sans-serif",
          fontWeight: "400",
        },
        minHeight: 160,
        maxHeight: 230,
        rotate: -70,
      },
      tickAmount: 51,
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#4F4F4F",
            fontSize: "14px",
            fontFamily: "Halvetica, sans-serif",
            fontWeight: "400",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
    legend: {
      position: "bottom",
      offsetY: 6,
      show: false,
    
    },
    markers: {
      size: 5,
    },
    fill: {
      opacity: 1,
    },
    colors: ["#FEB019", "#00B050", "#E1E103", "#FF0000", "#44546A", "#FFC000"],
  };
  useEffect(() => {
    setSeries([
      {
        name: "Total Tags",
        data: currentStage?.map((item, i) => {
          return item.total ? item.total : 0;
        }),
        type: "line",
      },
      {
        name: "Green Tags",
        data: currentStage?.map((item, i) => {
          return item.green ? item.green : 0;
        }),
        type: "bar",
      },
      {
        name: "Yellow Tags",
        data: currentStage?.map((item, i) => {
          return item.yellow ? item.yellow : 0;
        }),
        type: "bar",
      },
      {
        name: "Red Tags",
        data: currentStage?.map((item, i) => {
          return item.red ? item.red : 0;
        }),
        type: "bar",
      },
    ]);
    setXaxiscat(
      currentStage?.map((item, i) => {
        var letter = item?.locationArea?.indexOf(
          " ",
          item?.locationArea?.indexOf(" ") + 1
        );

        var word1 = item?.locationArea.substr(0, letter);

        var word2 = item?.locationArea.substr(letter + 1);
        return item.locationArea ? item.locationArea : [];
      })
    );
  }, [currentStage]);

  return (
    <>
      <Chart options={options} series={series} height="450" />
      <div className="columnLegends">
        <div className="columnLegends__item disabled">
          <span
            className="columnLegends__color"
            style={{ backgroundColor: "#FEB019" }}
          ></span>
          <span className="columnLegends__label">Total Tags</span>
        </div>
        <div
          className="columnLegends__item"
          onClick={() =>
            navigate("/asset-register", {
              state: {
                valid: "true",
                currentStatus: "Green",
                startDate: values.startDate,
                endDate: values.endDate,
              },
            })
          }
        >
          <span
            className="columnLegends__color"
            style={{ backgroundColor: "#00B050" }}
          ></span>
          <span className="columnLegends__label">Green Tags</span>
        </div>
        <div
          className="columnLegends__item"
          onClick={() =>
            navigate("/asset-register", {
              state: {
                valid: "true",
                currentStatus: "Yellow",
                startDate: values.startDate,
                endDate: values.endDate,
              },
            })
          }
        >
          <span
            className="columnLegends__color"
            style={{ backgroundColor: "#E1E103" }}
          ></span>
          <span className="columnLegends__label">Yellow Tags</span>
        </div>
        <div
          className="columnLegends__item"
          onClick={() =>
            navigate("/asset-register", {
              state: {
                valid: "true",
                currentStatus: "Red",
                startDate: values.startDate,
                endDate: values.endDate,
              },
            })
          }
        >
          <span
            className="columnLegends__color"
            style={{ backgroundColor: "#FF0000" }}
          ></span>
          <span className="columnLegends__label">Red Tags</span>
        </div>
      </div>
    </>
  );
}

export default CoverageCurrent;
