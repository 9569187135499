import React from 'react';
import { BiCheck } from 'react-icons/bi';

const STYLES = [
    "check-light",
    "check-dark",
    "check-none"
];

const FormChecbox = ({ id, name, label, value,variant, onChange, checked }) => {

    const setCheckboxStyle = STYLES.includes(variant) ? variant : STYLES[0];

    return (
        <>
            <div className='checkbox-wrapper'>
                <input 
                    type='checkbox'
                    onChange={onChange}
                    id={id}
                    checked={checked}
                    name={name}
                    label={label}
                    value={value}
                    className={`form-checkbox ${setCheckboxStyle}`}
                />
                {label && (
                    <label className='checkbox-label'>{label}</label>
                )}
            </div>
        </>
    );
}

export default FormChecbox;