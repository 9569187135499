import React, { useState } from 'react';
import { CgChevronDownO } from 'react-icons/cg';
import { motion, AnimatePresence } from 'framer-motion';

const Accordion = ({ title, progress, children, i }) => {

  const [expanded, setExpanded] = useState(0);

  const isOpen = i === expanded;

  return (
    <>
      <div className={`accordion ${isOpen && 'show'}`}>
        <motion.header 
          initial={false}
          className={`accordion__header ${isOpen ? 'open' : 'close'}`}
          onClick={() => setExpanded(isOpen ? false : i)}
        >
          <div className='header-left'>
            <span>Defect Code</span>
            <span>{title}</span>
          </div>
          <div className='header-right'>
            <span>{progress}</span>
            <CgChevronDownO size={30} />
          </div>
        </motion.header>
        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.div
              key='content'
              initial='collapsed'
              animate='open'
              exit='collapsed'
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 }
              }} 
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              className='accordion__content'
            >
              <motion.div
                variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
                transition={{ duration: 0.8 }}
                className='accordion__content-inner'
              >
                {children}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  )
}

export default Accordion;