import React, { Component, useState, useEffect } from "react";
import Chart from "react-apexcharts";

function FailureTrend({ trend, resultvalue, description }) {
  const [xaxiscat, setXaxiscat] = useState([]);
  const [series, setSeries] = useState([
    {
      name: [],
      type: "column",
      data: [],
    },
  ]);
  let options = {
    chart: {
      type: "line",
      stacked: false,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
        },
        export: {
          svg: {
            filename: "Failure Trend Analysis",
          },
          png: {
            filename: "Failure Trend Analysis",
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 1440,
        options: {
          chart: {
            height: 310,
            width: "100%",
          },
          dataLabels: {
            style: {
              fontSize: "12px",
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
        },
      },
      {
        breakpoint: 990,
        options: {
          chart: {
            width: "100%",
            height: 310,
          },
          dataLabels: {
            style: {
              fontSize: "12px",
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "400",
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          legend: {
            horizontalAlign: "left",
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
            height: 320,
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
      offsetY: -25,
      style: {
        fontSize: "14px",
        fontFamily: "Halvetica, sans-serif",
        fontWeight: "normal",
        colors: ["#4F4F4F"],
      },
    },
    stroke: {
      width: [0],
    },
    xaxis: {
      categories: [
        'A1',
        'A2',
        'A3',
        'A4',
        'A5',
        'A6',
        'A7',
        'A8',
        'A9',
        'A10',
        'A11',
        'A12',
        'A13',
        'A14',
        'A15',
        'A16',
        'A17',
        'A18',
        'A19',
        'A20',
        'A21',
        'A22',
        'A23',
        'A24',
        'A25',
        'A26',
        'A27',
        'A28',
        'A29',
        'A30',
        'A31',
        'B1',
        'B2',
        'B3',
        'B4',
        'B5',
        'B6',
        'B7',
        'B8',
        'B9',
        'B10',
        'B11',
        'B12',
        'B13',
        'B14',
        'B15',
        'B16',
        'B17',
        'B18',
        'B19',
        'B20',
        'B21',
        'B22',
        'C1',
        'C2',
        'C3',
      ],
      labels: {
        style: {
          colors: "#4F4F4F",
          fontSize: "12px",
          fontFamily: "Halvetica, sans-serif",
          fontWeight: "normal",
        },
        rotate: -45,
        rotateAlways: true,
      },
      tickAmount: 56,
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#4F4F4F",
            fontSize: "14px",
            fontFamily: "Halvetica, sans-serif",
            fontWeight: "normal",
          },
        },
        tooltip: {
          enabled: true,
        },
        forceNiceScale: false,
        decimalsInFloat: 0,
      },
    ],
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    colors: ["#C55A11"],
  };

  useEffect(() => {
    setSeries([
      {
        name: "Failure Trend",
        type: "column",
        data:resultvalue,
      },
    ]);
    setXaxiscat(
      trend
    );
  }, [description]);

  return (
    <>
      <Chart options={options} series={series} height="380" />
    </>
  );
}

export default FailureTrend;
