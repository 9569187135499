import React from 'react';

const PageHeader = ({ title, subtitle, children, className }) => {
  return (
    <>
        <div className={`app__header ${className}`}
           title={title}
           subtitle={subtitle}
        >
            <div className='app__header--wrapper'>
              <h2 className='app__header--title'>{title}</h2>
              <h5 className='app__header--subtitle'>{subtitle}</h5>
            </div>
            <div className='app__btns'>
                {children}
            </div>
        </div>
    </>
  )
}

export default PageHeader;