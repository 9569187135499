import React, { useState, useEffect } from "react";
import { TbCheck } from "react-icons/tb";
import { VscClose } from "react-icons/vsc";
import { AnimatePresence, motion } from "framer-motion";
import Table from "react-bootstrap/Table";

const AccordionChild = ({ defectCode, title, i, checkListData }) => {
  const [expanded, setExpanded] = useState(false);
  const isCollapse = i === expanded;
  return (
    <>
      <div className={`accordion_lite ${expanded && "show"}`}>
        <motion.header
          initial={false}
          onClick={() => setExpanded(isCollapse ? false : i)}
          className="accordion_lite--header"
        >
          <div className="header-content">
            <span className="header-defect">{defectCode}</span>
            <p className="header-title">{title}</p>
          </div>
          {checkListData?.find((item) => item.defectCode == defectCode) ? (
            <span className="header-icon  failed">
              <VscClose size={15} />
            </span>
          ) : (
            <span className="header-icon success">
              <TbCheck size={15} />
            </span>
          )}
        </motion.header>
        <AnimatePresence initial={false}>
          {isCollapse && (
            <motion.div
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              className="accordion_lite--content"
            >
              <div className="content-inner">
                <div className="inspection-table table-align-left">
                  <div className="asset-table checklist-table spacing-in">
                    {checkListData.find(
                      (item) => item.defectCode == defectCode
                    ) ? (
                      <Table striped>
                        <thead>
                          <tr>
                            <th>Findings</th>
                            <th>Remedial Actions</th>
                          </tr>
                        </thead>
                        {checkListData?.map((data) =>
                          data?.findingsAndActions?.map((item) => {
                            if (item.isSelected != undefined) {
                              if (item?.isSelected === true) {
                                return item?.defectCode == defectCode ? (
                                  <tbody>
                                    <tr>
                                      <>
                                        <td>{item?.finding}</td>
                                        <td>{item?.remedialAction}</td>
                                      </>
                                    </tr>
                                  </tbody>
                                ) : (
                                  ""
                                );
                              }
                            }
                          })
                        )}
                      </Table>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default AccordionChild;
