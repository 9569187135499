import React from 'react';

const ReadOnlyInput = ({value, label, className, disabled, readOnly}) => {

    return (
        <div className={`form-group ${className}`}>
            <label className='form-label'>{label}</label>
            <span 
                className={`
                    form-input
                    ${readOnly && 'readonly'}
                    ${disabled && 'disabled'}
                `}
                disabled={disabled}
            >{value}</span>
        </div>
    );
}

export default ReadOnlyInput