import React, { useState } from "react";
import axios from "axios";
import fileService from "../../services/fileService";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { MediauploadLoader } from "../../components";
import { toast } from "react-toastify";


function OtherFileUpload() {
 
  let counts = 0;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [count, setCount] = useState(0);
  const [LoaderTotal, setLoaderTotal] = useState();
  const [LoaderTotal2, setLoaderTotal2] = useState();
  const [loading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    const files = [...e.target.files];
    setSelectedFiles(files);
    setLoaderTotal2(files.length);
    const names = files.map((file) => file.name);
    setFileNames(names);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);

      const response = await fileService.MultifileUpload(
        formData,
        "reamainFile"
      );
      setCount((prevCount) => prevCount + 1);
      return response;
    } catch (error) {
      toast.error("This format is not supported");
      console.error("File upload failed:", error);
      throw error;
    }
  };

  const uploadAllFiles = async () => {
    setLoaderTotal(selectedFiles.length);
    setIsLoading(true);
    try {
      for (const file of selectedFiles) {
        await uploadFile(file);

      }
    } finally {
      setIsLoading(false);
      toast.success("Media files uploaded successfully");
     
      setLoaderTotal2()
      setCount(0)
      setSelectedFiles([])
     


    }
  };

  return (
    <div className="file_upload_wrapper">
      <div className="upload-input">
        <Button
          component="label"
          variant="contained"
          startIcon={<AiOutlineCloudUpload />}
        >
          Select file
          <VisuallyHiddenInput type="file" multiple onChange={handleFileChange} />
        </Button>

        {selectedFiles.length !== 0 && (
          <div className="file_name_listing">
            <p></p>
            <ul>{LoaderTotal2 ? `${LoaderTotal2} files selected` : ""}</ul>

            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              onClick={uploadAllFiles}
              className="click click-primary file_up_bttn"
            >
              Upload Media files
            </Button>
            {loading && (
              <MediauploadLoader LoaderTotal={LoaderTotal} count={count} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default OtherFileUpload;
