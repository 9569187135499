import React, { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { images } from "../../constants";
import { FiChevronDown } from "react-icons/fi";
import { BiUser } from "react-icons/bi";
import { TbLogout } from "react-icons/tb";
import { Link } from "react-router-dom";
import userContext from "../../contextObject/global";
import authService from "../../services/authService";
import { Button, Modal } from "../../components";
const Header = () => {
  const [isOpen, setOpen] = useState(false);
  const toggleMenu = () => setOpen(!isOpen);
  const user = useContext(userContext);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const prevScrollY = useRef(0);

  const logoutHandler = () => {
    authService.logout();
    navigate("/login");
    window.location.reload();
  };
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && isOpen) {
        setOpen(false);
      }
      prevScrollY.current = currentScrollY;
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isOpen]);

  return (
    <div className="header">
      <div className="header__logo">
        <img src={images.logo} alt="Asset Integrity Managment" />
      </div>
      <Link to className="header__logout" onClick={() => openModal()}>
        Logout
        <TbLogout size={23} />
      </Link>

      {showModal && (
        <Modal
          closeModal={closeModal}
          title="Logout?"
          text="Are you sure you want to logout?"
        >
          <Button variant="click-gray" size="click-lg" onClick={closeModal}>
            No
          </Button>
          <Button
            onClick={() => logoutHandler()}
            variant="click-primary"
            size="click-lg"
          >
            Yes
          </Button>
        </Modal>
      )}
    </div>
  );
};

export default Header;
