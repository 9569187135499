import React from 'react'
import { Row, Col } from "react-bootstrap";
import OtherFileUpload from './fileUpload';
import DefectiveFileUpload from './DefectfileUpload';
import CorrectiveFileUpload from './CorrectivefileUpload';
import { Card } from '../../components';

function FileUploadUI() {
  return (
    <div>
      <Card variant='remove-header' className='locator-card up-media-filesWrap'>
        <div class="app__header--wrapper">
          <h2 class="app__header--title file_upload_title wrap">
            Drop Media Files here to Upload
          </h2>
        </div>
        <Row className='up-font-wrap'>
          <Col sm={12} lg={4}>
            <div class="app__header--wrapper-main">
              <h4 class="app__header--title file_upload_title">
                Drawings & Documents
              </h4>
            </div>
            <div className="frame drop_upload_wrap main">
              <OtherFileUpload />
            </div>
          </Col>
          <Col sm={12} lg={4}>
            <div class="app__header--wrapper-main">
              <h4 class="app__header--title file_upload_title">
                Inspection Photos
              </h4>
            </div>
            <div className="frame drop_upload_wrap main">
              <DefectiveFileUpload />
            </div>
          </Col>
          <Col sm={12} lg={4}>
            <div class="app__header--wrapper-main">
              <h4 class="app__header--title file_upload_title">
                Corrective Photos
              </h4>
            </div>
            <div className="frame drop_upload_wrap main">
              <CorrectiveFileUpload />
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default FileUploadUI