import React, { useState } from 'react';
import { DateInput } from 'semantic-ui-calendar-react';

const STYLES = [
  "normal",
  "chart-range"
];

const DateRange = ({valueFrom, valueTo, variable, nameFrom, nameTo, idFrom, idTo, handleFrom, handleTo, fromDate, toDate}) => {

  // const [fromDate, setFromDate] = useState('');
  // const [toDate, setToDate] = useState('');

  // const handleFrom = (event, { name, value }) => {
  //   setFromDate(value);
  // };

  // const handleTo = (event, { name, value }) => {
  //   setToDate(value);
  // };

  const handleKeyDown = (e) => {
    if (e.keyCode === 8) {
      // Backspace key
      e.preventDefault();
    }
  };

    const setRangeStyle = STYLES.includes(variable) ? variable : STYLES[0];

    return (
      <>
        <div className={`form-daterange ${setRangeStyle}`}>
          <div className="date-range">
            <label className="form-label" htmlFor={nameFrom}>
              Date From
            </label>
            <DateInput
              id={idFrom}
              name={nameFrom}
              placeholder="dd-mm-yyyy"
              value={fromDate}
              iconPosition="right"
              popupPosition="bottom left"
              onChange={handleFrom}
              dateFormat="DD-MMM-YYYY"
              closable
              closeOnMouseLeave={false}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="date-range">
            <label className="form-label" htmlFor={nameTo}>
              Date To
            </label>
            <DateInput
              id={idTo}
              name={nameTo}
              placeholder="dd-mm-yyyy"
              value={toDate}
              iconPosition="right"
              popupPosition="bottom left"
              onChange={handleTo}
              dateFormat="DD-MMM-YYYY"
              closable
              closeOnMouseLeave={false}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
      </>
    );
}

export default DateRange