import React, { useState } from 'react';
import { Select } from 'semantic-ui-react';
import { FiChevronDown } from 'react-icons/fi';

const STYLES = [
    "regular",
    "transparent"
];

const FormSelect = ({ id, name, label, placeholder, data, value, className, groupClass, variant, onSelect, readOnly, disabled, onChange, selected, key, required, errormsg}) => {

    const setSelectStyle = STYLES.includes(variant) ? variant : STYLES[0];

    const handleSelect = (event) => {
        const {value} = event.target;
        onSelect(value);
    }

    return (
        <>
            <div className={`form-group ${setSelectStyle} ${groupClass}`}>
                <label className='form-label' htmlFor={label}>
                    {label}
                    {required && (
                        <span className='form-required'>*</span>
                    )}
                </label>
                <Select
                    id={id}
                    key={key}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    className={`
                        form-select
                        ${className}
                        ${readOnly && 'readonly'}
                        ${disabled && 'disabled'}
                    `}
                    fluid
                    selection
                    options={data}
                    onSelect={handleSelect}
                    onChange={onChange}
                    selected={selected}
                    icon={<FiChevronDown size={25} className='dropdown-icon' />}
                    readOnly={readOnly}
                    disabled={disabled}
                    required={required}
                    errormsg={errormsg}
                />
                {required&&
                    <span className='form-error'>{errormsg}</span>
                }
            </div>
        </>
    );
}

export default FormSelect;