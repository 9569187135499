import React, { useState, useEffect, useRef } from "react";
import { Accordion, AccordionChild, Form } from "../../components";
import inspectionService from "../../services/inspectionService";
import assetService from "../../services/assetService";
import { useParams } from "react-router-dom";

const InspectionChecklistPrint = (id) => {
  const { _id } = useParams();

  let assetId;

  if (_id != undefined) {
    assetId = _id;
  } else {
    assetId = id;
  }

  const [inspectionChecklist, setInspectionChecklist] = useState([]);
  const [inspectionDetails, setInspectionDetails] = useState([]);
  const [totalA, setDefectA] = useState([]);
  const [totalB, setDefectB] = useState([]);
  const [totalC, setDefectC] = useState([]);
  const [defectCountA, setDefectCountA] = useState([]);
  const [defectCountB, setDefectCountB] = useState([]);
  const [defectCountC, setDefectCountC] = useState([]);
  const [checkList, setCheckList] = useState([]);

  const checklistFormRef = useRef(null);

  useEffect(() => {
    if (assetId !== undefined) {
      let details;
      try {
        inspectionService.getAllInspectionList().then((response) => {
          setInspectionChecklist(response);
          details = response?.checkListDetails.filter(
            (ele, ind) =>
              ind ===
              response?.checkListDetails.findIndex(
                (elem) => elem.defectCode === ele.defectCode
              )
          );
          setInspectionDetails(details);
          setDefectA(
            details.filter((item) => {
              return item.defectCategory == "A";
            })
          );
          setDefectB(
            details.filter((item) => {
              return item.defectCategory == "B";
            })
          );
          setDefectC(
            details.filter((item) => {
              return item.defectCategory == "C";
            })
          );
        });
      } catch (error) {
        console.log(error);
      }

      try {
        assetService.getAssetById(String(assetId)).then((assetData) => {
          setCheckList(assetData?.checkList);
          setDefectCountA(
            assetData?.checkList?.filter((f) =>
              f.defectCode.toLowerCase().startsWith("a")
            )
          );
          setDefectCountB(
            assetData?.checkList?.filter((f) =>
              f.defectCode.toLowerCase().startsWith("b")
            )
          );
          setDefectCountC(
            assetData?.checkList?.filter((f) =>
              f.defectCode.toLowerCase().startsWith("c")
            )
          );
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  return (
    <>
      {assetId ? (
        <div formRef={checklistFormRef}>
          {inspectionChecklist &&
            inspectionChecklist?.checkLists?.map((item, i) => (
              <div>
                {item?.defectCategory == "A" ? (
                  <div
                    title={"A. " + item?.checklistName}
                    progress={defectCountA?.length + "/" + totalA?.length}
                    key={i}
                  >
                    {inspectionDetails.map((details, i) => (
                      <div>
                        {details?.defectCategory == "A" ? (
                          <AccordionChild
                            defectCode={details?.defectCode}
                            title={details?.checkListGroup}
                            checkListData={checkList}
                          ></AccordionChild>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </div>
                ) : item?.defectCategory == "B" ? (
                  <div
                    title={"B. " + item?.checklistName}
                    progress={defectCountB?.length + "/" + totalB?.length}
                    key={i}
                  >
                    {inspectionDetails.map((details, i) => (
                      <div>
                        {details?.defectCategory == "B" ? (
                          <AccordionChild
                            defectCode={details?.defectCode}
                            title={details?.checkListGroup}
                            checkListData={checkList}
                          ></AccordionChild>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </div>
                ) : item?.defectCategory == "C" ? (
                  <div
                    title={"C. " + item?.checklistName}
                    progress={defectCountC?.length + "/" + totalC?.length}
                    key={i}
                  >
                    {inspectionDetails.map((details, i) => (
                      <div>
                        {details?.defectCategory == "C" ? (
                          <AccordionChild
                            defectCode={details?.defectCode}
                            title={"C." + details?.checkListGroup}
                            checkListData={checkList}
                          ></AccordionChild>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
        </div>
      ) : (
        <div className="no-data">No data found</div>
      )}
    </>
  );
};

export default InspectionChecklistPrint;
