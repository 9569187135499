import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { MdOutlineCancel } from "react-icons/md";
import { Button } from "../../components";

const SearchLocator = ({
  id,
  name,
  className,
  value,
  placeholder,
  onChange,
  getAssets,
  cancelAll,
  getEnter,
}) => {
  return (
    <>
      <div className={`search-group ${className}`}>
        <input
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          type="search"
          onChange={onChange}
          onKeyPress={(event) => {
            getEnter(event);
          }}
          className={`search-input ${className}`}
        />
        <Button
          variant="click-none"
          size="click-resize"
          className="search-btn"
          onClick={getAssets}
        >
          <RiSearchLine size={20} />
        </Button>
        <Button
          variant="click-none"
          size="click-resize"
          className="cancel-btn"
          onClick={cancelAll}
        >
          <MdOutlineCancel size={20} />
        </Button>
      </div>
    </>
  );
};

export default SearchLocator;
