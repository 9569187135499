import React, { Fragment } from 'react';

import './App.scss';
import  Routes  from './routes/Routes';


const App = ( ) => {

  return (
    <>
       <Routes/>
    </>
  )
}

export default App;