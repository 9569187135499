import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import * as moment from "moment";

function InspectionStatGrade({ grade, values, navigate, filtervalue }) {
  const [xaxiscat, setXaxiscat] = useState([]);
  const [series, setSeries] = useState([
    {
      name: "Total Inspections",
      type: "line",
      data: [],
    },
    {
      name: "Detailed Inspections",
      type: "column",
      data: [],
    },
    {
      name: "Close Inspections",
      type: "column",
      data: [],
    },
    {
      name: "Visual Inspections",
      type: "column",
      data: [],
    },
  ]);

  let options = {
      chart: {
        type: "line",
        stacked: false,
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
          },
          export: {
            svg: {
              filename: "Inspection Statistics - Inspection Grade",
            },
            png: {
              filename: "Inspection Statistics - Inspection Grade",
            },
            csv: {
              filename: "Inspection Statistics - Inspection Grade",
            },
          },
          autoSelected: "zoom",
        },
      },
      responsive: [
        {
          breakpoint: 1440,
          options: {
            chart: {
              height: 310,
              width: "100%"
            },
            dataLabels: {
              style: {
                fontSize: '12px'
              },
            },
            xaxis: {
              labels: {
                style: {
                  fontSize: '12px'
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  fontSize: '12px'
                },
              },
            },
          }
        },
        {
          breakpoint: 990,
          options: {
            chart: {
              width: "100%",
              height: 310,
            },
            dataLabels: {
              style: {
                fontSize: '12px',
                fontFamily: 'Helvetica, sans-serif',
                fontWeight: '400',
              },
            }
          }
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
              height: 320,
            },
            dataLabels: {
              style: {
                fontSize: '12px',
                fontFamily: 'Helvetica, sans-serif',
                fontWeight: '400',
              },
            },
            xaxis: {
              labels: {
                style: {
                  fontSize: '12px'
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  fontSize: '12px'
                },
              },
            },
            legend: {
              horizontalAlign: 'left'
            }
          }
        }
      ],
      dataLabels: {
        enabled: false,
        offsetY: -25,
        style: {
          fontSize: '14px',
          fontFamily: 'Halvetica, sans-serif',
          fontWeight: 'normal',
          colors: ["#4F4F4F"]
        }
      },
      stroke: {
        width: [3, 0, 0, 0],
      },
      xaxis: {
        categories: xaxiscat,
        labels: {
          style: {
            colors: "#4F4F4F",
            fontSize: '14px',
            fontFamily: 'Halvetica, sans-serif',
            fontWeight: 'normal',
          },
        },
        tickPlacement: 'between',
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: "#4F4F4F",
              fontSize: '14px',
              fontFamily: 'Halvetica, sans-serif',
              fontWeight: 'normal',
            },
          },
          tooltip: {
            enabled: true,
          },
        }
      ],
      legend: {
        show: false,
      },
      markers: {
        size: 5,
      },
      colors: ['#FEB019', '#0678EB', '#62AEFB', '#CBE4FE'],
    
  
}
useEffect(()=>{

  setSeries([
    {
      name: 'Total Inspections',
      type: 'line',
      data: grade?.map((item,i)=>{
        return (item.total)?item.total:0
      })
    },
    {
      name: 'Detailed Inspections',
      type: 'column',
      data: grade?.map((item, i) => {
        return (item.detailed)?item.detailed:0

      }),
      // onClick: navigate("/asset-register",{state: {filter: "Detailed", startDate:values.startDate,endDate:values.endDate}})
      
    },
    {
      name: 'Close Inspections',
      type: 'column',
      data: grade?.map((item, i) => {
        return (item.close)?item.close:0
      }),
    },
    {
      name: 'Visual Inspections',
      type: 'column',
      data: grade?.map((item, i) => {
        return (item.visual)?item.visual:0
      }),
    }
  ])
  setXaxiscat(grade?.map((item,i)=>{
   if (filtervalue.type === "monthly") {
     return item?.date ? moment(item?.date).format("MMM-YYYY") : [];
   }
   if (filtervalue.type === "yearly") {
     return item?.date ? moment(item?.date).format("YYYY") : [];
   } else {
     return item?.date ? moment(item?.date).format("DD-MM-YYYY") : [];
   }
  }))
},[grade])
  return (
    <div>
      <>
        <Chart options={options} series={series} width="100%" height="420" />
        <div className="columnLegends">
          <div className="columnLegends__item disabled">
            <span
              className="columnLegends__color"
              style={{ backgroundColor: "#feb019" }}
            ></span>
            <span className="columnLegends__label">Total Inspections</span>
          </div>
          <div
            className="columnLegends__item"
            onClick={() =>
              navigate("/asset-register", {
                state: {
                  valid: "true",
                  inspectionGrade: "Detailed",
                  startDate: values.startDate,
                  endDate: values.endDate,
                },
              })
            }
          >
            <span
              className="columnLegends__color"
              style={{ backgroundColor: "#0678EB" }}
            ></span>
            <span className="columnLegends__label">Detailed Inspections</span>
          </div>
          <div
            className="columnLegends__item"
            onClick={() =>
              navigate("/asset-register", {
                state: {
                  valid: "true",
                  inspectionGrade: "Close",
                  startDate: values.startDate,
                  endDate: values.endDate,
                },
              })
            }
          >
            <span
              className="columnLegends__color"
              style={{ backgroundColor: "#62aefb" }}
            ></span>
            <span className="columnLegends__label">Close Inspections</span>
          </div>
          <div
            className="columnLegends__item"
            onClick={() =>
              navigate("/asset-register", {
                state: {
                  valid: "true",
                  inspectionGrade: "Visual",
                  startDate: values.startDate,
                  endDate: values.endDate,
                },
              })
            }
          >
            <span
              className="columnLegends__color"
              style={{ backgroundColor: "#cbe4fe" }}
            ></span>
            <span className="columnLegends__label">Visual Inspections</span>
          </div>
        </div>
      </>
    </div>
  );
}

export default InspectionStatGrade;
