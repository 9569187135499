import React, { Component } from "react";
import Chart from "react-apexcharts";
class CurrentStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        colors: ["#FF0000", "#E1E103", "#00B050"],
        legend: {
          show: false,
        },
        chart: {
          dropShadow: {
            enabled: false,
            enabledOnSeries: undefined,
            top: 0,
            left: 0,
            blur: 10,
            color: "#000",
            opacity: 0.1,
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: {
                enabled: true,
              },
            },
            export: {
              svg: {
                filename: 'Inspection Overview Current Status',
              },
              png: {
                filename: 'Inspection Overview Current Status',
              }
            },
            autoSelected: 'zoom'
          },
        },
        labels: ["Red Tags", "Yellow Tags", "Green Tags"],

        responsive: [
          {
            breakpoint: 1600,
            options: {
              chart: {
                height: 300,
                width: 300,
              },
              dataLabels: {
                style: {
                  fontSize: "12px",
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "400",
                },
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                width: '100%',
                height: 300,
              },
              dataLabels: {
                style: {
                  fontSize: "12px",
                },
              },
            },
          },
          {
            breakpoint: 990,
            options: {
              chart: {
                width: '100%',
                height: 310,
              },
              xaxis: {
                labels: {
                  style: {
                    fontSize: "12px",
                  },
                },
              },
              yaxis: {
                labels: {
                  style: {
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                horizontalAlign: "left",
              },
            },
          },
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
                height: 320,
              },
              dataLabels: {
                style: {
                  fontSize: "12px",
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "400",
                },
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: true,
            offsetX: 0,
            offsetY: 0,
            customScale: 1,
            dataLabels: {
              offset: -25,
              minAngleToShowLabel: 10,
            },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "400",
          },
        },
      },
      series: [870, 1580, 4650],
      labels: ["Red Tags", "Yellow Tags", "Green Tags"],
    };
  }

  render() {
    return (
      <>
        <div className="chart-flex">
          <div className="flex-column">
            <Chart
              options={this.state.options}
              series={[
                this.props.red ? this.props.red : 0,
                this.props.yellow ? this.props.yellow : 0,
                this.props.green ? this.props.green : 0,
              ]}
              type="pie"
              height="365px"
              width="365px"
            />
          </div>
          <div className="flex-column">
            <div className="legends">
              <div className="legends__item disabled">
                <span className="legends__color total"></span>
                <div>
                  <p className="legends__title">Total Tags</p>
                  {this.props.total ? (
                    <p className="legends__count">{this.props.total}</p>
                  ) : (
                    <p className="legends__count">0</p>
                  )}
                </div>
              </div>
              <div
                className="legends__item"
                onClick={() =>
                  this.props.navigate("/asset-register", {
                    state: {
                      valid: "true",
                      currentStatus: "Red",
                      startDate: this.props.values.startDate,
                      endDate: this.props.values.endDate,
                    },
                  })
                }
              >
                <span className="legends__color red"></span>
                <div>
                  <p className="legends__title">Red Tags</p>
                  {this.props.red ? (
                    <p className="legends__count">{this.props.red}</p>
                  ) : (
                    <p className="legends__count">0</p>
                  )}
                </div>
              </div>
              <div
                className="legends__item"
                onClick={() =>
                  this.props.navigate("/asset-register", {
                    state: {
                      valid: "true",
                      currentStatus: "Yellow",
                      startDate: this.props.values.startDate,
                      endDate: this.props.values.endDate,
                    },
                  })
                }
              >
                <span className="legends__color yellow"></span>
                <div>
                  <p className="legends__title">Yellow Tags</p>
                  {this.props.yellow ? (
                    <p className="legends__count">{this.props.yellow}</p>
                  ) : (
                    <p className="legends__count">0</p>
                  )}
                </div>
              </div>
              <div
                className="legends__item"
                onClick={() =>
                  this.props.navigate("/asset-register", {
                    state: {
                      valid: "true",
                      currentStatus: "Green",
                      startDate: this.props.values.startDate,
                      endDate: this.props.values.endDate,
                    },
                  })
                }
              >
                <span className="legends__color green"></span>
                <div>
                  <p className="legends__title">Green Tags</p>
                  {this.props.green ? (
                    <p className="legends__count">{this.props.green}</p>
                  ) : (
                    <p className="legends__count">0</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CurrentStatus;
