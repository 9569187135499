import React, { useState, useEffect, useRef } from "react";
import { Accordion, AccordionChild, Form } from "../../../components";
import inspectionService from "../../../services/inspectionService";
import assetService from "../../../services/assetService";

const InspectionChecklist = ({
  isSubmit,
  setIsSubmit,
  locationId,
  setLocationId,
  assetId,
  setChecklistData,
  checklistData,
  isClear,
  setIsClear,
  next,
  setNext,
  ...props
}) => {
  const [inspectionChecklist, setInspectionChecklist] = useState([]);
  const [inspectionDetails, setInspectionDetails] = useState([]);
  const [totalA, setDefectA] = useState([]);
  const [totalB, setDefectB] = useState([]);
  const [totalC, setDefectC] = useState([]);
  const [defectCountA, setDefectCountA] = useState([]);
  const [defectCountB, setDefectCountB] = useState([]);
  const [defectCountC, setDefectCountC] = useState([]);
  const [checkList, setCheckList] = useState([]);


  const checklistFormRef = useRef(null);

  useEffect(async () => {
    let filter = {};

    if (assetId !== undefined) {
      let details;

      try {
        await assetService.getAssetById(String(assetId)).then((assetData) => {
          setCheckList(assetData?.checkList);
          
          filter = {
            inspectionType: assetData?.inspectionType,
            equipmentType: assetData?.equipmentEquipmentType,
            checklistName: assetData?.inspectionChecklistType,
            inspectionGrade: assetData?.inspectionGrade,
          };

          setDefectCountA(
            assetData?.checkList?.filter((f) =>
              f.defectCode.toLowerCase().startsWith("a")
            )
          );
          setDefectCountB(
            assetData?.checkList?.filter((f) =>
              f.defectCode.toLowerCase().startsWith("b")
            )
          );
          setDefectCountC(
            assetData?.checkList?.filter((f) =>
              f.defectCode.toLowerCase().startsWith("c")
            )
          );
        });
      } catch (error) {
        console.log(error);
      }
    }

    try {
      inspectionService.getAllInspectionList(filter).then((response) => {
        
        setInspectionChecklist(response);
        let details = response?.checkListDetails.filter(
          (ele, ind) =>
            ind ===
            response?.checkListDetails.findIndex(
              (elem) => elem.defectCode === ele.defectCode
            )
        );
        setInspectionDetails(details);
        setDefectA(
          details.filter((item) => {
            return item.defectCategory == "A";
          })
        );
        setDefectB(
          details.filter((item) => {
            return item.defectCategory == "B";
          })
        );
        setDefectC(
          details.filter((item) => {
            return item.defectCategory == "C";
          })
        );
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      {assetId ? (
        <div formRef={checklistFormRef}>
          {inspectionChecklist &&
            inspectionChecklist?.checkLists?.map((item, i) => (
              <div>
                {item?.defectCategory == "A" ? (
                  <Accordion
                    title={"A. " + item?.checklistName}
                    progress={defectCountA?.length + "/" + totalA?.length}
                    key={i}
                  >
                    {inspectionDetails.map((details, i) => (
                      <div>
                        {details?.defectCategory == "A" ? (
                          <AccordionChild
                            defectCode={details?.defectCode}
                            title={details?.checkListGroup}
                            checkListData={checkList}
                          ></AccordionChild>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </Accordion>
                ) : item?.defectCategory == "B" ? (
                  <Accordion
                    title={"B. " + item?.checklistName}
                    progress={defectCountB?.length + "/" + totalB?.length}
                    key={i}
                  >
                    {inspectionDetails.map((details, i) => (
                      <div>
                        {details?.defectCategory == "B" ? (
                          <AccordionChild
                            defectCode={details?.defectCode}
                            title={details?.checkListGroup}
                            checkListData={checkList}
                          ></AccordionChild>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </Accordion>
                ) : item?.defectCategory == "C" ? (
                  <Accordion
                    title={"C. " + item?.checklistName}
                    progress={defectCountC?.length + "/" + totalC?.length}
                    key={i}
                  >
                    {inspectionDetails.map((details, i) => (
                      <div>
                        {details?.defectCategory == "C" ? (
                          <AccordionChild
                            defectCode={details?.defectCode}
                            title={details?.checkListGroup}
                            checkListData={checkList}
                          ></AccordionChild>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </Accordion>
                ) : (
                  ""
                )}
              </div>
            ))}
        </div>
      ) : (
        <div className="no-data">No data found</div>
      )}
    </>
  );
};

export default InspectionChecklist;
