import React, { useEffect, useState, useRef } from 'react';
import { Form, ReadOnlyInput } from '../../components';
import { Row, Col } from 'react-bootstrap';
import locationService from '../../services/locationService';
import {useParams} from 'react-router-dom';
import assetService from '../../services/assetService';
import { useSelector } from "react-redux";

const LocationDetails = ({isSubmit, setIsSubmit, id, ...props}) => {

  const [values, setValues] = useState([]);

  const { _id } = useParams()

  let assetId;

  if(_id != undefined){
    assetId = _id
  }else {
    assetId = id
  }


  let zoneData= [];
  let ipRatingData = [];
  let gasGroupData = [];
  let tClassData = [];

  const [zone, setZone] = useState('');
  const [gasGroup, setGasGroup] = useState('');
  const [tclass, setTClass] = useState('');
  const [ipRating, setIpRating] = useState('');
  const [areaClass, setAreaClass] = useState('');
  const [equipmentLayout, setEquipmentLayout] = useState('');
  const [gpscord, setgpscord] = useState("")

  const lookup = useSelector((state) => state.lookupData.data);
  const subLookup = useSelector((state) => state.subLookupData);

  useEffect(( )=>{
    
    if(assetId !== undefined){
      try {
        assetService.getAssetById(String(assetId)).then( assetData => {
          setValues(assetData)
          let data = assetData?.locationLatitude + ',' + assetData?.locationLongitude
          setgpscord(data)
        })
      }
      catch(error){
        console.log(error)
      }
    }
  }, []);

  useEffect(()=> {
      if(isSubmit){
        handleSubmit();
        setIsSubmit(false);
      }
  },[isSubmit])

  const locationFormRef = useRef(null);

  const handleLocation = (e) => {
    setValues({...values, location: e.target.value});
  };

  const handleArea = (e) => {
    setValues({...values, area: e.target.value});
  };

  const handleSubArea = (e) => {
    setValues({...values, subarea: e.target.value});
  };

  const handleZone = (e) => {
    setZone({zone});
  };

  const handleGasGroup = (e) => {
    setGasGroup({gasGroup});
  };

  const handleTClass = (e) => {
    setTClass({tclass});
  };

  const handleIpRating = (e) => {
    setIpRating({ipRating});
  };

  const handleAreaClass = (e) => {
    setAreaClass({areaClass});
  };

  const handleEquipment = (e) => {
    setEquipmentLayout({equipmentLayout});
  };

  const handleSubmit= async ( e ) => {
    e.preventDefault();
    let locationData = {};
    [...locationFormRef.current.elements].map((ele) => 
      {
        locationData[ele.name]=ele.value
      })
    locationData['areaClassDrawNo']='areaClassDrawNo';
    locationData['areaClassDrawAttach']='areaClassDrawAttach';
    locationData['areaClassDrawNo']='areaClassDrawNo';
    locationData['eqpmtLytDrawAttach']='eqpmtLytDrawAttach';
    locationData['subAreaCode']='subAreaCode';
    locationData['locationCode']='locationCode';
    locationData['eqpmtLytDrawNo']='eqpmtLytDrawNo';

    try {
      await locationService.addLocation( locationData ).then(
        (response) => {
          
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };


  if(lookup) {
    lookup.Zone?.map((ele) => { 
      zoneData.push({ 
        key: ele._id, 
        text: ele.value, 
        value: ele.value
      })
    });
   
 
    lookup.locationIpRating?.map((ele) => { 
      ipRatingData.push({ 
        key: ele._id, 
        text: ele.value, 
        value: ele.value
      })
    });

  }

  if(subLookup) {
    subLookup.locationTClass?.IEC.map((ele) => { 
      tClassData.push({ 
        key: ele._id, 
        text: ele.fieldValue, 
        value: ele.fieldValue
      })
    });

    subLookup.locationGasGroup?.IEC.map((ele) => { 
      gasGroupData.push({ 
        key: ele._id, 
        text: ele.fieldValue, 
        value: ele.fieldValue
      })
    });
   };

  return (
    <>
      <Form onSubmit={(e) => handleSubmit(e)} formRef={locationFormRef}>
      <Row className='align-items-end'>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label='Location'
              value={values.location?values.location:''}
              readonly
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label='Area'
              value={values.area?values.area:''}
              readonly
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput 
              label='Sub - Area'
              value={values.subArea
                ?values.subArea
                :''}
              readonly
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput 
              label='GPS Coordinates'
              value={gpscord?gpscord:''}
              readonly
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput 
              label='Zone'
              readonly
              value={values.zone?values.zone:''}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput 
              label='Gas Group'
              readonly
              value={values.locationGasGroup
                ?values.locationGasGroup
                :''}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput 
              label='T-Class'
              readonly
              value={values.locationTClass?values.locationTClass:''}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput 
              label='IP Rating'
              readonly
              value={values.locationIpRating
                ?values.locationIpRating
                :''}
            />
          </Col>
          <Col xxl={6} lg={6}>
            <ReadOnlyInput 
              label='Area Classification Drawing Number'
              readonly
              value={values.areaClassDrawNo?values.areaClassDrawNo:''}
            />
          </Col>
          <Col xxl={6} lg={6}>
            <ReadOnlyInput 
              label='Equipment Layout Drawing Number'
              value={values.eqpmtLytDrawNo
                ?values.eqpmtLytDrawNo
                :''}
              readonly
            />
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default LocationDetails;