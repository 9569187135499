import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { images } from "../../constants";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import * as moment from "moment-timezone";
import userService from "../../services/userService";
import {
  Form,
  ReadOnlyInput,
  FormSelect,
  DatePicker,
  FileUpload,
  TextArea,
} from "../../components";
import { useParams } from "react-router-dom";
import assetService from "../../services/assetService";
import { Spinner } from "../../components";
const Image = ({ srcvale }) => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading ? <Spinner /> : ""}
      <img
        src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${srcvale}`}
        className={"defect-img"}
        alt="defective photos"
        onClick={() => {
          window.open(`${process.env.REACT_APP_BACKEND_BASE_URL}/${srcvale}`);
        }}
        onLoad={() => setLoading(false)}
      />
    </>
  );
};

const DefectAnalysis = (id) => {
  const { _id } = useParams();

  let assetId;

  if (_id != undefined) {
    assetId = _id;
  } else {
    assetId = id;
  }

  const [inspectedBy, setInspectedBy] = useState("");
  const [signaturePath, setSignaturePath] = useState("");
  const [inspectionStatus, setInspectionStatus] = useState("");
  const [defectCategory, setDefectCategory] = useState("");
  const [overall, setOverall] = useState("");
  const [materials, setMaterials] = useState("");
  const [isolations, setIsolations] = useState("");
  const [safeToOperate, setSafeToOperate] = useState("");
  const [repairPriority, setRepairPriority] = useState("");
  const [other, setOther] = useState("");
  const [specification, setSpecification] = useState();
  const [units, setUnits] = useState();
  const [qty, setQty] = useState();
  const [materialsDatas, setMaterialsData] = useState([]);
  const [values, setValues] = useState({
    comments: "",
    spec1: "",
    spec2: "",
    spec3: "",
    faultyItems: "",
    overallCondition: "",
    inpectedDate: "",
    remarks: "",
  });
  const lookup = useSelector((state) => state.lookupData.data);
  const subLookup = useSelector((state) => state.subLookupData);

  useEffect(() => {
    if (assetId !== undefined) {
      try {
        assetService.getAssetById(String(assetId)).then((assetData) => {
          setValues(assetData);
          setMaterialsData(assetData?.materials);
          setSpecification(assetData.materials[0].specification);
          setUnits(assetData.materials[0].unit);
          setQty(assetData.materials[0].qty);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const handleComment = (e) => {
    setMaterials({ materials });
  };

  const handleMaterials = (e) => {
    setValues({ ...values, materials: e.target.value });
  };

  const handleSpecification = (e) => {
    setValues({ ...values, spec1: e.target.value });
  };

  const handleUnits = (e) => {
    setValues({ ...values, spec2: e.target.value });
  };

  const handleQuantity = (e) => {
    setValues({ ...values, spec3: e.target.value });
  };

  const handleFaultyItems = (e) => {
    setValues({ ...values, faultyItems: e.target.value });
  };

  const handleIsolations = (e) => {
    setIsolations({ isolations });
  };

  const handleInspectionStatus = (e) => {
    setInspectionStatus({ inspectionStatus });
  };

  const handleDefectCategory = (e) => {
    setDefectCategory({ defectCategory });
  };

  const handleOther = (e) => {
    setOther({ other });
  };

  const handleOverall = (e) => {
    setOverall({ overall });
  };

  const handleSafetoOperate = (e) => {
    setSafeToOperate({ safeToOperate });
  };

  const handleRepairPriority = (e) => {
    setRepairPriority({ repairPriority });
  };

  const handleInspectedDate = (e) => {
    setValues({ ...values, inpectedDate: e.target.value });
  };

  const handleRemarks = (e) => {
    setValues({ ...values, remarks: e.target.value });
  };

  const handleInspectedBy = (e) => {
    setInspectedBy({ inspectedBy });
  };

  let unitsData = [];
  let overallConditionData = [];
  let inspectionStatusData = [];
  let isolationsData = [];
  let otherRequirementsData = [];
  let DefectCategoryDatas = [
    {
      value: "1",
      text: "1 ",
    },
    {
      value: "2",
      text: "2",
    },
    {
      value: "3",
      text: "3",
    },
    {
      value: "4",
      text: "4 ",
    },
    {
      value: "5",
      text: "5",
    },
    {
      value: "Not Applicable",
      text: "Not Applicable",
    },
  ];
  let MaterialsData = [];
  if (lookup) {
    lookup?.Units?.map((ele) => {
      unitsData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });

    lookup?.OverallCondition?.map((ele) => {
      overallConditionData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });
    lookup?.InspectionStatus?.map((ele) => {
      inspectionStatusData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });
    lookup?.Isolations?.map((ele) => {
      isolationsData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });
    lookup?.OtherRequirements?.map((ele) => {
      otherRequirementsData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });

    // lookup?.DefectCategory?.map((ele) => {
    //   DefectCategoryData.push({
    //     key: ele._id,
    //     text: ele.value,
    //     value: ele.value,
    //   });
    // });

    lookup?.Materials?.map((ele) => {
      MaterialsData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });
  }
  useEffect(async () => {
    let data = await userService.getAllUsers();
    data?.users?.map((item) => {
      if (item.username?.includes(values?.inspectedBy)) {
        setSignaturePath(item?.signature);
      }
    });
  }, [values]);
  return (
    <>
      <div className="asset-table corrective-table spacing-in table-align-left">
        <Table striped>
          <thead>
            <tr>
              <th style={{ width: 150, maxWidth: 120 }}>Defect Code</th>
              <th>Findings</th>
              <th>Remedial Actions</th>
              <th>Remarks</th>
            </tr>
          </thead>

          <tbody>
            {values &&
              values?.checkList?.map((item, index) =>
                // <tr key={index}>
                //   <td>{item?.findingsAndActions?.[0]?.defectCode}</td>
                //   <td>{item?.findingsAndActions?.[0]?.finding}</td>
                //   <td>{item?.findingsAndActions?.[0]?.remedialAction}</td>
                //   <td>Remarks here...</td>
                // </tr>
                item?.findingsAndActions?.map((list, i) => {
                  if (list.isSelected != undefined) {
                    if (list?.isSelected === true) {
                      return (
                        <tr key={i}>
                          <td>{list?.defectCode}</td>
                          <td>{list?.finding}</td>
                          <td>{list?.remedialAction}</td>
                          <td>{list?.remark}</td>
                        </tr>
                      );
                    }
                  }
                })
              )}
          </tbody>
        </Table>
      </div>
      <ReadOnlyInput
        label="Additional Comments"
        className="defect-comment"
        value={values?.additionalComments}
        readOnly
      />
      {(values.defectivePhoto1 ||
        values.defectivePhoto2 ||
        values.defectivePhoto3) && <hr className="tab-divider" />}
      {(values.defectivePhoto1 ||
        values.defectivePhoto2 ||
        values.defectivePhoto3) && (
          <h3 className="tab-inner-title">Defective Photos</h3>
        )}
      <div className="defective-photos">
        <Row>
          <Col xxl={4} lg={4} md={4}>
            {values?.defectivePhoto1 ? (
              <Image srcvale={values.defectivePhoto1} />
            ) : (
              ""
            )}
          </Col>
          <Col xxl={4} lg={4} md={4}>
            {values?.defectivePhoto2 ? (
              <Image srcvale={values.defectivePhoto2} />
            ) : (
              ""
            )}
          </Col>
          <Col xxl={4} lg={4} md={4}>
            {values?.defectivePhoto3 ? (
              <Image srcvale={values.defectivePhoto3} />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <hr className="tab-divider" />

      <Form>
        <Row>
          <Col xxl={12} lg={12}>
            <span className="form-group-label mt-0">
              Defect Analysis Summary
            </span>
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Faulty Items"
              value={values.faultyItems ? values.faultyItems : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Inspection Status"
              value={values.inspectionStatus ? values.inspectionStatus : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Defect Category"
              value={
                values.defectDefectCategory ? values.defectDefectCategory : ""
              }
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Overall Condition"
              value={
                values.defectOverallCondition
                  ? values.defectOverallCondition
                  : ""
              }
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Isolations"
              value={values.defectIsolation ? values.defectIsolation : ""}
            />
          </Col>

          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Other Requirements"
              value={
                values.defectOtherRequirements
                  ? values.defectOtherRequirements
                  : ""
              }
            />
          </Col>
        </Row>
        {materialsDatas != ""&& <Row>
          <Col xxl={12} lg={12}>
            <span className="form-group-label mt-0">Material Requirements</span>
          </Col>
        </Row>}
        {materialsDatas != "" &&  <Row>
          <Col xxl={3} lg={3}>
            {" "}
            <span className="form-label">Part Number</span>
          </Col>
          <Col xxl={3} lg={3}>
            {" "}
            <span className="form-label">Description</span>
          </Col>
          <Col xxl={3} lg={3}>
            {" "}
            <span className="form-label">Units</span>
          </Col>
          <Col xxl={3} lg={3}>
            {" "}
            <span className="form-label">Quantity</span>
          </Col>
        </Row>}

        {materialsDatas?.map((materials, i) => {
          return (
            <Row>
              <Col xxl={3} lg={3}>
                <ReadOnlyInput
                  type="text"
                  //  label="Material Requirements"
                  value={materials._id ? materials._id : ""}
                  onChange={handleMaterials}
                //  required
                />
              </Col>
              <Col xxl={3} lg={3}>
                <ReadOnlyInput
                  type="text"
                  //  label="Description"
                  value={materials.specification ? materials.specification : ""}
                  onChange={handleSpecification}
                //  required
                />
              </Col>
              <Col xxl={3} lg={3}>
                <ReadOnlyInput
                  onChange={handleUnits}
                  value={materials.unit ? materials.unit : ""}
                />
              </Col>
              <Col xxl={3} lg={3}>
                <ReadOnlyInput
                  value={materials.qty ? materials.qty : ""}
                  onChange={handleQuantity}
                />
              </Col>
            </Row>
          );
        })}
        <br />
        <Row>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Data Sheet"
              labelStyle="form-label"
              value={values.dataSheetOrgName ? values.dataSheetOrgName : ""}
            />
          </Col>
          <Col xxl={9} lg={8}>
            <ReadOnlyInput
              label="Remarks"
              value={values.remarks ? values.remarks : ""}
            />
          </Col>
        </Row>
        <span className="form-group-label">Inspection sign off</span>
        <Row>
          <Col xxl={3} lg={4}>
            {/* <FormInput
              type="text"
              label="Signature"
              name="Signature"
              placeholder="Enter here"
              value={values.Signature ? values.Signature : ""}
              onChange={handleSignature}
            /> */}
            <label htmlFor="signature" className="form-label">Signature</label>
            <div className="sig-border-new-wrap">
            {signaturePath ? (
              <img
                src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${signaturePath}`}
              />
            ) : (
              ""
            )}
            </div>
        </Col>
        <Col xxl={3} lg={4}>
          <ReadOnlyInput
            label="Inspected By"
            value={values.inspectedBy ? values.inspectedBy : ""}
          />
        </Col>
        <Col xxl={3} lg={4}>
          <ReadOnlyInput
            label="Inspected Date"
            value={
              values.inspectedDate && values.inspectedDate.includes("+")
                ? moment
                  .utc(values.inspectedDate)
                  .format("DD/MM/YYYY hh:mm A")
                : values.inspectedDate
                  ? moment(values.inspectedDate).format("DD/MM/YYYY hh:mm A")
                  : ""
            }
          />
        </Col>
      </Row>
    </Form >
    </>
  );
};

export default DefectAnalysis;
