import React, { useState } from 'react';
import { YearInput } from 'semantic-ui-calendar-react';

const STYLES = [
  "regular",
  "transparent"
];

const YearSelector = ({ id, name, label, onChange, variant, placeholder,value}) => {

  const setSelectStyle = STYLES.includes(variant) ? variant : STYLES[0];

  // const [year, setYear] = useState('');

  // const handleChange = (event, { name, value }) => {
  //   setYear(value);
  // };

  return (
    <div className={`form-group date-group ${setSelectStyle}`}>
      <label className='form-label' htmlFor={name}>{label}</label>
      <YearInput
        id={id}
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        iconPosition="right"
        popupPosition="bottom left"
        onChange={onChange}
        closable
      />
    </div>
  );
}

export default YearSelector;