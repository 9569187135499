import React, { useState, useEffect } from "react";
import { Form, ReadOnlyInput } from "../../components";
import { Row, Col } from "react-bootstrap";
import { images } from "../../constants";
import Table from "react-bootstrap/Table";
import assetService from "../../services/assetService";
import { MdOutlineClose, MdCheck } from "react-icons/md";
import { useParams } from "react-router-dom";
import * as moment from "moment-timezone";
import { useSelector } from "react-redux";
import { Spinner } from "../../components";
import userService from "../../services/userService";

const CorrectiveActions = (id) => {
  const { _id } = useParams();

  let assetId;

  if (_id != undefined) {
    assetId = _id;
  } else {
    assetId = id;
  }

  const lookup = useSelector((state) => state.lookupData.data);
  const subLookup = useSelector((state) => state.subLookupData);

  const [currentStatus, setCurrentStatus] = useState("");
  const [currentCondition, setCurrentCondition] = useState("");
  const [defectCategory, setDefectCategory] = useState("");
  const [repairedBy, setRepairedBy] = useState("");
  const [values, setValues] = useState([]);
  const [userName, setUsername] = useState("");
  const [signaturePath, setSignaturePath] = useState("");

  let DefectCategoryData = [];

  // if (lookup) {
  //   lookup?.DefectCategory?.map((ele) => {
  //     DefectCategoryData.push({
  //       key: ele._id,
  //       text: ele.value,
  //       value: ele.value,
  //     });
  //   });
  // }
  useEffect(async () => {
    let data = await userService.getAllUsers()
    data?.users?.map((item) => {
      if (item.username?.includes(values?.repairedBy)) {
        setSignaturePath(item?.signature);
      }
    }
    )
  }, [values])

  const handleSignature = (e) => {
    setValues({ ...values, Signature: e.target.value });
  };
  const Image = ({ srcvale }) => {
    const [loading, setLoading] = useState(true);

    return (
      <>
        {loading ? <Spinner /> : ""}
        <img
          src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${srcvale}`}
          className={"defect-img"}
          alt="defective photos"
          onClick={() => {
            window.open(`${process.env.REACT_APP_BACKEND_BASE_URL}/${srcvale}`);
          }}
          onLoad={() => setLoading(false)}
        />
      </>
    );
  };

  useEffect(() => {
    if (assetId !== undefined) {
      try {
        assetService.getAssetById(String(assetId)).then((assetData) => {
          setValues(assetData);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const handleComment = (e) => {
    setValues({ ...values, comments: e.target.value });
  };

  const handleExistingFaults = (e) => {
    setValues({ ...values, existingFaults: e.target.value });
  };

  const handleRepairedDate = (e) => {
    setValues({ ...values, repairedDate: e.target.value });
  };

  const handleIsolations = (e) => {
    setValues({ ...values, isolations: e.target.value });
  };

  const handleOtherRequirements = (e) => {
    setValues({ ...values, otherRequirements: e.target.value });
  };

  const handleCurrentStatus = (e) => {
    setCurrentStatus({ currentStatus });
  };

  const handleCurrentCondition = (e) => {
    setCurrentCondition({ currentCondition });
  };

  const handleRepairedby = (e) => {
    setRepairedBy({ repairedBy });
  };

  const handleDefectCategory = (e) => {
    setDefectCategory({ defectCategory });
  };

  const dummyData = [
    {
      value: "dataone",
      text: "Data 1",
    },
    {
      value: "datatwo",
      text: "Data 2",
    },
    {
      value: "datathree",
      text: "Data 3",
    },
  ];

  const dummyData2 = [
    {
      value: "Red",
      text: "Red ",
    },
    {
      value: "Yellow",
      text: "Yellow",
    },
    {
      value: "Green",
      text: "Green",
    },
  ];
  let DefectCategoryDatas = [
    {
      value: "1",
      text: "1 ",
    },
    {
      value: "2",
      text: "2",
    },
    {
      value: "3",
      text: "3",
    },
    {
      value: "4",
      text: "4 ",
    },
    {
      value: "5",
      text: "5",
    },
    {
      value: "Not Applicable",
      text: "Not Applicable",
    },
  ];
  let CurrentConditionData = [];
  let isolationsData = [];
  let otherRequirementsData = [];

  if (lookup) {
    // lookup?.DefectCategory?.map((ele) => {
    //   DefectCategoryDatas.push({
    //     key: ele._id,
    //     text: ele.value,
    //     value: ele.value,
    //   });
    // });

    lookup?.OverallCondition?.map((ele) => {
      CurrentConditionData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });

    lookup?.OtherRequirements?.map((ele) => {
      otherRequirementsData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });

    lookup?.Isolations?.map((ele) => {
      isolationsData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });
  }

  return (
    <>
      <div className="asset-table table-align-left spacing-in corrective-table">
        <Table striped>
          <thead>
            <tr>
              <th>Defect Code</th>
              <th>Findings</th>
              <th>Recommendations</th>
              <th>Done</th>
              <th>Repaired By</th>
              <th>Repaired Date</th>
            </tr>
          </thead>
          <tbody>
            {values &&
              values?.checkList?.map((item, index) =>
                item?.findingsAndActions?.map((list, i) => {
                  if (list.isSelected != undefined) {
                    if (list?.isSelected === true) {
                      return (
                        <tr key={i}>
                          <td>{list?.defectCode}</td>
                          <td>{list?.finding}</td>
                          <td>{list?.remedialAction}</td>
                          <td>
                            <span className="repairs-done corrective">
                              <p>{list?.defectCode}</p>
                              {list?.isDone === true ? (
                                <span className="success">
                                  <MdCheck />
                                </span>
                              ) : (
                                <span className="failed">
                                  <MdOutlineClose />
                                </span>
                              )}
                            </span>
                          </td>
                          <td>{list?.repairedBy}</td>
                          <td>
                            {list?.repairedAt?.length > 19
                              ? moment(list?.repairedAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )
                              : list?.repairedAt}
                          </td>
                        </tr>
                      );
                    }
                  }
                })
              )}
          </tbody>
        </Table>
      </div>
      <ReadOnlyInput
        label="Additional Comments"
        className="defect-comment mt-4"
        value=""
      />
      {(values.correctivePhoto1 ||
        values.correctivePhoto2 ||
        values.correctivePhoto3) && <hr className="tab-divider" />}
      {(values.correctivePhoto1 ||
        values.correctivePhoto2 ||
        values.correctivePhoto3) && (
          <h3 className="tab-inner-title">Current Photos</h3>
        )}
      <div className="defective-photos">
        <Row>
          <Col xxl={4} lg={4} md={4}>
            {values?.correctivePhoto1 ? (
              <Image srcvale={values.correctivePhoto1} />
            ) : (
              ""
            )}
          </Col>
          <Col xxl={4} lg={4} md={4}>
            {values?.correctivePhoto2 ? (
              <Image srcvale={values.correctivePhoto2} />
            ) : (
              ""
            )}
          </Col>
          <Col xxl={4} lg={4} md={4}>
            {values?.correctivePhoto3 ? (
              <Image srcvale={values.correctivePhoto3} />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <hr className="tab-divider" />

      <Form>
        <Row>
          <Col xxl={12} lg={12}>
            <span className="form-group-label mt-0">
              Corrective Actions Summary
            </span>
          </Col>
        </Row>
        <Row>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Existing Faults"
              onChange={handleExistingFaults}
              value={values?.existingFaults ? values.existingFaults : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Current Status"
              data={dummyData2}
              onChange={handleCurrentStatus}
              value={values.currentStatus ? values.currentStatus : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Defect Category"
              data={DefectCategoryDatas}
              onChange={handleDefectCategory}
              value={
                values.correctiveDefectCategory
                  ? values.correctiveDefectCategory
                  : ""
              }
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Current Condition"
              data={CurrentConditionData}
              onChange={handleCurrentCondition}
              value={
                values.correctiveOverallCondition
                  ? values.correctiveOverallCondition
                  : ""
              }
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Isolations"
              onChange={handleIsolations}
              value={
                values.correctiveisolation ? values.correctiveisolation : ""
              }
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Other Requirements"
              onChange={handleOtherRequirements}
              value={
                values.correctiveOtherRequirements
                  ? values.correctiveOtherRequirements
                  : ""
              }
            />
          </Col>
        </Row>
        <Col xxl={12} lg={12}>
          <span className="form-group-label">Repair sign off</span>
        </Col>
        <Row>
          <Col xxl={3} lg={4}>
            {/* <FormInput
              label="Signature"
              placeholder="Enter here"
              onChange={handleSignature}
              value={values.Signature ? values.Signature : ""}
            /> */}
            <label htmlFor="signature" className="form-label">Signature</label>
            <div className="sig-border-new-wrap">
              {signaturePath ? (
                <img
                  src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${signaturePath}`}
                />
              ) : (
                ""
              )}
            </div>
          </Col>

          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Repaired By"
              onChange={handleRepairedby}
              value={values.repairedBy ? values.repairedBy : ""}
            />
          </Col>

          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Repaired Date"
              onChange={handleRepairedDate}
              value={
                values.repairedDate && values.repairedDate.includes("+")
                  ? moment.utc(values.repairedDate).format("DD/MM/YYYY hh:mm A")
                  : values.repairedDate
                    ? moment(values.repairedDate).format("DD/MM/YYYY hh:mm A")
                    : ""
              }
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CorrectiveActions;
