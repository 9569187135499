import React, { useState } from 'react';

const TextArea = ({ value, placeholder, onChange, label, name, id, row, className, readOnly }) => {

    return (
        <div className={`form-group textarea-group ${className}`}>
            <label className='form-label' htmlFor={name}>{label}</label>
            <textarea 
                value={value}
                placeholder={placeholder}
                name={name}
                id={id}
                row={row}
                label={label}
                className={`form-textarea ${readOnly && 'disabled'}`}
                onChange={onChange}
            >
                {value}
            </textarea>
        </div>
    );
}

export default TextArea;