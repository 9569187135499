import React from 'react';

const STYLES = [
  "add-header",
  "remove-header",
  "chart-card"
];

const Card = ({ children, title, variant, className }) => {
  const setCardStyle = STYLES.includes(variant) ? variant : STYLES[0];

  return (
    <>
        <div className={`app__card ${setCardStyle} ${className}`}>
            <div className='app__card--header'>
                <h4 className='card-title'>{title}</h4>
            </div>
            <div className='app__card--body'>
                {children}
            </div>
        </div>
    </>
  )
}

export default Card;