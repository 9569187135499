import React from 'react'

const Form = ({ onSubmit, children, className, formRef }) => {

    return (
        <form
            className={`common-form ${className}`}
            onSubmit={onSubmit}
            ref={formRef}
        >
            {children}
        </form>
    );
}

export default Form;