export const userTypeData = [
    {
        value: 'normal-user',
        text: 'Normal User'
    },
    {
        value: 'admin-user',
        text: 'Admin User'
    },
];

export const userStatusData = [
    {
        value: 'active',
        text: 'Active'
    },
    {
        value: 'disable',
        text: 'Disable'
    },
];