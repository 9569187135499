import React, { useState } from "react";
import { ProgressBarUpload } from "../../components";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import assetService from "../../services/assetService";
import fileService from "../../services/fileService";
import { toast } from "react-toastify";
import FileUpload from "./fileUpload";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { AiOutlineCloudUpload } from "react-icons/ai";
import DefectFileUpload from "./DefectfileUpload";
import CorrectiveFileUpload from "./CorrectivefileUpload";
import { Card } from '../../components';
import { PageHeader } from "../../components";

function AssetUpload() {
  const [file, setFile] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState("");
  const [values, setValues] = useState([]);
  const [values1, setValues1] = useState();
  const [check, setCheck] = useState(false);
  const [check2, setCheck2] = useState(false);
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleAreaClass = async (e) => {
    let selFile = e.target.files[0];
    setFile(selFile);
    setCheck(true);
    setFilename(e.target.files[0].name);
    setValues([...values, e.target.files[0].name]);
    const formData = new FormData();
    formData.append("file", selFile);

    try {
      await fileService.fileUpload(formData, "Excelupload").then(
        (response) => {
          if (response?.data?.status) {
            setValues([...values, response?.data?.data?.uploadStatus?.file]);
            setValues1(response?.data?.data?.uploadStatus?.file);
          } else {
            toast.error(" This format is not supported");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  const SkipButton = () => {
    navigate("/asset-register/media-upload");
  }
  const AssetExcelJSonUpload = async () => {
    setCheck(false);
    setLoading(true);
    if (values[0].endsWith(".xlsx")) {
      let newData = {
        path: values[0],
      };
      let datas1 = await assetService.Excelupload(newData);

      if (datas1.data.data == "not valid") {

        setLoading(false);
        toast.error(
          "This format excel can'n be uploaded , please check with sample excel file"
        );
        downloadURIS();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }

      if (datas1.data.sucess == "sucess" || check2 == true) {


        if (datas1.data.log == true) {
          setTimeout(() => {
            downloadError();
          }, 8000);
        }

        setLoading(false);
        toast.success("Assets added successfully");
        navigate("/asset-register/media-upload");
      }
    } else if (values[0].endsWith(".xls")) {
      let newData = {
        path: values[0],
      };
      let datas1 = await assetService.AndroidExcelupload(newData);

      if (datas1.data.data == "not valid") {

        setLoading(false);
        toast.error(
          "This format excel can'n be uploaded , please check with sample excel file"
        );
        downloadURI();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      if (datas1.data.sucess == "sucess") {
        setLoading(false);
        toast.success("Assets added successfully");
        navigate("/asset-register/media-upload");
      }
    } else if (values[0].endsWith(".txt")) {
      let newData = {
        path: values[0],
      };
      let datas1 = await assetService.TxtUpload(newData);

      if (datas1.data.sucess == "sucess") {

        setLoading(false);
        toast.success("Assets added successfully");
        navigate("/asset-register/media-upload");
      }
    }
  };
  const downloadURI = () => {

    let name = "sample.xls";
    let uri = `${process.env.REACT_APP_BACKEND_BASE_URL}/media/locations/${name}`;
    const accessToken = localStorage.getItem("accessToken");
    const options = {
      headers: {
        Authorization: `Bearer ${JSON.parse(accessToken).value}`,
      },
    };
    fetch(uri, options)
      .then((res) => res.blob())
      .then((blob) => {
        const fileURL = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");

        downloadLink.href = fileURL;
        downloadLink.download = name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
  };
  const downloadURIS = () => {
    let name = "sample.xlsx";
    let uri = `${process.env.REACT_APP_BACKEND_BASE_URL}/media/locations/${name}`;
    const accessToken = localStorage.getItem("accessToken");
    const options = {
      headers: {
        Authorization: `Bearer ${JSON.parse(accessToken).value}`,
      },
    };
    fetch(uri, options)
      .then((res) => res.blob())
      .then((blob) => {
        const fileURL = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");

        downloadLink.href = fileURL;
        downloadLink.download = name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
  };
  const downloadError = () => {
    let name = "Error.txt";
    let uri = `${process.env.REACT_APP_BACKEND_BASE_URL}/media/workorders/${name}`;
    const accessToken = localStorage.getItem("accessToken");
    const options = {
      headers: {
        Authorization: `Bearer ${JSON.parse(accessToken).value}`,
      },
    };
    fetch(uri, options)
      .then((res) => res.blob())
      .then((blob) => {
        const fileURL = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");

        downloadLink.href = fileURL;
        downloadLink.download = name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
  };

  return (
    <div>
      {/* <PageHeader title="Drop an Excel/JSON file to upload" /> */}
      <Card variant="remove-header" className="locator-card">
        <div className="upload-skip-wrap">
          <span>If you already uploaded Asset Register/JSON file?</span>
          <Button
            variant="click-primary"
            size="click-xl"
            type="submit"
            onClick={SkipButton}
            className="click click-primary file_up_bttn file_skip_btnn"
          >
            Skip
          </Button>
        </div>
      </Card>
      <Card variant="remove-header" className="locator-card">
        <Row className="up-font-wrap">
          <Col xl={12} lg={12} md={12} sx={12}>
            <div class="app__header--wrapper">
              <h2 class="app__header--title file_upload_title">
                Drop an Asset Register/JSON file here to upload
              </h2>
            </div>
            <div className="frame drop_upload_wrap">
              <div className="file_upload_wrapper new_jason_up">
                <div className="upload-input">
                  <Button
                    component="label"
                    variant="contained"
                    startIcon={<AiOutlineCloudUpload />}
                  >
                    Select file
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) => {
                        handleAreaClass(event);
                      }}
                    />
                  </Button>
                </div>

                <div className="file_name_listing">
                  <p>Uploaded Files:</p>
                  <ul>
                   
                      <li >{filename}</li>
                   
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="up-buttn-mainwrap">
          {values.length > 0 && values1 && (
            <Button
              variant="click-primary"
              size="click-xl"
              type="submit"
              onClick={AssetExcelJSonUpload}
              className="click click-primary file_up_bttn"
            >
              Upload data
            </Button>
          )}
          {loading && <ProgressBarUpload setLoading={setLoading} setCheck2={setCheck2} />}
        </Row>
      </Card>
    </div>
  );
}

export default AssetUpload;
