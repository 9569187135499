import React, { useState, useEffect ,useContext} from "react";
import { Oval } from "react-loader-spinner";
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { MyContext } from "../../routes/Routes";

const ProgressBar = ({ count, setProgress, setLoaders }) => {
  const { myState, setMyState } = useContext(MyContext);
  const [ChangeMsg, setChangeMsg] = useState(false);
  const [ChangeMsg2, setChangeMsg2] = useState(false);
  const [ChangeMsg3, setChangeMsg3] = useState(false);
  const [isToggle, setIsToggle] = useState(true);

  const toggleLoader = () => setIsToggle(!isToggle);

  const closeLoader = () => {
    setMyState(false);
  };
  useEffect(() => {
    setTimeout(() => {
      
      setChangeMsg(true);
    }, 20000);
  });
  useEffect(() => {
    setTimeout(() => {
    
      setChangeMsg2(true);
    }, 60000);
  });
  useEffect(() => {
    setTimeout(() => {
    
      setChangeMsg3(true);
    }, 90000);
  });
  return (
    <div className="downloading">
      <div className={`downloading__progress ${isToggle && 'show'}`}>
        <Oval
          height={30}
          width={30}
          color="#EA2A2A"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#EA2A2A"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        {!ChangeMsg ? 
          <span className="downloading__text">Preparing to Download...</span>
        : !ChangeMsg2 ?
            <span className="downloading__text">
              Do not close this tab 
            </span>
          :!ChangeMsg3?  <span className="downloading__text" >almost there... </span>:
          <span className="downloading__text" >Thank you for your patience</span>
        }
      </div>
      <button className="downloading__toggle" onClick={toggleLoader}>
        {isToggle ? (
          <BiChevronRight size={24} />
        ) : (
          <BiChevronLeft size={24} />
        )}
      </button>
    </div>
  );
};

export default ProgressBar;
