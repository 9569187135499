import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import TokenService from "../../services/tokenService";

import { BiChevronsRight } from "react-icons/bi";
import { FiChevronDown } from "react-icons/fi";
import { motion } from "framer-motion";
import { Scrollbars } from "react-custom-scrollbars-2";
import { images } from "../../constants";
import { Button,Modal } from "../../components";
import { GiHamburgerMenu } from "react-icons/gi";
import authService from "../../services/authService";

const Sidebar = ({ toggleSidebar, className }) => {
  const [showModal, setShowModal] = useState(false);
  const [isDrop, setDrop] = useState(true);
  const [list, setList] = useState([]);

  const toggleSubmenu = () => setDrop(!isDrop);

  const dashAnimation = {
    hidden: {
      height: 0,
      opacity: 0,
      transition: {
        opacity: 0.5,
      },
    },
    show: {
      height: "504px",
      opacity: 1,
      transition: {
        opacity: 1,
      },
    },
  };

  const navigate = useNavigate();

  const logoutHandler = () => {
    authService.logout();
    navigate("/login");
    window.location.reload();
  };
  const getuser = async () => {
    let values = await TokenService.getUser();
    setList(values);
  };

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    getuser();
  }, []);
  return (
    <>
      <Button
        className={`mobile-toggle ${toggleSidebar && "open"}`}
        variant="click-none"
        size="click-resize"
        onClick={toggleSidebar}
      >
        <GiHamburgerMenu size={32} />
      </Button>
      <div className={`sidebar ${className}`}>
        <Button
          variant="click-none"
          size="click-resize"
          className="sidebar__toggle"
          onClick={toggleSidebar}
        >
          <BiChevronsRight size={25} />
        </Button>
        <Scrollbars style={{ width: "100%", height: "100%" }} autoHide>
          <nav className="sidebar__menu">
            <ul>
              <li>
                <button
                  className={`sidebar__link sidebar__subtoggle ${
                    isDrop && "open"
                  }`}
                  onClick={toggleSubmenu}
                >
                  <img src={images.dashboard} alt="navbar" />
                  <span>Dashboard</span>
                  <div className="toggle-icon">
                    <FiChevronDown size={20} />
                  </div>
                </button>
                <motion.ul
                  animate={{
                    height: isDrop ? 367 : 0,
                    opacity: isDrop ? 1 : 0,
                    pointerEvents: isDrop ? "all" : "none",
                  }}
                  transition={{ ease: "easeOut", duration: 0.2 }}
                  variants={dashAnimation}
                  className={`sidebar__submenu ${isDrop && "open"}`}
                >
                  <li>
                    <NavLink
                      to="/dashboard/inspection-overview"
                      className="sidebar__link"
                      activeclassname="activePage"
                    >
                      <img src={images.inspectionOverview} alt="submenu" />
                      <span>Inspection Overview</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/inspection-statistics"
                      className="sidebar__link"
                      activeclassname="activePage"
                    >
                      <img src={images.inspectionStatistics} alt="submenu" />
                      <span>Inspection Statistics</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/failure-trend-analysis"
                      className="sidebar__link"
                      activeclassname="activePage"
                    >
                      <img src={images.FailureTrendAnalysis} alt="submenu" />
                      <span>Failure Trend Analysis</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/asset-condition-analysis"
                      className="sidebar__link"
                      activeclassname="activePage"
                    >
                      <img src={images.AssetConditionAnalysis} alt="submenu" />
                      <span>Asset Condition Analysis</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/defect-coverage"
                      className="sidebar__link"
                      activeclassname="activePage"
                    >
                      <img src={images.DefectCoverage} alt="submenu" />
                      <span>Defect Coverage</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/repair-overview"
                      className="sidebar__link"
                      activeclassname="activePage"
                    >
                      <img src={images.RepairOverview} alt="submenu" />
                      <span>Repair Overview</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/activities-overdue"
                      className="sidebar__link"
                      activeclassname="activePage"
                    >
                      <img src={images.ActivitiesOverdue} alt="submenu" />
                      <span>Activities Overdue</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/project-plan-vs-actual"
                      className="sidebar__link"
                      activeclassname="activePage"
                    >
                      <img src={images.PlanActual} alt="submenu" />
                      <span>Project Plan vs Actual</span>
                    </NavLink>
                  </li>
                </motion.ul>
              </li>
              <li>
                <NavLink
                  to="/asset-register"
                  className="sidebar__link"
                  activeclassname="activePage"
                >
                  <img src={images.AssetRegister} alt="navbar" />
                  <span>Asset Register</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/asset-locator"
                  className="sidebar__link"
                  activeclassname="activePage"
                >
                  <img src={images.assetlocator} alt="navbar" />
                  <span>Asset Locator</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/work-order"
                  className="sidebar__link"
                  activeclassname="activePage"
                >
                  <img src={images.workorder} alt="navbar" />
                  <span>Work Order</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/inspections-repairs-planner"
                  className="sidebar__link"
                  activeclassname="activePage"
                >
                  <img src={images.projectPlan} alt="navbar" />
                  <span>Project Plan</span>
                </NavLink>
              </li>
              {list.userRole === "ADMIN" && (
                <NavLink
                  to="/user-management"
                  className="sidebar__link"
                  activeclassname="activePage"
                >
                  <img src={images.userManagement} alt="navbar" />
                  <span>User Management</span>
                </NavLink>
              )}
              <li>
                <Button
                  variant="click-none"
                  size="click-resize"
                  onClick={() => openModal()}
                  className={`sidebar__link sidebar__logout ${
                    isDrop && "place"
                  }`}
                >
                  <img src={images.logout} alt="navbar" />
                  <span>Logout</span>
                </Button>
                {showModal && (
                  <Modal
                    closeModal={closeModal}
                    title="Logout?"
                    text="Are you sure you want to logout?"
                  >
                    <Button
                      variant="click-gray"
                      size="click-lg"
                      onClick={closeModal}
                    >
                      No
                    </Button>
                    <Button
                      onClick={() => logoutHandler()}
                      variant="click-primary"
                      size="click-lg"
                    >
                      Yes
                    </Button>
                  </Modal>
                )}
              </li>
            </ul>
          </nav>
        </Scrollbars>
      </div>
    </>
  );
};

export default Sidebar;
