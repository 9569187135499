import React, { Component } from "react";
import Chart from "react-apexcharts";
import * as moment from "moment";
import { useEffect, useState } from "react";

function RepairsPlan({
  repairActual,
  repairPlan,
  navigate,
  values,
  filtervalue,
}) {
  const [xaxiscat, setXaxiscat] = useState([]);
  const [series, setSeries] = useState([
    {
      name: "Plan",
      type: "column",
      data: [],
    },
    {
      name: "Actual",
      type: "column",
      data: [],
    },
    {
      name: "Cumulative Plan",
      type: "line",
      data: [],
    },
    {
      name: "Cumulative Actual",
      type: "line",
      data: [],
    },
  ]);
  let options = {
    chart: {
      type: "line",
      stacked: false,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
        },
        export: {
          svg: {
            filename: "Repairs Plan vs Actual",
          },
          png: {
            filename: "Repairs Plan vs Actual",
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 1440,
        options: {
          chart: {
            height: 310,
            width: "100%",
          },
          dataLabels: {
            style: {
              fontSize: "12px",
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
        },
      },
      {
        breakpoint: 990,
        options: {
          chart: {
            width: "100%",
            height: 310,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          legend: {
            horizontalAlign: "left",
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
            height: 320,
          },
          dataLabels: {
            enabled: false,
            style: {
              fontSize: "12px",
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "400",
            },
          },
        },
      },
    ],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "14px",
        fontFamily: "Halvetica, sans-serif",
        fontWeight: "400",
        colors: ["#373D3F", "#373D3F", "#373D3F", "#373D3F"],
      },
      background: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "90%",
        dataLabels: {
          position: "center",
        },
      },
      line: {
        dataLabels: {
          position: "top",
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#373D3F"],
        },
        offsetX: -20,
      },
    },
    stroke: {
      width: [3, 3, 3, 3],
      colors: ["transparent", "transparent", "#0678EB", "#00B050"],
    },
    xaxis: {
      categories: xaxiscat,
      labels: {
        style: {
          colors: "#4F4F4F",
          fontSize: "14px",
          fontFamily: "Halvetica, sans-serif",
          fontWeight: "400",
        },
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#4F4F4F",
            fontSize: "14px",
            fontFamily: "Halvetica, sans-serif",
            fontWeight: "400",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
    legend: {
      show: false,
    },
    markers: {
      size: 5,
    },
    fill: {
      opacity: 1,
    },
    colors: ["#0678EB", "#00B050", "#0678EB", "#00B050"],
  };
  let total = 0;
  const getTotal = (item) => {
    return (total = total + item);
  };

  let cumulativeTotal = 0;
  const getCumulativeTotal = (item) => {
    return (cumulativeTotal = cumulativeTotal + item);
  };
  
  useEffect(() => {
    setSeries([
      {
        name: "Plan",
        type: "column",
        data: repairPlan.map((item, i) => {
          return item?.count ? item?.count : 0;
        }),
      },
      {
        name: "Actual",
        type: "column",
        data: repairActual.map((item, i) => {
          return item?.count ? item?.count : 0;
        }),
      },
      {
        name: "Cumulative Plan",
        type: "line",
        data: repairPlan.map((item, i) => {
          return getTotal(item.count);
        }),
      },
      {
        name: "Cumulative Actual",
        type: "line",
        data: repairActual.map((item, i) => {
          return getCumulativeTotal(item.count);
        }),
      },
    ]);

    setXaxiscat(
      repairActual?.map((item, i) => {
        if (filtervalue.type === "monthly") {
          return item?.date ? moment(item?.date).format("MMM-YYYY") : [];
        } else {
          return item?.date ? moment(item?.date).format("YYYY") : [];
        }
      })
    );
  }, [repairActual, repairPlan]);

  return (
    <>
      <Chart options={options} series={series} width="100%" height="420" />
      <div className="columnLegends">
        <div
          className="columnLegends__item"
          style={{ pointerEvents: 'none' }}
          // onClick={() =>
          //   navigate("/asset-register", {
          //     state: {
          //       valid: "true",
          //       repairedDate: "true",
          //       startDate: values.startDate,
          //       endDate: values.endDate,
          //     },
          //   })
          // }
        >
          <span
            className="columnLegends__color"
            style={{ backgroundColor: "#0678EB" }}
          ></span>
          <span className="columnLegends__label">Plan</span>
        </div>
        <div
          className="columnLegends__item"
          onClick={() =>
            navigate("/asset-register", {
              state: {
                valid: "true",
                repairedDate: "true",
                startDate: values.startDate,
                endDate: values.endDate,
              },
            })
          }
        >
          <span
            className="columnLegends__color"
            style={{ backgroundColor: "#00B050" }}
          ></span>
          <span className="columnLegends__label">Actual</span>
        </div>
        <div className="columnLegends__item disabled">
          <span
            className="columnLegends__color"
            style={{ backgroundColor: "#0678EB" }}
          ></span>
          <span className="columnLegends__label">Cumulative Plan</span>
        </div>
        <div className="columnLegends__item disabled">
          <span
            className="columnLegends__color"
            style={{ backgroundColor: "#00B050" }}
          ></span>
          <span className="columnLegends__label">Cumulative Actual</span>
        </div>
      </div>
    </>
  );
}

export default RepairsPlan;
