import React from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { Button } from '../../components';

const Search = ({ id, name, className, value, placeholder,onChange }) => {
  return (
    <>
        <div className={`search-group ${className}`}>
            <input
                id={id}
                name={name}
                value={value}
                placeholder={placeholder}
                type='search'
                onChange={onChange}
                className={`search-input ${className}`}
            />
            <Button variant='click-none' size='click-resize' className='search-btn'>
                <RiSearchLine size={20} />
            </Button>
        </div>
    </>
  )
}

export default Search;