import React from 'react';

const PageFooter = ({ children, className }) => {
  return (
    <>
        <div className={`app__footer ${className}`}>
            {children}
        </div>
    </>
  )
}

export default PageFooter;