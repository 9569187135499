import React, { useState } from 'react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

const FormPassword = ({ id, name, label, placeholder, autoComplete, className, onChange, value, errormsg }) => {
    const [isShown, setIsShown] = useState(false);

    const togglePassword = () => {
        setIsShown((isShown) => !isShown);
    };

    return (
        <>
            <div className='form-group'>
                <label htmlFor={name} className='form-label'>{label}</label>
                <div className='password-group'>
                    <input 
                        type={isShown ? "text" : "password"}
                        id={id}
                        name={name}
                        value={value}
                        label={label}
                        placeholder={placeholder}
                        autoComplete={autoComplete}
                        className={`form-password ${className}`}
                        onChange={onChange}
                        errormsg={errormsg}
                    />
                    <span onClick={togglePassword} className={`show-pass ${isShown && 'hide'}`}>
                        <BsEyeSlash size={20} className='show-icon' />
                        <BsEye size={20} className='hide-icon' />
                    </span>
                </div>
                <span className='form-error'>{errormsg}</span>
            </div>
        </>
    );
}

export default FormPassword