import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Row, Col } from "react-bootstrap";
import { FiChevronRight } from "react-icons/fi";

function RepairAnalysis({
  values,
  navigate,
  redToYellow,
  total1,
  yellowToGreen,
  redToGreen,
}) {
  const [xaxiscat, setXaxiscat] = useState([total1]);

  let options = {
    chart: {
      type: "radialBar",
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: {
            enabled: true,
          },
        },
        export: {
          svg: {
            filename: "Repair Overview Analysis",
          },
          png: {
            filename: "Reapir Overview Analysis",
          },
        },
        autoSelected: "zoom",
      },
    },
    responsive: [
      {
        breakpoint: 1600,
        options: {
          chart: {
            height: 300,
            width: 300,
          },
          dataLabels: {
            style: {
              fontSize: "12px",
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "400",
            },
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: "100%",
            height: 300,
          },
          dataLabels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      },
      {
        breakpoint: 990,
        options: {
          chart: {
            width: "100%",
            height: 310,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          legend: {
            horizontalAlign: "left",
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
            height: 320,
          },
          dataLabels: {
            style: {
              fontSize: "12px",
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "400",
              color: ["#000000"],
            },
          },
        },
      },
    ],
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "14px",
          },
          value: {
            fontSize: "14px",
            formatter: function (value) {
              return value + "%";
            },
          },
          // total: {
          //   show: true,
          //   label: "Total",
          //   formatter: function () {
          //     return xaxiscat;
          //   },
          // },
        },
        track: {
          background: ["#00B050", "#00B050", "#E1E103"],
          opacity: 1,
        },
      },
    },
    labels: ["Red - Green", "Yellow - Green", "Red - Yellow"],
    colors: ["#FF0000", "#E1E103", "#FF0000"],
    fill: {
      opacity: 1,
    },
  };
  useEffect(() => {
                 
  }, [total1]);

  return (
    <>
      <div className="chart-flex">
        <div className="flex-column">
          {total1 && (
            <Chart
              options={options}
              series={[
                redToGreen ? ((redToGreen / total1) * 100).toFixed(0) : 0,
                yellowToGreen ? ((yellowToGreen / total1) * 100).toFixed(0) : 0,
                redToYellow ? ((redToYellow / total1) * 100).toFixed(0) : 0,
              ]}
              type="radialBar"
              height="380px"
              width="380px"
            />
          )}
        </div>
        <div className="flex-column">
          <div className="legends radialbars">
            <div className="legends__item disabled">
              <span className="legends__color total"></span>
              <div>
                <p className="legends__title">Total Repairs Done</p>
                {total1 ? (
                  <p className="legends__count">{total1}</p>
                ) : (
                  <p className="legends__count">0</p>
                )}
              </div>
            </div>
            <div
              className="legends__item"
              onClick={() =>
                navigate("/asset-register", {
                  state: {
                    valid: "true",
                    repairAnalysis: "redToGreen",
                    startDate: values.startDate,
                    endDate: values.endDate,
                  },
                })
              }
            >
              <span className="legends__color red"></span>
              <div>
                <p className="legends__title">
                  Red <FiChevronRight /> Green
                </p>
                {redToGreen ? (
                  <p className="legends__count">{redToGreen}</p>
                ) : (
                  <p className="legends__count">0</p>
                )}
              </div>
            </div>
            <div
              className="legends__item"
              onClick={() =>
                navigate("/asset-register", {
                  state: {
                    valid: "true",
                    repairAnalysis: "yellowToGreen",
                    startDate: values.startDate,
                    endDate: values.endDate,
                  },
                })
              }
            >
              <span className="legends__color yellow"></span>
              <div>
                <p className="legends__title">
                  Yellow <FiChevronRight /> Green
                </p>
                {yellowToGreen ? (
                  <p className="legends__count">{yellowToGreen}</p>
                ) : (
                  <p className="legends__count">0</p>
                )}
              </div>
            </div>
            <div
              className="legends__item"
              onClick={() =>
                navigate("/asset-register", {
                  state: {
                    valid: "true",
                    repairAnalysis: "redToYellow",
                    startDate: values.startDate,
                    endDate: values.endDate,
                  },
                })
              }
            >
              <span className="legends__color green"></span>
              <div>
                <p className="legends__title">
                  Red <FiChevronRight /> Yellow
                </p>
                {redToYellow ? (
                  <p className="legends__count">{redToYellow}</p>
                ) : (
                  <p className="legends__count">0</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RepairAnalysis;
