import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  FormInput,
  FormSelect,
  Button,
  Modal,
  ReadOnlyInput,
} from "../../../components";
import { Row, Col } from "react-bootstrap";
import equipmentService from "../../../services/equipmentService";
import assetService from "../../../services/assetService";
import validator from "validator";
import inspectionService from "../../../services/inspectionService";
import lookupService from "../../../services/lookupService";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const EquipmentTag = ({
  isSubmit,
  setIsSubmit,
  locationId,
  locationData,
  setLocationId,
  assetId,
  setEquipmentData,
  equipmentData,
  isClear,
  setIsClear,
  next,
  setNext,
  ...props
}) => {
  const [values, setValues] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [requiredMsg, setRequired] = useState("");
  const [gpscord, setgpscord] = useState("");
  const [equpcord, setEqucord] = useState("");
  const [inspectionRef, setInspectionReference] = useState("");

  const [asset, setAssetData] = useState([]);
  const dispatch = useDispatch();
  const lookup = useSelector((state) => state.lookupData.data);
  const subLookup = useSelector((state) => state.subLookupData);
  const navigate = useNavigate();

  const equipmentFormRef = useRef(null);
  useEffect(() => {
    if (assetId !== undefined) {
      try {
        assetService.getAssetById(String(assetId)).then((assetData) => {
          setAssetData(assetData);
          setValues(assetData);
          setInspectionReference(assetData?.inspectionReferenceNumber);

          let locationLatitude = assetData?.locationLatitude ? assetData?.locationLatitude :"";
          let locationLongitude = assetData?.locationLongitude ? assetData?.locationLongitude :"";
          let data = "";
          if(locationLatitude && locationLongitude === null){
            data = ""
          }
          else if(locationLatitude && !locationLongitude){
              data = locationLatitude
          }
          else if(!locationLatitude && locationLatitude){
            data = locationLongitude

          }
          else{
            data = locationLatitude + "," + locationLongitude;
          }
          setgpscord(data);

          let dataeq = "";
          let eqpmtLatitude = assetData?.eqpmtLatitude ? assetData?.eqpmtLatitude :"";
          let eqpmtLongitude = assetData?.eqpmtLongitude ? assetData?.eqpmtLongitude :"";

          if(eqpmtLatitude && eqpmtLongitude === null){
            dataeq = ""
          }
          else if(eqpmtLatitude && !eqpmtLongitude){
            dataeq = eqpmtLatitude
          }
          else if(!eqpmtLatitude && eqpmtLongitude){
            dataeq = eqpmtLongitude
          }
          else{
            dataeq = eqpmtLatitude + "," + eqpmtLongitude;
          }
          setEqucord(dataeq);
        });
      } catch (error) {
        console.log(error);
      }
    }

    // inspectionService
    //   .getInspectionReferenceNum(locationId)
    //   .then((inspRefNum) => {
    //     setInspectionReference(inspRefNum.data?.inspectionReference);
    //     // setValues({inspectionReference: inspRefNum.data?.inspectionReference})
    //   });
  }, []);

  useEffect(() => {
    setValues(equipmentData);
    if (locationId) {
      setValues({ ...equipmentData, location: locationId });
    }
  }, [equipmentData]);

  useEffect(() => {
    if (isSubmit) {
      handleSubmit();
      setIsSubmit(false);
      setNext(true);
    }
  }, [isSubmit]);

  useEffect(() => {
    if (isClear) {
      handleClear();
      setIsClear(false);
    }
  }, [isClear]);

  const handleClear = () => {
    setValues("");
    setEquipmentData("");
  };

  let equipmentCategoryData = [];
  let atexCategoryData = [];
  let eplData = [];
  let protectionStandardData = [];
  let ipRatingData = [];
  let gasGroupDataIEC = [];
  let tClassData = [];
  let gasGroupData = [];

  let tClassDataIEC = [];
  let tClassDataNEC = [];
  let gasGroupDataNEC = [];
  // const [gasGroupData, setGasGroupData] = useState([]);
  // const [tClassData, setTClassData] = useState([]);

  if (lookup) {
    lookup?.EquipmentCategory?.map((ele) => {
      equipmentCategoryData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });
    lookup?.ATEXCategory?.map((ele) => {
      atexCategoryData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });
    lookup?.EPL?.map((ele) => {
      eplData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });
    lookup.ProtectionStandard?.map((ele) => {
      protectionStandardData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });
    lookup.equipIpRating?.map((ele) => {
      ipRatingData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });
  }

  // Sublookup datata from store

  if (subLookup) {
    if (values.protectionStd == "NEC") {
      subLookup.equipmentTClass?.NEC?.map((ele) => {
        tClassData.push({
          key: ele._id,
          text: ele.fieldValue,
          value: ele.fieldValue,
        });
      });

      subLookup.equipmentGasGroup?.NEC.map((ele) => {
        gasGroupData.push({
          key: ele._id,
          text: ele.fieldValue,
          value: ele.fieldValue,
        });
      });
    }

    if (values.protectionStd == "IEC") {
      subLookup.equipmentTClass?.IEC?.map((ele) => {
        tClassData.push({
          key: ele._id,
          text: ele.fieldValue,
          value: ele.fieldValue,
        });
      });
      subLookup.equipmentGasGroup?.IEC.map((ele) => {
        gasGroupData.push({
          key: ele._id,
          text: ele.fieldValue,
          value: ele.fieldValue,
        });
      });
    }

    if (values.protectionStd == "Not Available") {
      subLookup.equipmentTClass?.NotAvailable?.map((ele) => {
        tClassData.push({
          key: ele._id,
          text: ele.fieldValue,
          value: ele.fieldValue,
        });
      });
      subLookup.equipmentGasGroup?.NotAvailable.map((ele) => {
        gasGroupData.push({
          key: ele._id,
          text: ele.fieldValue,
          value: ele.fieldValue,
        });
      });
    }

    //  subLookup.equipTClass?.IEC?.map((ele) => {
    //   tClassData.push({
    //     key: ele._id,
    //     text: ele.fieldValue,
    //     value: ele.fieldValue
    //   })
    // });
    // subLookup.equipGasGroup?.IEC.map((ele) => {
    //   gasGroupData.push({
    //     key: ele._id,
    //     text: ele.fieldValue,
    //     value: ele.fieldValue
    //   })
    // });

    // subLookup.areaGasGroup?.IEC.map((ele) => {
    //   gasGroupData.push({
    //     key: ele._id,
    //     text: ele.fieldValue,
    //     value: ele.fieldValue
    //   })
    // });

    // subLookup?.equipTClass?.NEC.map((ele) => {
    //   tClassDataNEC.push({
    //     key: ele._id,
    //     text: ele.fieldValue,
    //     value: ele.fieldValue
    //   })
    // });
    // subLookup?.equipGasGroup?.NEC.map((ele) => {
    //   gasGroupDataNEC.push({
    //     key: ele._id,
    //     text: ele.fieldValue,
    //     value: ele.fieldValue
    //   })
    // });
  }

  const handleEquipmentCategory = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    //setEquipmentCategory(value);
    //console.log("event.target.textContent", event.target.textContent)
    setValues({ ...values, eqpmtCatg: value });
  };

  const handleAtexCategory = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    //setAtexCategory(value);
    setValues({ ...values, atexCatg: value });
  };

  const handleipRating = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    //setIpRating(value);
    setValues({ ...values, equipmentIpRating: value });
  };

  const handleEpl = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    //setEpl(value);
    setValues({ ...values, epl: value });
  };

  const handleProtectionStandard = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    //setProtectionStandard(value);
    setValues({ ...values, protectionStd: value });
    // if(value === 'NEC' ){
    //   setGasGroupData(gasGroupDataNEC);
    //   setTClassData(tClassDataNEC);

    // }else {
    //   setGasGroupData(gasGroupDataIEC);
    //   setTClassData(tClassDataIEC);
    // }
  };

  const handleGasGroup = (e) => {
    //setGasGroup(value);
    setValues({ ...values, equipmentGasGroup: e.target.value });
  };

  const handleTClass = (event, { value }) => {
    //setTClass(value);
    setValues({ ...values, equipmentTClass: value });
  };

  const handleRfid = (e) => {
    setValues({ ...values, rfidRef: e.target.value });
  };

  const handleInspection = (e) => {
    setValues({ ...values, inspectionReferenceNumber: e.target.value });
  };

  const handleEquipmentTag = (e) => {
    setValues({ ...values, eqpmtTag: e.target.value });
  };

  const handlecircuitId = (e) => {
    setValues({ ...values, circuitId: e.target.value });
  };

  const handleDescription = (e) => {
    setValues({ ...values, description: e.target.value });
     if (requiredMsg) {
       setRequired("");
     }
  };

  const handleTambient = (e) => {
    setValues({ ...values, tAmbient: e.target.value });
  };

  const handleManufacturer = (e) => {
    setValues({ ...values, manufacturer: e.target.value });
  };

  const handleCertification = (e) => {
    setValues({ ...values, certfnBody: e.target.value });
  };

  const handleTypemodel = (e) => {
    setValues({ ...values, type: e.target.value });
  };

  const handleProtectionType = (e) => {
    setValues({ ...values, protectionType: e.target.value });
  };

  const handleCertificationNum = (e) => {
    setValues({ ...values, certfnNo: e.target.value });
  };
   const handleOracleId = (e) => {
     setValues({ ...values, oracleId: e.target.value });
   };

  const handleSerialNum = (e) => {
    setValues({ ...values, serialNumber: e.target.value });
  };

  const handleSpecialConditions = (e) => {
    setValues({ ...values, specialCond: e.target.value });
  };

  const handleGps = (e) => {
    setValues({ ...values, gpsCord: e.target.value });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    let newData = {
      ...locationData,
      locationId: locationId,
      rfidRef: values?.rfidRef ? values?.rfidRef : "",
      gpsCord: values?.gpsCord ? values?.gpsCord : "",
      eqpmtCatg: values?.eqpmtCatg ? values?.eqpmtCatg : "",
      eqpmtTag: values?.eqpmtTag ? values?.eqpmtTag : "",
      circuitId: values?.circuitId ? values?.circuitId : "",
      description: values?.description ? values?.description : "",
      manufacturer: values?.manufacturer ? values?.manufacturer : "",
      type: values?.type ? values?.type : "",
      serialNumber: values?.serialNumber ? values?.serialNumber : "",
      atexCatg: values?.atexCatg ? values?.atexCatg : "",
      epl: values?.epl ? values?.epl : "",
      protectionStd: values?.protectionStd ? values?.protectionStd : "",
      protectionType: values?.protectionType ? values?.protectionType : "",
      equipmentGasGroup: values?.equipmentGasGroup
        ? values?.equipmentGasGroup
        : "",
      equipmentTClass: values?.equipmentTClass ? values?.equipmentTClass : "",
      equipmentIpRating: values?.equipmentIpRating
        ? values?.equipmentIpRating
        : "",
      tAmbient: values?.tAmbient ? values?.tAmbient : "",
      certfnBody: values?.certfnBody ? values?.certfnBody : "",
      certfnNo: values?.certfnNo ? values?.certfnNo : "",
      specialCond: values?.specialCond ? values?.specialCond : "",
      oracleId: values?.oracleId ? values?.oracleId : "",
    };

    setEquipmentData(values);
    let equipmentData = {};
    [...equipmentFormRef.current.elements].map((ele) => {
      equipmentData[ele.name] = ele.value;
    });

  
    if (validator.isEmpty(equipmentData.description)) {
      setRequired("Required*");
      

    } 
    else {
      
      setRequired("");
      if (assetId) {
        try {
          await equipmentService.addEquipmentTagById(assetId, newData).then(
            (response) => {
              toast.success(response.data.msg);
             
            },
            (error) => {
              console.log(error);
            }
          );
        } catch (err) {
          setShowModal(true);
          setStatusMsg("Failed");
        }
      } else {
        try {
          await equipmentService.addEquipmentTag(newData).then(
            (response) => {
              toast.success("Asset added successfully");
              navigate("/asset-register");
            },
            (error) => {
              console.log(error);
            }
          );
        } catch (err) {
          setShowModal(true);
          setStatusMsg("Failed");
        }
      }
    }
  };

  return (
    <>
      <Form onSubmit={(e) => handleSubmit(e)} formRef={equipmentFormRef}>
        <Row>
          <Col xxl={3} lg={4}>
            <FormInput
              label="RFID Reference"
              name="rfidRef"
              placeholder="Enter RFID Number"
              value={values.rfidRef ? values.rfidRef : ""}
              onChange={handleRfid}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <ReadOnlyInput
              label="Inspection Reference"
              placeholder="Auto generated"
              name="inspectionReferenceNumber"
              value={inspectionRef ? inspectionRef : ""}
              onChange={handleInspection}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormInput
              label="GPS Coordinates"
              placeholder="Enter here"
              name="gpsCord"
              value={equpcord ? equpcord : ""}
              onChange={handleGps}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              label="Equipment Category"
              name="eqpmtCatg"
              placeholder="Select"
              data={equipmentCategoryData}
              onChange={handleEquipmentCategory}
              value={values.eqpmtCatg ? values.eqpmtCatg : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormInput
              label="Equipment Tag"
              name="eqpmtTag"
              placeholder="Enter here"
              value={values.eqpmtTag ? values.eqpmtTag : ""}
              onChange={handleEquipmentTag}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormInput
              label="Circuit ID"
              name="circuitId"
              placeholder="Enter Circuit ID"
              value={values.circuitId ? values.circuitId : ""}
              onChange={handlecircuitId}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormInput
              label="Description"
              name="description"
              placeholder="Enter here"
              value={values.description ? values.description : ""}
              onChange={handleDescription}
              required
              errormsg={requiredMsg ? requiredMsg : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormInput
              label="Manufacturer"
              name="manufacturer"
              placeholder="Enter here"
              value={values.manufacturer ? values.manufacturer : ""}
              onChange={handleManufacturer}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormInput
              label="Type/Model"
              name="type"
              placeholder="Enter Type/Model"
              value={values.type ? values.type : ""}
              onChange={handleTypemodel}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormInput
              label="Serial Number"
              name="serialNumber"
              placeholder="Enter here"
              value={values.serialNumber ? values.serialNumber : ""}
              onChange={handleSerialNum}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              label="ATEX Category"
              name="atexCatg"
              placeholder="Select"
              data={atexCategoryData}
              onChange={handleAtexCategory}
              value={values.atexCatg ? values.atexCatg : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              label="EPL"
              name="epl"
              placeholder="Select"
              data={eplData}
              onChange={handleEpl}
              value={values.epl ? values.epl : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              label="Protection Standard"
              name="protectionStd"
              placeholder="Select"
              data={protectionStandardData}
              onChange={handleProtectionStandard}
              value={values.protectionStd ? values.protectionStd : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormInput
              label="Protection Type"
              name="protectionType"
              placeholder="Enter here"
              value={values.protectionType ? values.protectionType : ""}
              onChange={handleProtectionType}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormInput
              label="Gas Group"
              name="equipGasGroup"
              placeholder="Enter here"
              value={values.equipmentGasGroup ? values.equipmentGasGroup : ""}
              onChange={handleGasGroup}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              label="T-Class"
              name="equipTClass"
              placeholder="Select"
              data={tClassData}
              onChange={handleTClass}
              selection
              value={values.equipmentTClass ? values.equipmentTClass : ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              label="IP Rating"
              name="equipIpRating"
              placeholder="Select IP Rating"
              data={ipRatingData}
              value={values.equipmentIpRating ? values.equipmentIpRating : ""}
              onChange={handleipRating}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormInput
              label="T-Ambient (°C)"
              name="tAmbient"
              placeholder="Enter here"
              value={values.tAmbient ? values.tAmbient : ""}
              onChange={handleTambient}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormInput
              label="Certification Body"
              name="certfnBody"
              placeholder="Enter here"
              value={values.certfnBody ? values.certfnBody : ""}
              onChange={handleCertification}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormInput
              label="Certification Number"
              name="certfnNo"
              placeholder="Enter here"
              value={values.certfnNo ? values.certfnNo : ""}
              onChange={handleCertificationNum}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormInput
              label="Oracle ID"
              placeholder="Enter here"
              value={values.oracleId ? values.oracleId : ""}
              onChange={handleOracleId}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12}>
            <FormInput
              label="Special Conditions"
              name="specialCond"
              placeholder="Enter here"
              value={values.specialCond ? values.specialCond : ""}
              onChange={handleSpecialConditions}
            />
          </Col>
        </Row>
      </Form>
      {showModal && (
        <Modal closeModal={closeModal} title={`${statusMsg}`}>
          <Button variant="click-gray" size="click-lg" onClick={closeModal}>
            Close
          </Button>
        </Modal>
      )}
    </>
  );
};

export default EquipmentTag;
