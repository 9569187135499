import React, { Component } from "react";
import Chart from "react-apexcharts";
import * as moment from "moment";
import { useEffect, useState } from "react";

function  InspectionsPlan({
  inspectionPlan,
  inspectionActual,
  values,
  navigate,
  filtervalue,
}) {
  const [xaxiscat, setXaxiscat] = useState([]);
  const [series, setSeries] = useState([
    {
      name: "Plan",
      type: "column",
      data: [],
    },
    {
      name: "Actual",
      type: "column",
      data: [],
    },
    {
      name: "Cumulative Plan",
      type: "line",
      data: [],
    },
    {
      name: "Cumulative Actual",
      type: "line",
      data: [],
    },
  ]);
  let options = {
    chart: {
      type: "line",
      stacked: false,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
        },
        export: {
          svg: {
            filename: "Inspection Plan vs Actual",
          },
          png: {
            filename: "Inspection Plan vs Actual",
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 1440,
        options: {
          chart: {
            height: 310,
            width: "100%",
          },
          dataLabels: {
            style: {
              fontSize: "12px",
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
        },
      },
      {
        breakpoint: 990,
        options: {
          chart: {
            width: "100%",
            height: 310,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          legend: {
            horizontalAlign: "left",
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
            height: 320,
          },
          dataLabels: {
            enabled: false,
            style: {
              fontSize: "12px",
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "400",
            },
          },
        },
      },
    ],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "14px",
        fontFamily: "Halvetica, sans-serif",
        fontWeight: "400",
        colors: ["#FFFFFF", "#FFFFFF", "#373D3F", "#373D3F"],
      },
      background: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "90%",
        dataLabels: {
          position: "center",
        },
      },
      line: {
        dataLabels: {
          position: "top",
          offesetY: -30,
        },
      },
    },
    dataLabels: {
      enabled: true,
      position: 'top',
      minHeight: 10,
      style: {
        fontSize: "10px",
        fontFamily: "Halvetica, sans-serif",
        fontWeight: "400",
        colors: ["#373D3F", "#373D3F", "#373D3F", "#373D3F"],
      },
      dropShadow: {
        enabled: true,
        top: 0,
        left: 0,
        blur: 1,
        opacity: 0.5,
      },
      background: {
        enabled: false,
      },
      offsetY: -2,
    },
    stroke: {
      width: [3, 3, 3, 3],
      colors: ["transparent", "transparent", "#0678EB", "#00B050"],
    },
    xaxis: {
      categories: xaxiscat,
      labels: {
        style: {
          colors: "#4F4F4F",
          fontSize: "14px",
          fontFamily: "Halvetica, sans-serif",
          fontWeight: "400",
        },
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#4F4F4F",
            fontSize: "14px",
            fontFamily: "Halvetica, sans-serif",
            fontWeight: "400",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
    legend: {
      show: false,
    },
    markers: {
      size: 5,
    },
    fill: {
      opacity: 1,
    },
    colors: ["#0678EB", "#00B050", "#0678EB", "#00B050"],
  };

  let total = 0;
  const getTotal = (item) => {
    return (total = total + item);
  };

  let cumulativeTotal = 0;
  const getCumulativeTotal = (item) => {
    return (cumulativeTotal = cumulativeTotal + item);
  };

  useEffect(() => {
    setSeries([
      {
        name: "Plan",
        type: "column",
        data: inspectionPlan?.map((item, i) => {
          return item?.count ? item?.count : 0;
        }),
      },
      {
        name: "Actual",
        type: "column",
        data: inspectionActual?.map((item, i) => {
          return item?.count ? item?.count : 0;
        }),
      },
      {
        name: "Cumulative Plan",
        type: "line",
        data: inspectionPlan?.map((item, i) => {
          return getTotal(item.count);
        }),
      },
      {
        name: "Cumulative Actual",
        type: "line",
        data: inspectionActual?.map((item, i) => {
          return  getCumulativeTotal(item.count);
        }),
      },
    ]);
    setXaxiscat(
      inspectionActual?.map((item, i) => {
        if (filtervalue.type === "monthly") {
          return item?.date ? moment(item?.date).format("MMM-YYYY") : [];
        } else {
          return item?.date ? moment(item?.date).format("YYYY") : [];
        }
      })
    );
  }, [inspectionActual, inspectionPlan]);

  return (
    <div>
      <>
        <Chart options={options} series={series} width="100%" height="420px" />
        <div className="columnLegends">
          <div
            className="columnLegends__item"
            style={{ pointerEvents: 'none' }}
            // onClick={() =>
            //   navigate("/asset-register", {
            //     state: {
            //       valid: "true",
            //       inspectedDate: "true",
            //       startDate: values.startDate,
            //       endDate: values.endDate,
            //     },
            //   })
            // }
          >
            <span
              className="columnLegends__color"
              style={{ backgroundColor: "#0678EB" }}
            ></span>
            <span className="columnLegends__label">Plan</span>
          </div>
          <div
            className="columnLegends__item"
            onClick={() =>
              navigate("/asset-register", {
                state: {
                  valid: "true",
                  inspectedDate: "true",
                  startDate: values.startDate,
                  endDate: values.endDate,
                },
              })
            }
          >
            <span
              className="columnLegends__color"
              style={{ backgroundColor: "#00B050" }}
            ></span>
            <span className="columnLegends__label">Actual</span>
          </div>
          <div className="columnLegends__item disabled">
            <span
              className="columnLegends__color"
              style={{ backgroundColor: "#0678EB" }}
            ></span>
            <span className="columnLegends__label">Cumulative Plan</span>
          </div>
          <div className="columnLegends__item disabled">
            <span
              className="columnLegends__color"
              style={{ backgroundColor: "#00B050" }}
            ></span>
            <span className="columnLegends__label">Cumulative Actual</span>
          </div>
        </div>
      </>
    </div>
  );
}

export default InspectionsPlan;
