import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { images } from "../../constants";
import * as moment from "moment-timezone";
import { textAlign } from "@mui/system";

const styles = StyleSheet.create({
  body: {
    padding: 20,
    backgroundColor: "#ffffff",
  },
  section: {
    padding: 10,
    backgroundColor: "#f2f2f2",
    minHeight: "100%",
  },
  firstPage: {
    padding: 10,
    backgroundColor: "#f2f2f2",
    minHeight: "calc(100% - 95px)",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    width: "33.33%",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 20,
  },
  col4: {
    display: "flex",
    flexDirection: "column",
    width: "25%",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 20,
  },
  col5: {
    display: "flex",
    flexDirection: "column",
    width: "20%",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 20,
  },
  col2: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 20,
  },
  col1: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 20,
  },
  input: {
    display: "block",
    verticalAlign: "middle",
    width: "100%",
    height: "28px",
    border: "none",
    borderRadius: 0,
    backgroundColor: "#ffffff",
    color: "#242A30",
    padding: "9px 15px",
    fontSize: 8,
  },
  input1: {
    display: "block",
    verticalAlign: "middle",
    width: "100%",
    height: "28px",
    border: "none",
    borderRadius: 0,
    backgroundColor: "#ffffff",
    color: "#242A30",
    paddingLeft: "15px",
    paddingRight: "5px",
    paddingTop: "9px",
    paddingBottom: "5px",
    fontSize: 8,
  },
  input2: {
    display: "block",
    verticalAlign: "middle",
    width: "100%",
    height: "28px",
    border: "none",
    borderRadius: 0,
    backgroundColor: "#ffffff",
    color: "#242A30",
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: 8,
  },
  comment: {
    display: "block",
    verticalAlign: "middle",
    width: "100%",
    height: "35px",
    border: "none",
    borderRadius: 0,
    backgroundColor: "#ffffff",
    border: "1px solid #dee2e6",
    borderTop: 0,
    color: "#242A30",
    padding: "11px 15px",
    fontSize: 8,
  },
  label: {
    display: "block",
    color: "#444444",
    marginBottom: 5,
    fontSize: 8,
  },
  col: {
    display: "flex",
    flexDirection: "column",
    width: "33.33%",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 20,
  },
  title: {
    display: "block",
    width: "100%",
    fontSize: 11,
    fontWeight: 700,
    color: "#ea2a2a",
    paddingLeft: 10,
    paddingBottom: 15,
    paddingTop: 15,
  },
  subtitle: {
    display: "block",
    width: "100%",
    fontSize: 10,
    fontWeight: 400,
    color: "#707072",
    paddingLeft: 10,
    paddingBottom: 15,
  },
  smallTitle: {
    display: "block",
    width: "100%",
    fontSize: 10,
    fontWeight: 400,
    color: "#242A30",
  },
  table: {
    display: "block",
    width: "100%",
    border: "1px solid #dee2e6",
    borderBottom: 0,
  },
  tableHead: {
    backgroundColor: "#f2f2f2",
  },
  tableBody: {
    display: "block",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    borderBottom: "1px solid #dee2e6",
    margin: 0,
    padding: 0,
  },
  tableColumn: {
    border: "none",
    backgroundColor: "#f2f2f2",
    color: "#8f8c9b",
    padding: "10px",
    fontSize: 8,
    textAlign: "left",
  },
  tableCell: {
    border: "none",
    backgroundColor: "#ffffff",
    padding: "10px",
    fontSize: 8,
    textAlign: "left",
  },
  image: {
    display: "block",
    width: "100%",
    height: "90px",
    overflow: "hidden",
    borderRadius: "4px",
    objectFit: "cover",
  },
  header: {
    display: "block",
    height: "95px",
    overflow: "hidden",
    position: "relative",
    margin: "-10px -10px 0",
    backgroundColor: "#ffffff",
  },
  headerImg: {
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "contain",
    position: "relative",
  },
  headerCnt: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    padding: 20,
    zIndex: 2,
  },
  headerFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  general: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    border: "1px solid #d9d9d9",
    borderBottom: 0,
  },
  generalCol: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "50%",
    borderBottom: "1px solid #d9d9d9",
  },
  generalLabel: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    backgroundColor: "#f2f2f2",
    color: "#8f8c9b",
    height: "28px",
    fontSize: 8,
    padding: "9px 10px",
  },
  generalLabel1: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    backgroundColor: "#f2f2f2",
    color: "#8f8c9b",
    height: "28px",
    fontSize: 8,
    padding: "5px 10px",
  },
  generalInput: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    backgroundColor: "#ffffff",
    color: "#2d353c",
    height: "28px",
    fontSize: 8,
    padding: "9px 5px",
  },
  generalInput1: {
    display: "flex",
    alignItems: "flex-start",
    width: "50%",
    backgroundColor: "#ffffff",
    height: "28px",
    paddingTop: "6px",
    paddingBottom: "4px",
    paddingLeft: "5px",
  },
  generalInput2: {
    display: "flex",
    alignItems: "flex-start",
    width: "50%",
    backgroundColor: "#ffffff",
    height: "28px",
    fontSize: 8,
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingLeft: "5px",
    paddingRight: "2px",
  },
  accordion: {
    display: "block",
    width: "100%",
    backgroundColor: "#ffffff",
    marginBottom: "15px",
  },
  accordionHead: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: "#ea2a2a",
    padding: "10px 20px",
    overflow: "hidden",
    borderRadius: 4,
  },
  headCol: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  accordionContent: {
    display: "block",
    width: "100%",
    backgroundColor: "#ffffff",
  },
  span: {
    fontSize: 8,
    color: "#ffffff",
    paddingRight: "30px",
  },
  text: {
    fontSize: 8,
    color: "#ffffff",
  },
  para: {
    fontSize: 8,
    color: "#2d353c",
  },
  checkListPara: {
    fontSize: 8,
    color: "#2d353c",
    lineHeight: 1.2,
    // overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "300px",
    whiteSpace: "nowwrap",
  },
  accord: {
    display: "block",
    width: "100%",
    borderBottom: "1px solid #e0e0e0",
  },
  accordHead: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: "#ffffff",
    padding: "10px 20px",
  },
  accordContent: {
    display: "block",
    width: "100%",
    padding: "10px 20px",
    backgroundColor: "#ffffff",
  },
  headContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  status: {
    width: "20px",
    height: "20px",
    objectFit: "content",
    padding: 3,
  },
  done: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  doneText: {
    fontSize: 8,
    color: "#2d353c",
    marginTop: "-2px",
  },
  doneIcon: {
    width: "8px",
    height: "8px",
    objectFit: "contain",
    marginLeft: 10,
    marginTop: 10,
  },
  logos: {
    maxWidth: "80px",
    height: "auto",
  },
});

const GeneratePdf = ({
  values,
  totalA,
  totalB,
  totalC,
  inspectionDetails,
  defectCountA,
  defectCountB,
  inspectionChecklist,
  checklist,
  defectCountC,
  resultA,
  resultB,
  resultC,
  signaturePath,
  signaturePath2,
}) => {
  return (
    <Document file="generatepdf.pdf" fileName="generatepdf.pdf">
      <Page size="A4" style={styles.body}>
        <View style={styles.firstPage}>
          <View style={styles.header}>
            <Image src={images.PdfHeader} style={styles.headerImg} />
            <View style={styles.headerCnt}>
              <View style={styles.headerFlex}>
                <Text>Ex Inspection Test Report</Text>
                <Image src={images.logo} />
              </View>
              <Text>
                Explosion Proof (Ex) Equipment - Inspection & Repair Works at
                DPF Facilities
              </Text>
            </View>
          </View>
          <Text style={styles.title}>General Informations</Text>
          <View style={styles.row}>
            <View style={styles.col1}>
              <View style={styles.general}>
                <View style={styles.generalCol}>
                  <Text style={styles.generalLabel}>Client Name</Text>
                  <View style={styles.generalInput1}>
                    <Image src={images.DubaiLogo} style={styles.logos} />
                  </View>
                </View>
                <View style={styles.generalCol}>
                  <Text style={styles.generalLabel}>Contractor Name</Text>
                  <View style={styles.generalInput1}>
                    <Image src={images.DeltaLogo} style={styles.logos} />
                  </View>
                </View>
                <View style={styles.generalCol}>
                  <Text style={styles.generalLabel}>Location</Text>
                  <Text style={styles.generalInput}>{values?.location}</Text>
                </View>
                <View style={styles.generalCol}>
                  <Text style={styles.generalLabel}>Area</Text>
                  <Text style={styles.generalInput}>{values?.area}</Text>
                </View>
                <View style={styles.generalCol}>
                  <Text style={styles.generalLabel}>Sub - Area</Text>
                  <Text style={styles.generalInput}>{values?.subArea}</Text>
                </View>
                <View style={styles.generalCol}>
                  <Text style={styles.generalLabel}>GPS Coordinates</Text>
                  <Text style={styles.generalInput}>
                    {values?.locationLatitude},{values?.locationLongitude}
                  </Text>
                </View>
                <View style={styles.generalCol}>
                  <Text style={styles.generalLabel}>Zone</Text>
                  <Text style={styles.generalInput}>{values?.zone}</Text>
                </View>
                <View style={styles.generalCol}>
                  <Text style={styles.generalLabel}>Gas Group</Text>
                  <Text style={styles.generalInput}>
                    {values?.locationGasGroup}
                  </Text>
                </View>
                <View style={styles.generalCol}>
                  <Text style={styles.generalLabel}>T-Class</Text>
                  <Text style={styles.generalInput}>
                    {values?.locationTClass}
                  </Text>
                </View>
                <View style={styles.generalCol}>
                  <Text style={styles.generalLabel}>IP Rating</Text>
                  <Text style={styles.generalInput}>
                    {values?.locationIpRating}
                  </Text>
                </View>
                <View style={styles.generalCol}>
                  <Text style={styles.generalLabel1}>
                    Area Classification Drawing Number
                  </Text>
                  <Text style={styles.generalInput2}>
                    {values?.areaClassDrawNo}
                  </Text>
                </View>
                <View style={styles.generalCol}>
                  <Text style={styles.generalLabel1}>
                    Equipment Layout Drawing Number
                  </Text>
                  <Text style={styles.generalInput2}>
                    {values?.eqpmtLytDrawNo}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row}>
            <Text style={styles.title}>Equipment Tag Details</Text>
            <View style={styles.col4}>
              <Text style={styles.label}>RFID Reference</Text>
              <Text style={styles.input}> {values?.rfidRef}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>Inspection Reference</Text>
              <Text style={styles.input}>
                {values?.inspectionReferenceNumber}
              </Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>GPS Coordinates</Text>
              <Text style={styles.input1}>
                {values?.eqpmtLatitude},{values?.eqpmtLongitude}
              </Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>Equipment Category</Text>
              <Text style={styles.input}>{values?.eqpmtCatg}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>Equipment Tag</Text>
              <Text style={styles.input}>{values?.eqpmtTag}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>Circuit ID</Text>
              <Text style={styles.input}>{values?.circuitId}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>Description</Text>
              <Text style={styles.input}>{values?.description}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>Manufacturer</Text>
              <Text style={styles.input}>{values?.manufacturer}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>Type/Model</Text>
              <Text style={styles.input}>{values?.type}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>Serial Number</Text>
              <Text style={styles.input}>{values?.serialNumber}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>ATEX Category</Text>
              <Text style={styles.input}>{values?.atexCatg}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>EPL</Text>
              <Text style={styles.input}>{values?.epl}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>Protection Standard</Text>
              <Text style={styles.input}>{values?.protectionStd}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>Protection Type</Text>
              <Text style={styles.input}>{values?.protectionType}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>Gas Group</Text>
              <Text style={styles.input}>{values?.equipmentGasGroup}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>T-Class</Text>
              <Text style={styles.input}>{values?.equipmentTClass}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>IP Rating</Text>
              <Text style={styles.input}>{values?.equipmentIpRating}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>T-Ambient</Text>
              <Text style={styles.input}>{values?.tAmbient}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>Certification Body</Text>
              <Text style={styles.input}>{values?.certfnBody}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>Certification Number</Text>
              <Text style={styles.input}>{values?.certfnNo}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col1}>
              <Text style={styles.label}>Special Conditions</Text>
              <Text style={styles.input}>{values?.specialCond}</Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.body}>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.title}>Inspection Checklist</Text>
            <View style={styles.row}>
              <View style={styles.col}>
                <Text style={styles.label}>Inspection Type</Text>
                <Text style={styles.input}>{values?.inspectionType}</Text>
              </View>
              <View style={styles.col}>
                <Text style={styles.label}>Inspection Checklist</Text>
                <Text style={styles.input}>
                  {values?.inspectionChecklistType}
                </Text>
              </View>
              <View style={styles.col}>
                <Text style={styles.label}>Inspection Grade</Text>
                <Text style={styles.input}>{values?.inspectionGrade}</Text>
              </View>
            </View>

            {inspectionChecklist &&
              inspectionChecklist?.checkLists?.map(
                (item, i) =>
                  item?.defectCategory == "A" && (
                    <View style={styles.accordion}>
                      <View style={styles.accordionHead}>
                        <View style={styles.headCol}>
                          <Text style={styles.span}>Defect Code</Text>
                          <Text style={styles.span}>
                            A.{item?.checklistName}
                          </Text>
                        </View>
                        <View style={styles.headCol}>
                          <Text style={styles.text}>
                            {defectCountA?.length + "/" + totalA?.length}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.accordionContent}>
                        {resultA.map((details) => (
                          <View style={styles.accord}>
                            <View style={styles.accordHead}>
                              {details.defectCategory === "A" &&
                                details.defectCategory != false &&
                                details.defectCategory != undefined && (
                                  <View style={styles.headContent}>
                                    <Text
                                      style={[
                                        styles.checkListPara,
                                        { paddingRight: "65px" },
                                      ]}
                                    >
                                      {details?.defectCode}
                                    </Text>
                                    {/* test123 */}
                                    <Text style={styles.checkListPara}>
                                      {details?.checkListGroup}
                                    </Text>
                                  </View>
                                )}

                              {details.defectCategory == "A" && (
                                <View style={styles.headContent}>
                                  {checklist?.find(
                                    (item) =>
                                      item.defectCode == details.defectCode
                                  ) ? (
                                    <Image
                                      src={images.remove}
                                      style={styles.status}
                                    />
                                  ) : (
                                    <Image
                                      src={images.correct}
                                      style={styles.status}
                                    />
                                  )}
                                </View>
                              )}
                            </View>
                          </View>
                        ))}
                      </View>
                    </View>
                  )
              )}

            {inspectionChecklist &&
              inspectionChecklist?.checkLists?.map(
                (item, i) =>
                  item?.defectCategory == "B" && (
                    <View style={styles.accordion}>
                      <View style={styles.accordionHead}>
                        <View style={styles.headCol}>
                          <Text style={styles.span}>Defect Code</Text>
                          <Text style={styles.span}>
                            B.{item?.checklistName}
                          </Text>
                        </View>
                        <View style={styles.headCol}>
                          <Text style={styles.text}>
                            {defectCountB?.length + "/" + totalB?.length}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.accordionContent}>
                        {resultB.map((details) => (
                          <View style={styles.accord}>
                            <View style={styles.accordHead}>
                              {details.defectCategory === "B" && (
                                <View style={styles.headContent}>
                                  <Text
                                    style={[
                                      styles.para,
                                      { paddingRight: "65px" },
                                    ]}
                                  >
                                    {details?.defectCode}
                                  </Text>
                                  <Text style={styles.checkListPara}>
                                    {details?.checkListGroup}
                                  </Text>
                                </View>
                              )}

                              {details.defectCategory == "B" && (
                                <View style={styles.headContent}>
                                  {checklist?.find(
                                    (item) =>
                                      item.defectCode == details.defectCode
                                  ) ? (
                                    <Image
                                      src={images.remove}
                                      style={styles.status}
                                    />
                                  ) : (
                                    <Image
                                      src={images.correct}
                                      style={styles.status}
                                    />
                                  )}
                                </View>
                              )}
                            </View>
                          </View>
                        ))}
                      </View>
                    </View>
                  )
              )}

            {inspectionChecklist &&
              inspectionChecklist?.checkLists?.map(
                (item, i) =>
                  item?.defectCategory == "C" && (
                    <View style={styles.accordion}>
                      <View style={styles.accordionHead}>
                        <View style={styles.headCol}>
                          <Text style={styles.span}>Defect Code</Text>
                          <Text style={styles.span}>
                            C.{item?.checklistName}
                          </Text>
                        </View>
                        <View style={styles.headCol}>
                          <Text style={styles.text}>
                            {defectCountC?.length + "/" + totalC?.length}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.accordionContent}>
                        {resultC.map((details) => (
                          <View style={styles.accord}>
                            <View style={styles.accordHead}>
                              {details.defectCategory === "C" && (
                                <View style={styles.headContent}>
                                  <Text
                                    style={[
                                      styles.para,
                                      { paddingRight: "65px" },
                                    ]}
                                  >
                                    {details?.defectCode}
                                  </Text>
                                  <Text style={styles.checkListPara}>
                                    {details?.checkListGroup}
                                  </Text>
                                </View>
                              )}

                              {details.defectCategory == "C" && (
                                <View style={styles.headContent}>
                                  {checklist?.find(
                                    (item) =>
                                      item.defectCode == details.defectCode
                                  ) ? (
                                    <Image
                                      src={images.remove}
                                      style={styles.status}
                                    />
                                  ) : (
                                    <Image
                                      src={images.correct}
                                      style={styles.status}
                                    />
                                  )}
                                </View>
                              )}
                            </View>
                          </View>
                        ))}
                      </View>
                    </View>
                  )
              )}
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.body}>
        <View style={styles.section}>
          <Text style={styles.title}>Defect Analysis</Text>
          <View style={styles.col1}>
            <View style={[styles.row, { padding: 0, margin: 0 }]}>
              <View style={[styles.col1, { padding: 0, margin: 0 }]}>
                <View style={styles.table}>
                  <View style={styles.tableHead}>
                    <View style={styles.tableRow}>
                      <Text style={[styles.tableColumn, { width: "15%" }]}>
                        Defect Code
                      </Text>
                      <Text style={[styles.tableColumn, { width: "30%" }]}>
                        Findings
                      </Text>
                      <Text style={[styles.tableColumn, { width: "35%" }]}>
                        Remedial Actions
                      </Text>
                      <Text style={[styles.tableColumn, { width: "20%" }]}>
                        Remarks
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableBody}>
                    {values?.checkList?.map((item, index) =>
                      item?.findingsAndActions?.map((list, i) => {
                        if (list.isSelected != undefined) {
                          if (list?.isSelected === true) {
                            return (
                              <View style={styles.tableRow} key={i}>
                                <Text
                                  style={[styles.tableCell, { width: "15%" }]}
                                >
                                  {list?.defectCode}
                                </Text>
                                <Text
                                  style={[styles.tableCell, { width: "30%" }]}
                                >
                                  {list?.finding}
                                </Text>
                                <Text
                                  style={[styles.tableCell, { width: "35%" }]}
                                >
                                  {list?.remedialAction}
                                </Text>
                                <Text
                                  style={[styles.tableCell, { width: "20%" }]}
                                >
                                  {list?.remark}
                                </Text>
                              </View>
                            );
                          }
                        }
                      })
                    )}
                  </View>
                </View>
                <Text style={styles.comment}>
                  Additional Comments: {values?.additionalComments}
                </Text>
              </View>
            </View>
            <View style={[styles.row, { padding: 0, margin: 0 }]}>
              <View style={[styles.col1, { padding: 0, margin: 0 }]}>
                <View
                  style={[
                    styles.row,
                    {
                      padding: "10px 8px",
                      backgroundColor: "#ffffff",
                      border: "1px solid #dee2e6",
                      borderTop: 0,
                      margin: 0,
                    },
                  ]}
                >
                  <View
                    style={[styles.col4, { padding: "0 5px", width: "22%" }]}
                  >
                    {values?.defectivePhoto1 ? (
                      <Image
                        src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${values.defectivePhoto1}`}
                        style={styles.image}
                      />
                    ) : (
                      <Image src={images.dummy} style={styles.image} />
                    )}
                  </View>
                  <View
                    style={[styles.col4, { padding: "0 5px", width: "22%" }]}
                  >
                    {values?.defectivePhoto2 ? (
                      <Image
                        src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${values.defectivePhoto2}`}
                        style={styles.image}
                      />
                    ) : (
                      <Image src={images.dummy} style={styles.image} />
                    )}
                  </View>
                  <View
                    style={[styles.col4, { padding: "0 5px", width: "22%" }]}
                  >
                    {values?.defectivePhoto3 ? (
                      <Image
                        src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${values.defectivePhoto3}`}
                        style={styles.image}
                      />
                    ) : (
                      <Image src={images.dummy} style={styles.image} />
                    )}
                  </View>
                  <View
                    style={[styles.col4, { padding: "0 5px", width: "33%" }]}
                  >
                    <Text style={styles.label}>
                      Faulty Items: {values?.faultyItems}
                    </Text>
                    <Text style={styles.label}>
                      Inspection Status: {values?.inspectionStatus}
                    </Text>
                    <Text style={styles.label}>
                      Defect Category: {values?.defectDefectCategory}
                    </Text>
                    <Text style={styles.label}>
                      Overall Condition: {values?.defectOverallCondition}
                    </Text>
                    <Text style={styles.label}>
                      Isolations: {values?.defectIsolation}
                    </Text>
                    <Text style={styles.label}>
                      Other Requirements: {values?.defectOtherRequirements}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <Text style={styles.subtitle}>Material Requirements</Text>
            <View style={styles.col4}>
              <Text style={styles.label}>Part Number</Text>
              {values?.materials?.map((item, i) => {
                return <Text style={styles.input}>{item?._id}</Text>;
              })}
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>Description</Text>
              {values?.materials?.map((item, i) => {
                return <Text style={styles.input}>{item?.specification}</Text>;
              })}
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>Units</Text>
              {values?.materials?.map((item, i) => {
                return <Text style={styles.input}>{item?.unit}</Text>;
              })}
            </View>
            <View style={styles.col4}>
              <Text style={styles.label}>Quantity</Text>
              {values?.materials?.map((item, i) => {
                return <Text style={styles.input}>{item?.qty}</Text>;
              })}
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col2}>
              <Text style={styles.label}>Data Sheet</Text>
              <Text style={styles.input}>{values?.dataSheetOrgName}</Text>
            </View>
            <View style={styles.col2}>
              <Text style={styles.label}>Remarks</Text>
              <Text style={styles.input}>{values?.remarks}</Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.body}>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.title}>Corrective Actions</Text>
            <View style={styles.col1}>
              <View style={[styles.row, { padding: 0, margin: 0 }]}>
                <View style={[styles.col1, { padding: 0, margin: 0 }]}>
                  <View style={styles.table}>
                    <View style={styles.tableHead}>
                      <View style={styles.tableRow}>
                        <Text style={[styles.tableColumn, { width: "15%" }]}>
                          Defect Code
                        </Text>
                        <Text style={[styles.tableColumn, { width: "20%" }]}>
                          Findings
                        </Text>
                        <Text style={[styles.tableColumn, { width: "25%" }]}>
                          Recommendations
                        </Text>
                        <Text style={[styles.tableColumn, { width: "15%" }]}>
                          Done
                        </Text>
                        <Text style={[styles.tableColumn, { width: "15%" }]}>
                          Repaired By
                        </Text>
                        <Text style={[styles.tableColumn, { width: "15%" }]}>
                          Repaired Date
                        </Text>
                      </View>
                    </View>
                    <View style={styles.tableBody}>
                      {values?.checkList?.map((item, index) =>
                        item?.findingsAndActions?.map((list, i) => {
                          if (list.isSelected != undefined) {
                            if (list?.isSelected === true) {
                              return (
                                <View style={styles.tableRow} key={i}>
                                  <Text
                                    style={[styles.tableCell, { width: "15%" }]}
                                  >
                                    {list?.defectCode}
                                  </Text>
                                  <Text
                                    style={[styles.tableCell, { width: "20%" }]}
                                  >
                                    {list?.finding}
                                  </Text>
                                  <Text
                                    style={[styles.tableCell, { width: "25%" }]}
                                  >
                                    {list?.remedialAction}
                                  </Text>
                                  <Text
                                    style={[styles.tableCell, { width: "15%" }]}
                                  >
                                    <Text style={styles.doneText}>
                                      {item.done}
                                    </Text>
                                    {list?.isDone ? (
                                      <Image
                                        src={images.correct}
                                        style={styles.doneIcon}
                                      />
                                    ) : (
                                      <Image
                                        src={images.remove}
                                        style={styles.doneIcon}
                                      />
                                    )}
                                  </Text>
                                  <Text
                                    style={[styles.tableCell, { width: "15%" }]}
                                  >
                                    {list?.repairedBy}
                                  </Text>
                                  <Text
                                    style={[styles.tableCell, { width: "15%" }]}
                                  >
                                    {list?.repairedAt?.length > 19
                                      ? moment(list?.repairedAt).format(
                                          "DD/MM/YYYY hh:mm A"
                                        )
                                      : list?.repairedAt}
                                  </Text>
                                </View>
                              );
                            }
                          }
                        })
                      )}
                    </View>
                  </View>
                  <Text style={styles.comment}>Additional Comments:</Text>
                </View>
              </View>
              <View style={[styles.row, { padding: 0, margin: 0 }]}>
                <View style={[styles.col1, { padding: 0, margin: 0 }]}>
                  <View
                    style={[
                      styles.row,
                      {
                        padding: "10px 8px",
                        backgroundColor: "#ffffff",
                        border: "1px solid #dee2e6",
                        borderTop: 0,
                        margin: 0,
                      },
                    ]}
                  >
                    <View
                      style={[styles.col4, { padding: "0 5px", width: "22%" }]}
                    >
                      {values?.correctivePhoto1 ? (
                        <Image
                          src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${values.correctivePhoto1}`}
                          style={styles.image}
                        />
                      ) : (
                        <Image src={images.dummy} style={styles.image} />
                      )}
                    </View>
                    <View
                      style={[styles.col4, { padding: "0 5px", width: "22%" }]}
                    >
                      {values?.correctivePhoto2 ? (
                        <Image
                          src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${values.correctivePhoto2}`}
                          style={styles.image}
                        />
                      ) : (
                        <Image src={images.dummy} style={styles.image} />
                      )}
                    </View>
                    <View
                      style={[styles.col4, { padding: "0 5px", width: "22%" }]}
                    >
                      {values?.correctivePhoto3 ? (
                        <Image
                          src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${values.correctivePhoto3}`}
                          style={styles.image}
                        />
                      ) : (
                        <Image src={images.dummy} style={styles.image} />
                      )}
                    </View>
                    <View
                      style={[styles.col4, { padding: "0 5px", width: "33%" }]}
                    >
                      <Text style={styles.label}>
                        Existing Faults: {values?.existingFaults}
                      </Text>
                      <Text style={styles.label}>
                        Current Status: {values?.currentStatus}
                      </Text>
                      <Text style={styles.label}>
                        Defect Category: {values?.correctiveDefectCategory}
                      </Text>
                      <Text style={styles.label}>
                        Current Condition:{values?.correctiveOverallCondition}
                      </Text>
                      <Text style={styles.label}>
                        Isolations: {values?.correctiveisolation}
                      </Text>
                      <Text style={styles.label}>
                        Other Requirements:
                        {values?.correctiveOtherRequirements}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col5}></View>
            <View style={styles.col5}>
              <Text style={styles.smallTitle}>Signature</Text>
            </View>
            <View style={styles.col5}>
              <Text style={styles.smallTitle}>Name</Text>
            </View>
            <View style={styles.col5}>
              <Text style={styles.smallTitle}>Reference Id</Text>
            </View>
            <View style={styles.col5}>
              <Text style={styles.smallTitle}>Date</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col5}>
              <Text style={styles.smallTitle}>Inspected By</Text>
            </View>
            <View style={styles.col5}>
              {signaturePath2 && (
                <Image
                  src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${signaturePath2}`}
                  style={styles.image}
                />
              )}
            </View>
            <View style={styles.col5}>
              <Text style={styles.input}>{values?.inspectedBy}</Text>
            </View>
            <View style={styles.col5}>
              <Text style={styles.input}></Text>
            </View>
            <View style={styles.col5}>
              <Text style={styles.input2}>
                {values.inspectedDate && values.inspectedDate.includes("+")
                  ? moment
                      .utc(values.inspectedDate)
                      .format("DD/MM/YYYY hh:mm A")
                  : values.inspectedDate
                  ? moment(values.inspectedDate).format("DD/MM/YYYY hh:mm A")
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col5}>
              <Text style={styles.smallTitle}>Repaired By</Text>
            </View>
            <View style={styles.col5}>
              {signaturePath && (
                <Image
                  src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${signaturePath}`}
                  style={styles.image}
                />
              )}
            </View>
            <View style={styles.col5}>
              <Text style={styles.input}>{values?.repairedBy}</Text>
            </View>
            <View style={styles.col5}>
              <Text style={styles.input}></Text>
            </View>
            <View style={styles.col5}>
              <Text style={styles.input2}>
                {values.repairedDate && values.repairedDate.includes("+")
                  ? moment.utc(values.repairedDate).format("DD/MM/YYYY hh:mm A")
                  : values.repairedDate
                  ? moment(values.repairedDate).format("DD/MM/YYYY hh:mm A")
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col5}>
              <Text style={styles.smallTitle}>Approved By</Text>
            </View>
            <View style={styles.col5}>
              <Text style={styles.input}></Text>
            </View>
            <View style={styles.col5}>
              <Text style={styles.input}></Text>
            </View>
            <View style={styles.col5}>
              <Text style={styles.input}></Text>
            </View>
            <View style={styles.col5}>
              <Text style={styles.input}></Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default GeneratePdf;
