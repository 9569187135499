import React, { useState, useEffect } from "react";
// import { Formik, Form, Field } from "formik";
import { images } from "../../constants";
import { Button, FormCheckbox, FormInput, Form } from "../../components";
import { MdOutlineClose } from "react-icons/md";
import { Row, Col } from "react-bootstrap";
import assetService from "../../services/assetService";
import "bootstrap/dist/css/bootstrap.css";
import { GiConsoleController } from "react-icons/gi";
import { propTypes } from "react-bootstrap/esm/Image";
import { toast } from "react-toastify";

const CustomLayout = ({ showLayout, closeLayout, addLayout, list }) => {
  const [checkedItem, setChecked] = useState([]);
  const [layoutName, setLayoutNAme] = useState("");
  const [validation, setValidation] = useState(false);
  const fields = [
    { id: 1, label: "RFID Reference", value: "RFID Reference" },
    { id: 2, label: "Inspection Reference", value: "Inspection Reference" },
    { id: 3, label: "Location", value: "Location" },
    { id: 4, label: "Area", value: "Area" },
    { id: 5, label: "Sub Area", value: "Sub-Area" },
    { id: 6, label: "GPS Co-ordinates", value: "GPS Co-ordinates" },
    { id: 7, label: "Zone", value: "Zone" },
    { id: 8, label: "Area Gas group", value: "Area Gas group" },
    { id: 9, label: "Area T-Class", value: "Area T-Class" },
    { id: 10, label: "IP Rating", value: "IP Rating" },
    {
      id: 11,
      label: "Area Classification Drawing Number",
      value: "Area Classification Drawing Number",
    },
    {
      id: 12,
      label: "Equipment Layout Drawing Number",
      value: "Equipment Layout Drawing Number",
    },
    { id: 13, label: "Equipment Tag", value: "Equipment Tag" },
    { id: 14, label: "Circuit ID", value: "Circuit ID" },
    { id: 15, label: "Description", value: "Description" },
    { id: 16, label: "Manufacturer", value: " Manufacturer" },
    { id: 17, label: "Type / Model", value: "Type / Model" },
    { id: 18, label: "Equipment Category", value: "Equipment Category" },
    { id: 19, label: "ATEX Category", value: "ATEX Category" },
    { id: 20, label: "EPL", value: "EPL" },
    { id: 21, label: "Protection Standard", value: "Protection Standard" },
    { id: 22, label: "Protection type", value: "Protection type" },
    { id: 23, label: "Equip. Gas Group", value: "Equip. Gas Group" },
    { id: 24, label: "Equip. T-Class", value: "Equip. T-Class" },
    { id: 25, label: "IP Rating", value: "IP Rating" },
    { id: 26, label: "T-Ambient", value: "T-Ambient" },
    { id: 27, label: "Certification Body", value: "Certification Body" },
    { id: 28, label: "Certification Number", value: "Certification Number" },
    { id: 29, label: "Special Conditions", value: "Special Conditions" },
    { id: 30, label: "Inspection Type", value: "Inspection Type" },
    { id: 31, label: "Inspection Checklist", value: "Inspection Checklist" },
    { id: 32, label: "Inspection Grade", value: "Inspection Grade" },
    { id: 33, label: "Findings", value: "Findings" },
    { id: 34, label: "Remedial Actions", value: "Remedial Actions" },
    { id: 35, label: "Faulty Items", value: "Faulty Items" },
    { id: 36, label: "Inspection Photos", value: "Inspection Photos" },
    { id: 37, label: "Inspection Status", value: "Inspection Status" },
    { id: 38, label: "Defect Category", value: " Defect Category" },
    { id: 39, label: "Overall Condition", value: "Overall Condition" },
    { id: 40, label: "Isolations", value: "Isolations" },
    { id: 41, label: "Other Requirements", value: "Other Requirements" },
    { id: 42, label: "Material Requirements", value: "Material Requirements" },
    { id: 43, label: "Equipment Data Sheet", value: "Equipment Data Sheet" },
    { id: 44, label: "Inspected By", value: "Inspected By" },
    { id: 45, label: "Inspected Date", value: "Inspected Date" },
    { id: 46, label: "Repairs Done", value: "Repairs Done" },
    { id: 47, label: "Existing Faults", value: "Existing Faults" },
    { id: 48, label: "Current Photos", value: "Current Photos" },
    { id: 49, label: "Current Status", value: "Current Status" },
    { id: 50, label: " Defect Category", value: " Defect Category" },
    { id: 51, label: "Current Condition", value: "Current Condition" },
    { id: 52, label: "Isolations", value: "Isolations" },
    { id: 53, label: "Other Requirements", value: "Other Requirements" },
    { id: 55, label: "Repaired By", value: "Repaired By" },
    { id: 56, label: "Repaired Date", value: "Repaired Date" },
  ];

  const handleLayout = (event) => {
    setLayoutNAme({ layoutName: event.target.value });
  };

  const addCustomLayout = () => {
    if (layoutName.layoutName != "" && layoutName.layoutName != undefined) {
      if (checkedItem.length != 0 && validation === false) {
        addLayout(layoutName, checkedItem);
      } else {
        if (validation === true && checkedItem.length != 0) {
          toast.error("The layout name already exists");
        } if (checkedItem.length === 0) {
          toast.error("Please  select  layout field");
        }
      }
    } else {
      toast.error("Please Enter layout Name ");
    }
  };
  useEffect(() => {
    list.map((item) => {
      if (item.layoutName === layoutName.layoutName) {
        // toast.warn(
        //   "This name already exist.   are you sure to add this as layout name?"
        // );
        setValidation(true);
      }
         if (item.layoutName != layoutName.layoutName)
         {
           setValidation(false);
         }
    });
  },[]);
  return (
    <div className="layout">
      <div className="layout__overlay"></div>
      <div className="layout__wrapper">
        <div className="layout__header">
          <h3 className="layout__title">
            <img src={images.layout} />
            Create Custom Layout
          </h3>
          <Button
            className="layout__close"
            variant="click-none"
            size="click-resize"
            onClick={closeLayout}
          >
            <MdOutlineClose size={25} />
          </Button>
        </div>

        <div>
          <div className="layout__content">
            <FormInput
              name="layoutName"
              type="text"
              placeholder="Layout Name"
              label="Layout Name"
              onChange={handleLayout}
              value={layoutName.layoutName}
              required
            />

            <ul className="layout__list">
              {fields.map((field, index) => (
                <li className="layout__list--item">
                  <FormCheckbox
                    id={field.id}
                    name="layoutFields"
                    label={field.label}
                    value={field.value}
                    variant="check-dark"
                    size="check-sm"
                    onChange={(e) => {
                      const { value, checked } = e.target;
                      var updatedList = [...checkedItem];
                      if (checked) {
                        updatedList = [...checkedItem, value];
                      } else {
                        updatedList.splice(checkedItem.indexOf(value), 1);
                      }
                      setChecked(updatedList);
                    }}
                  />
                </li>
              ))}
            </ul>

            <Row className="mt-4">
              <Col lg={6} sm={12}>
                <Button
                  variant="click-gray"
                  size="click-lg"
                  className="w-100"
                  onClick={closeLayout}
                >
                  Cancel
                </Button>
              </Col>
              <Col lg={6} sm={12}>
                <Button
                  variant="click-primary"
                  size="click-lg"
                  className="w-100"
                  type="submit"
                  onClick={addCustomLayout}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomLayout;
