import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  FormInput,
  FormSelect,
  FileUpload,
  Button,
  AddTable,
  MixedInput,
  GpsInput,
  Modal,
} from "../../../components";
import { AiOutlineFileText } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import { Row, Col } from "react-bootstrap";
import locationService from "../../../services/locationService";
import validator from "validator";
import { useSelector, useDispatch } from "react-redux";
import assetService from "../../../services/assetService";
import fileService from "../../../services/fileService";
import { GiConsoleController } from "react-icons/gi";
import { getSubLookup, getLookup } from "../../../Redux/Actions/lookupActions";
import { toast } from "react-toastify";

const LocationDetails = ({
  isSubmit,
  setIsSubmit,
  setActiveStep,
  locationId,
  setLocationId,
  assetId,
  locationData,
  setLocationData,
  isClear,
  setIsClear,
  next,
  setNext,
  activeStep,
  ...props
}) => {
  const [values, setValues] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [showEqpmtFile, setShowEqpmtFile] = useState(false);
  const [showAreaFile, setShowAreaFile] = useState(false);
  const [gpscord, setgpscord] = useState("");

  const dispatch = useDispatch();
  const lookup = useSelector((state) => state.lookupData.data);
  const subLookup = useSelector((state) => state.subLookupData);

  const [equipmentLayout, setEquipmentLayout] = useState("");
  const [areaClassification, setAreaClassification] = useState("");

  // useEffect(() => {
  //     dispatch(getLookup());
  // }, [dispatch]);

  const [file, setFile] = useState("");
  const [eqpmtFile, setEqpmtFile] = useState(null);
  const [filename, setFilename] = useState("");
  const [eqpmtFilename, setEqpmtFilename] = useState("");

  const [requiredMsg, setRequired] = useState("");
    const [requiredMsg1, setRequired1] = useState("");
  const [requiredLocation, setRequiredLocation] = useState("");
  const [requiredArea, setRequiredArea] = useState("");
  const [areaClassNo, setAreaClassNo] = useState("");
  const [gpsCoord, setGpsCoord] = useState("");
  const [assetData, setAssetData] = useState([]);

  const locationFormRef = useRef(null);
  let zoneData = [];
  let ipRatingData = [];
  let gasGroupData = [];
  let tClassData = [];

  useEffect(() => {
    if (assetId !== undefined) {
      try {
        assetService.getAssetById(String(assetId)).then((assetData) => {
          setLocationId(assetData?.locationId);
          setAssetData(assetData);
          // setValues(assetData);

          // setValues(
          //   { location : assetData?.location,
          //    area: assetData?.area,
          //    subArea: assetData?.subArea,
          //    zone: assetData?.zone,
          //    gasGroup: assetData?.gasGroup,

          //    tClass: assetData?.tClass,
          //    ipRating: assetData?.ipRating,

          //    areaClassificationDrawingNumber: assetData?.areaClassDrawNo,

          //    equipmentDrawingNumber: assetData?.equipmentDrawingNumber,
          //    gpsCoord: assetData?.latitude+ ',' + assetData?.latitude
          //   }

          // )

          setValues(assetData);

          let locationLatitude = assetData?.locationLatitude ? assetData?.locationLatitude :"";
          let locationLongitude = assetData?.locationLongitude ? assetData?.locationLongitude :"";
          let data = "";
          if(locationLatitude && locationLongitude === null){
            data = ""
          }
          else if(locationLatitude && !locationLongitude){
              data = locationLatitude
          }
          else if(!locationLatitude && locationLongitude){
            data = locationLongitude

          }
          else{
            data = locationLatitude + "," + locationLongitude;
          }
          setgpscord(data);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  useEffect(() => {
    if (isSubmit) {
      handleSubmit();
      setIsSubmit(false);
      setNext(true);
    }
  }, [isSubmit]);

  useEffect(() => {
    if (isClear) {
      handleClear();
      setIsClear(false);
    }
  }, [isClear]);

  useEffect(() => {
    if (next) {
      setNext(false);
    }
  }, [values]);
  useEffect(() => {
    setValues(locationData);
  }, [activeStep]);

  const handleClear = () => {
    setValues("");
    // setgpscord("");
    setLocationData("");
  };

  if (lookup) {
    lookup.Zone?.map((ele) => {
      zoneData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });

    lookup.locationIpRating?.map((ele) => {
      ipRatingData.push({
        key: ele._id,
        text: ele.value,
        value: ele.value,
      });
    });
  }

  if (subLookup) {
    subLookup.locationTClass?.IEC.map((ele) => {
      tClassData.push({
        key: ele._id,
        text: ele.fieldValue,
        value: ele.fieldValue,
      });
    });

    subLookup.locationGasGroup?.IEC.map((ele) => {
      gasGroupData.push({
        key: ele._id,
        text: ele.fieldValue,
        value: ele.fieldValue,
      });
    });
  }

  const handleLocation = (e) => {
    setValues({ ...values, location: e.target.value });
      if (requiredMsg1) {
        setRequired1("");
      }
      if(requiredLocation){
        setRequiredLocation("")
      }
  };

  const handleArea = (e) => {
    setValues({ ...values, area: e.target.value });
     if (requiredMsg) {
       setRequired("");
     }
     if(requiredArea){
      setRequiredArea("")
     }
  };

  const handleSubArea = (e) => {
    setValues({ ...values, subArea: e.target.value });
  };

  const handleZone = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    setValues({ ...values, zone: value });
  };

  const handleGasGroup = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    setValues({ ...values, locationGasGroup: value });
  };

  const handleTClass = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    setValues({ ...values, locationTClass: value });
  };

  // const handleIpRating = (event, {value}) => {
  //   setValues({...values, locationIpRating: value});
  // };

  const handleipRating = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);

    //setIpRating(value);
    setValues({ ...values, locationIpRating: value });
  };

  // const handleIpRating = (event, data)=> {
  //   const { value } = data;
  //   const { key } = data.options.find(o => o.value === value);
  //   //setIpRating(value);
  //   setValues({...values, locationIpRating: value});
  // };

  const handleAreaClassDrawNo = (e) => {
    setValues({ ...values, areaClassDrawNo: e.target.value });
  };

  const handleEqpmtLytDrawNo = (e) => {
    setValues({ ...values, eqpmtLytDrawNo: e.target.value });
  };

  const handleGpsCoord = (e) => {
    // navigator.geolocation.getCurrentPosition(function(position) {
    //   setGpsCoord(`${position.coords.latitude},${position.coords.longitude}`)
    // });
    setValues({ ...values, locationGpsCord: e.target.value });
  };

  const handleAreaClass = async (e) => {
    let selFile = e.target.files[0];
    setFile(selFile);
    setFilename(e.target.files[0].name);
    const formData = new FormData();
    formData.append("file", selFile);
    if (selFile !== undefined) {
      setShowAreaFile(true);
    }
    try {
      await fileService.fileUpload(formData, "areaClassDrawAttach").then(
        (response) => {
          if (response?.data?.status) {
            setAreaClassification(response?.data?.data.file);
            setValues({
              ...values,
              areaClassDrawAttach: response?.data?.data?.uploadStatus?.file,
              areaClassDrawNo: response?.data?.data?.uploadStatus?.originalName,
            });
          } else {
            toast.error(" This format is not supported");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleEquipment = async (e) => {
    let selFile = e.target.files[0];
    setEqpmtFile(selFile);
    setEqpmtFilename(e.target.files[0].name);
    const formData = new FormData();
    formData.append("file", selFile);
    if (selFile !== undefined) {
      setShowEqpmtFile(true);
    }

    try {
      await fileService.fileUpload(formData, "eqpmtLytDrawAttach").then(
        (response) => {
          if (response?.data?.status) {
            setEquipmentLayout(response?.data?.data.file);
            setValues({
              ...values,
              eqpmtLytDrawAttach: response?.data?.data?.uploadStatus?.file,
              eqpmtLytDrawNo: response?.data?.data?.uploadStatus?.originalName,
            });
          } else {
            toast.error(" This format is not supported");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const deleteFilename = () => {
    let values = {
      equipmentDrawingNumber: "",
    };
  };

  const closeModal = async () => {
    setShowModal(false);
    let datas = await locationService.addLocationById(locationId, values);
  };

  const handleSubmit = async (e) => {
    //response from the file upload should be passed

    setLocationData(values);

    let locationData = {};
    let formData = new FormData();
    [...locationFormRef.current.elements].map((ele) => {
      locationData[ele.name] = ele.value;
      // if(ele.type === 'file') {
      //   formData.append(ele.name, ele.files)
      // }
      // else{
      //   formData.append(ele.name, ele.value)
      //   locationData[ele.name]=ele.value
      // }
    });
    // locationData['areaClassDrawAttach']="fileInfo";
    // locationData['eqpmtLytDrawAttach']="eqpmtFileInfo";
    //locationData['subAreaCode']=gpsCoord;
    //locationData['zone']=zone;
    //locationData['gasGroup']=gasGroup;
    //locationData['tClass']=tclass;
    //locationData['ipRating']=ipRating;

    if (
      validator.isEmpty(locationData.location) &&
      validator.isEmpty(locationData.area)
    ) {
      setRequired("Required*");
        setRequired1("Required*");
    } else if (validator.isEmpty(locationData.area)) {
      setRequiredArea("Area required*");
      setRequiredLocation("");
      setRequired("");
    } else if (validator.isEmpty(locationData.location)) {
      setRequiredLocation("Location required*");
      setRequiredArea("");
      setRequired("");
    } else {
      setRequired("");
      setRequiredLocation("");
      setRequiredArea("");
      if (assetId) {
        let newData = {
          area: values?.area,
          location: values?.location ? values?.location : "",
          subArea: values?.subArea ? values?.subArea : "",
          zone: values?.zone ? values?.zone : "",
          locationGasGroup: values?.locationGasGroup ? values?.locationGasGroup : "",
          locationTClass: values?.locationTClass ? values?.locationTClass : "",
          locationIpRating: values?.locationIpRating ? values?.locationIpRating : "",
          areaClassDrawNo: values?.areaClassDrawNo ? values?.areaClassDrawNo : "",
          areaClassDrawAttach: values?.areaClassDrawAttach ? values?.areaClassDrawAttach : "",
          eqpmtLytDrawAttach: values?.eqpmtLytDrawAttach ? values?.eqpmtLytDrawAttach : "",
          eqpmtLytDrawNo: values?.eqpmtLytDrawNo ? values?.eqpmtLytDrawNo : "",
        };
        try {
          await locationService.addLocationById(locationId, newData).then(
            (response) => {
              // setShowModal(true);
              // setStatusMsg(response.data.msg);
              toast.success("Location updated successfully");
            },
            (error) => {
              toast.success(error?.response.data.msg);
            }
          );
        } catch (err) {
          setShowModal(true);
          setStatusMsg("Failed");
        }
      } else {
        try {
          await locationService.addLocation(values).then(
            (response) => {
              setLocationId(response.data.data.locationId);
              // setShowModal(true);
              // setStatusMsg(response.data.msg);
              toast.success("Location added successfully");

              setActiveStep((prevActiveStep) => prevActiveStep + 1);

              localStorage.setItem(
                "locationId",
                response?.data?.dat?.locationId
              );
            },
            (error) => {
              toast.error(error?.response?.data?.message);
            }
          );
        } catch (err) {
          setShowModal(true);
          setStatusMsg("Failed");
        }
      }
    }
  };
  return (
    <>
      <Form onSubmit={(e) => handleSubmit(e)} formRef={locationFormRef}>
        <Row>
          <Col xxl={3} lg={4}>
            <FormInput
              type="text"
              label="Location"
              name="location"
              placeholder="Enter here"
              value={values.location ? values.location : ""}
              onChange={handleLocation}
              required
              errormsg={requiredMsg1 ? requiredMsg1 : requiredLocation}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormInput
              type="text"
              label="Area"
              name="area"
              placeholder="Enter here"
              value={values.area ? values.area : ""}
              onChange={handleArea}
              required
              errormsg={requiredMsg ? requiredMsg : requiredArea}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormInput
              type="text"
              label="Sub - Area"
              name="subArea"
              placeholder="Enter here"
              value={values.subArea ? values.subArea : ""}
              onChange={handleSubArea}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormInput
              type="text"
              label="GPS Coordinates"
              name="locationGpsCord"
              placeholder="Enter here"
              value={gpscord ? gpscord : ""}
              onChange={handleGpsCoord}
              // onChange={(e)=>{setGpsCoord(e.target.value)}}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              label="Zone"
              name="zone"
              placeholder="Select"
              data={zoneData}
              onChange={handleZone}
              selection
              value={values.zone ? values.zone : ""}
            />
          </Col>

          <Col xxl={3} lg={4}>
            <FormSelect
              label="Gas Group"
              name="locationGasGroup"
              placeholder="Select"
              data={gasGroupData}
              onChange={handleGasGroup}
              selection
              value={values.locationGasGroup ? values.locationGasGroup: ""}
            />
          </Col>
          <Col xxl={3} lg={4}>
            <FormSelect
              label="T-Class"
              name="locationTClass"
              placeholder="Select"
              data={tClassData}
              onChange={handleTClass}
              selection
              value={values.locationTClass ? values.locationTClass : ""}
            />
          </Col>

          <Col xxl={3} lg={4}>
            <FormSelect
              label="IP Rating"
              name="locationIpRating"
              placeholder="Select IP Rating"
              data={ipRatingData}
              value={values.locationIpRating ? values.locationIpRating : ""}
              onChange={handleipRating}
            />
          </Col>

          <Col xxl={6} lg={6}>
            <MixedInput
              label="Area Classification Drawing Number"
              textName="areaClassDrawNo"
              name="areaClassDrawAttach"
              onFileChange={handleAreaClass}
              onTextChange={handleAreaClassDrawNo}
              textValue={values.areaClassDrawNo ? values.areaClassDrawNo : ""}
            />
            {filename ? (
              <span className="file-name">
                <AiOutlineFileText size={20} />
                {filename}
              </span>
            ) : (
              <span className="document-name">
                <span
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_BACKEND_BASE_URL}/${values?.areaClassDrawAttach}`
                    );
                  }}
                >
                  {values.areaClassDrawNo && <AiOutlineFileText size={20} />}
                  {values.areaClassDrawNo}
                </span>
                <span style={{ marginLeft: "10px" }} onClick={deleteFilename}>
                  {values.areaClassDrawNo && (
                    <RiDeleteBinLine
                      onClick={() => {
                        values.areaClassDrawAttach = "";
                        values.areaClassDrawNo = "";
                        setValues({ ...values });
                      }}
                      size={20}
                    />
                  )}
                </span>
              </span>
            )}
          </Col>
          <Col xxl={6} lg={6}>
            <MixedInput
              label="Equipment Layout Drawing Number"
              textName="eqpmtLytDrawNo"
              name="eqpmtLytDrawAttach"
              onFileChange={handleEquipment}
              onTextChange={handleEqpmtLytDrawNo}
              textValue={values.eqpmtLytDrawNo ? values.eqpmtLytDrawNo : ""}
            />
            {showEqpmtFile ? (
              <span className="file-name">
                <AiOutlineFileText size={20} />
                {eqpmtFilename}
              </span>
            ) : (
              <span className="document-name">
                <span
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_BACKEND_BASE_URL}/${values?.eqpmtLytDrawAttach}`
                    );
                  }}
                >
                  {values.eqpmtLytDrawNo && <AiOutlineFileText size={20} />}
                  {values.eqpmtLytDrawNo}
                </span>
                <span style={{ marginLeft: "10px" }} onClick={deleteFilename}>
                  {values.eqpmtLytDrawNo && (
                    <RiDeleteBinLine
                      onClick={() => {
                        values.eqpmtLytDrawAttach = "";
                        values.eqpmtLytDrawNo = "";
                        setValues({ ...values });
                      }}
                      size={20}
                    />
                  )}
                </span>
              </span>
            )}
          </Col>
        </Row>
      </Form>
      {showModal && (
        <Modal closeModal={closeModal} title={`${statusMsg}`}>
          <Button variant="click-gray" size="click-lg" onClick={closeModal}>
            Close
          </Button>
        </Modal>
      )}
    </>
  );
};

export default LocationDetails;
