import React, { Component } from "react";
import Chart from "react-apexcharts";
import { Navigate } from 'react-router-dom';

class InspectionGrade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [2500, 1800, 1600],
      options: {
        colors: ["#0678EB", "#62AEFB", "#CBE4FE"],
        legend: {
          show: false,
        },
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              
              if(config.dataPointIndex===0) { 
                this.myFunction(config.dataPointIndex)
              }
              else if(config.dataPointIndex===1) {
                this.myFunction(config.dataPointIndex)

              }
              else if(config.dataPointIndex===2) {
                this.myFunction(config.dataPointIndex)
              }
            }
          },
          dropShadow: {
            enabled: false,
            enabledOnSeries: undefined,
            top: 0,
            left: 0,
            blur: 10,
            color: "#000",
            opacity: 0.1,
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: {
                enabled: true,
              },
            },
            export: {
              svg: {
                filename: 'Inspection Overview Grade',
              },
              png: {
                filename: 'Inspection Overview Grade',
              }
            },
            autoSelected: 'zoom'
          },
        },
        labels: [
          "Detailed Inspections",
          "Close Inspections",
          "Visual Inspections",
        ],
        responsive: [
          {
            breakpoint: 1600,
            options: {
              chart: {
                height: 302,
                width: 302,
              },
              dataLabels: {
                style: {
                  fontSize: "12px",
                },
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                width: '100%',
                height: 267,
              },
              dataLabels: {
                style: {
                  fontSize: "12px",
                },
              },
            },
          },
          {
            breakpoint: 990,
            options: {
              chart: {
                width: "100%",
                height: 310,
              },
              dataLabels: {
                style: {
                  fontSize: "12px",
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "400",
                },
              },
            },
          },
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
                height: 300,
              },
              dataLabels: {
                style: {
                  fontSize: "12px",
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "400",
                },
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: true,
            offsetX: 0,
            offsetY: 0,
            customScale: 1,
            dataLabels: {
              offset: -35,
              minAngleToShowLabel: 10,
            },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "400",
          },
        },
      },
    };
  }

  myFunction = (data) =>{
    this.setState({ redirect: true });
  }

  render() {
    const { redirect } = this.state;
    if(redirect) {
      return <Navigate to="/asset-register"/>
    }
    return (
      <>
        <div className="chart-flex">
          <div className="flex-column">
            <Chart
              options={this.state.options}
              series={[
                this.props.detailed ? this.props.detailed : 0,
                this.props.close ? this.props.close : 0,
                this.props.visual ? this.props.visual : 0,
              ]}
              type="pie"
              height="358px"
              width="358px"
            />
          </div>
          <div className="flex-column">
            <div className="legends">
              <div className="legends__item disabled">
                <span className="legends__color total"></span>
                <div>
                  <p className="legends__title">Total Inspections</p>
                  {this.props.total1 ? (
                    <p className="legends__count">{this.props.total1}</p>
                  ) : (
                    <p className="legends__count">0</p>
                  )}
                </div>
              </div>
              <div
                className="legends__item"
                onClick={() =>
                  this.props.navigate("/asset-register", {
                    state: {
                      valid: "true",
                      inspectionGrade: "Detailed",
                      startDate: this.props.values.startDate,
                      endDate: this.props.values.endDate,
                    },
                  })
                }
              >
                <span className="legends__color detail"></span>
                <div>
                  <p className="legends__title">Detailed Inspections</p>
                  {this.props.detailed ? (
                    <p className="legends__count">{this.props.detailed}</p>
                  ) : (
                    <p className="legends__count">0</p>
                  )}
                </div>
              </div>
              <div
                className="legends__item"
                onClick={() =>
                  this.props.navigate("/asset-register", {
                    state: {
                      valid: "true",
                      inspectionGrade: "Close",
                      startDate: this.props.values.startDate,
                      endDate: this.props.values.endDate,
                    },
                  })
                }
              >
                <span className="legends__color close"></span>
                <div>
                  <p className="legends__title">Close Inspections</p>
                  {this.props.close ? (
                    <p className="legends__count">{this.props.close}</p>
                  ) : (
                    <p className="legends__count">0</p>
                  )}
                </div>
              </div>
              <div
                className="legends__item"
                onClick={() =>
                  this.props.navigate("/asset-register", {
                    state: {
                      valid: "true",
                      inspectionGrade: "Visual",
                      startDate: this.props.values.startDate,
                      endDate: this.props.values.endDate,
                    },
                  })
                }
              >
                <span className="legends__color visual"></span>
                <div>
                  <p className="legends__title">Visual Inspections</p>
                  {this.props.visual ? (
                    <p className="legends__count">{this.props.visual}</p>
                  ) : (
                    <p className="legends__count">0</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default InspectionGrade;
