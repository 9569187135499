import React, { Component, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";

function CurrentStage({ currentStage, values }) {
  const navigate = useNavigate();
  const [xaxiscat, setXaxiscat] = useState([]);
  const [series, setSeries] = useState([
    {
      name: "Total Inspections",
      type: "line",
      data: [],
    },
    {
      name: "Electrical",
      type: "column",
      data: [],
    },
    {
      name: "Instrumentation",
      type: "column",
      data: [],
    },
    {
      name: "Telecom",
      type: "column",
      data: [],
    },
    {
      name: "Simple",
      type: "column",
      data: [],
    },
  ]);
  let options = {
    chart: {
      type: "line",
      stacked: false,
      padding: {
        left: 50,
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
        },
        export: {
          svg: {
            filename: "Asset Condition Analysis - Current Stage",
          },
          png: {
            filename: "Asset Condition Analysis - Current Stage",
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3, 3, 3, 3],
      colors: [
        "#EA2A2A",
        "transparent",
        "transparent",
        "transparent",
        "transparent",
      ],
      curve: "straight",
    },
    responsive: [
      {
        breakpoint: 1440,
        options: {
          chart: {
            height: 310,
            width: "100%",
          },
          dataLabels: {
            style: {
              fontSize: "12px",
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
        },
      },
      {
        breakpoint: 990,
        options: {
          chart: {
            width: "100%",
            height: 310,
          },
          dataLabels: {
            style: {
              fontSize: "12px",
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "400",
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          legend: {
            horizontalAlign: "left",
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
            height: 320,
          },
        },
      },
    ],
    xaxis: {
      categories: xaxiscat,
      position: "bottom",
      tickPlacement: "between",
      labels: {
        style: {
          colors: "#4F4F4F",
          fontSize: "14px",
          fontFamily: "Halvetica, sans-serif",
          fontWeight: "400",
        },
        rotate: -45,
        offsetY: -4,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
        offsetY: -4,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        style: {
          colors: "#4F4F4F",
          fontSize: "14px",
          fontFamily: "Halvetica, sans-serif",
          fontWeight: "400",
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    chart: {
      animations: {
        enabled: false,
      },
    },
    legend: {
      show: false,
      offsetY: 7,
    },
    markers: {
      size: 5,
    },
    fill: {
      opacity: 1,
    },
    colors: ["#EA2A2A", "#0070C0", "#C55A11", "#44546A", "#FFC000"],
  };
  useEffect(() => {
    setSeries([
      {
        name: "Total Inspections",
        type: "line",
        data: currentStage?.map((item, i) => {
          let total1 = 0;
          let total2 = 0;
          let total3 = 0;
          let total4 = 0;
          {
            item.Electrical ? (total1 = item.Electrical) : (total1 = 0);
          }
          {
            item.Instrumentation
              ? (total2 = item.Instrumentation)
              : (total2 = 0);
          }
          {
            item.Telecom ? (total3 = item.Telecom) : (total3 = 0);
          }
          {
            item.Simple ? (total4 = item.Simple) : (total4 = 0);
          }
          let total = total1 + total2 + total3 + total4;
          return total ? total : 0;
        }),
      },
      {
        name: "Electrical",
        type: "column",
        data: currentStage?.map((item, i) => {
          return item.Electrical ? item.Electrical : 0;
        }),
      },
      {
        name: "Instrumentation",
        type: "column",
        data: currentStage?.map((item, i) => {
          return item.Instrumentation ? item.Instrumentation : 0;
        }),
      },
      {
        name: "Telecom",
        type: "column",
        data: currentStage?.map((item, i) => {
          return item.Telecom ? item.Telecom : 0;
        }),
      },
      {
        name: "Simple",
        type: "column",
        data: currentStage?.map((item, i) => {
          return item.Simple ? item.Simple : 0;
        }),
      },
    ]);
    setXaxiscat(
      currentStage?.map((item, i) => {
        return (
          item.correctiveOverallCondition != null &&
          item.correctiveOverallCondition
        );
      })
    );
  }, [currentStage]);

  return (
    <>
      <Chart options={options} series={series} type="bar" height="420" />
      <div className="columnLegends">
        <div className="columnLegends__item disabled">
          <span
            className="columnLegends__color"
            style={{ backgroundColor: "#ea2a2a" }}
          ></span>
          <span className="columnLegends__label">Total Inspections</span>
        </div>
        <div
          className="columnLegends__item"
          onClick={() =>
            navigate("/asset-register", {
              state: {
                valid: "true",
                repairedDate: "true",
                eqpmtCatg: "Electrical",
                startDate: values.startDate,
                endDate: values.endDate,
              },
            })
          }
        >
          <span
            className="columnLegends__color"
            style={{ backgroundColor: "#0070c0 " }}
          ></span>
          <span className="columnLegends__label">Electrical</span>
        </div>
        <div
          className="columnLegends__item"
          onClick={() =>
            navigate("/asset-register", {
              state: {
                valid: "true",
                eqpmtCatg: "Instrumentation",
                repairedDate: "true",
                startDate: values.startDate,
                endDate: values.endDate,
              },
            })
          }
        >
          <span
            className="columnLegends__color"
            style={{ backgroundColor: "#c55a11" }}
          ></span>
          <span className="columnLegends__label">Instrumentation</span>
        </div>
        <div
          className="columnLegends__item"
          onClick={() =>
            navigate("/asset-register", {
              state: {
                valid: "true",
                repairedDate: "true",
                eqpmtCatg: "Telecom",
                startDate: values.startDate,
                endDate: values.endDate,
              },
            })
          }
        >
          <span
            className="columnLegends__color"
            style={{ backgroundColor: "#44546a" }}
          ></span>
          <span className="columnLegends__label">Telecom</span>
        </div>
        <div
          className="columnLegends__item"
          onClick={() =>
            navigate("/asset-register", {
              state: {
                valid: "true",
                repairedDate: "true",
                eqpmtCatg: "Simple",
                startDate: values.startDate,
                endDate: values.endDate,
              },
            })
          }
        >
          <span
            className="columnLegends__color"
            style={{ backgroundColor: "#ffc000" }}
          ></span>
          <span className="columnLegends__label">Simple</span>
        </div>
      </div>
    </>
  );
}

export default CurrentStage;
